import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTitle } from 'react-use';
import Spinner from '../../components/spinner';
import { endOfDay, subDays } from 'date-fns';
import { formatDateToTimeZone, utcDate } from '../../utils/format-date';
import { EyeIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import { useReportLock } from '../../hooks/use-report-lock';
import { toast } from 'react-hot-toast';
import Confirmation from '../../components/libs/confirmation';
import { useTopBotReport } from '../../hooks/use-top-bot-report';
import { usePrintTopBottomReportSlice } from '../../store/print-top-bottom-report-slice';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

const TypeList = [
  'schedules',
  'clocks',
  'owed',
  'extra',
  'un_schedules',
  'leave_requests',
  'schedules_breaks',
  'clocks_breaks',
  'early_comer',
  'late_comer',
  'early_leave',
  'late_leave',
];

const TopBottomReport = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Top-Bottom X`);
  const navigate = useNavigate();
  const reportLock = useReportLock({
    onSuccess: (data) => {
      toast.success(data.message);
    },
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const workSpaceSubDomain = useActiveWorkspaceSlice((state) => state.workspace?.sub_domain)!;
  const [startDate, setStartDate] = useState<Date | null>(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState<Date | null>(endOfDay(new Date()));
  const [activeStaff, setActiveStaff] = useState<number>(0);
  const [direction, setDirection] = useState<string>('desc');
  const [count, setCount] = useState<number>(3);
  const [type, setType] = useState<string>('schedules');
  const from = startDate ? formatDateToTimeZone(startDate, 'y-MM-dd') : null;
  const to = endDate ? formatDateToTimeZone(endDate, 'y-MM-dd') : null;
  const topBotReport = useTopBotReport(workSpaceSubDomain, from, to, count, type, direction);
  const printTopBottomReport = usePrintTopBottomReportSlice((state) => state.updateData);

  const handleShowDetails = (id: number) => {
    setActiveStaff(id);
  };

  const handlePrint = () => {
    printTopBottomReport(topBotReport.data ?? []);
    navigate('/print-top-bottom');
  };

  const handleLock = () => {
    if (startDate && endDate) {
      // const from = utcDate(startDate).getTime();
      const to = utcDate(endDate).getTime();
      reportLock.mutate({ to });
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && !reportLock.isLoading) {
      handleLock();
      return;
    }
  };

  return (
    <>
      <div className="w-full mx-auto space-y-0 pb-14 print:p-0 print:max-w-none print:w-full">
        <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9 print:shadow-none print:p-0 print:border-none">
          <div className="flex items-center justify-between w-full pb-6 mb-6 font-medium border-b print:hidden print:pb-4 print:mb-0 print:border-b print:border-b-gray-200">
            <div className="flex flex-col w-full font-medium">
              <span>Top-Bottom X</span>
              <p className="mt-2 text-sm font-normal text-gray-400">
                Identify your outstanding or underperforming employees by making selections from the
                dropdown menus below.
              </p>
              <div className="flex flex-col w-full mt-6 space-y-8 font-medium desktop:space-y-0 desktop:space-x-4 desktop:flex-row desktop:items-center desktop:justify-between">
                <div className="flex items-center space-x-4">
                  <CSVLink
                    data={(topBotReport.data ?? []).map((report) => {
                      return {
                        Name: report.staff_name,
                        Email: report.staff_email,
                        Job: report.staff_job,
                        Duration: report.duration,
                        Average: report.average,
                      };
                    })}
                    filename={`${from}-${to}.csv`}
                    className="flex items-center justify-center h-12 text-white transition duration-200 rounded w-28 bg-brand-primary hover:bg-brand-primary-dark"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                  <button
                    className="items-center justify-center hidden h-12 text-white transition duration-200 rounded md:flex w-28 bg-brand-primary hover:bg-brand-primary-dark"
                    type="button"
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                </div>
                <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-4 md:flex-row md:items-center">
                  <FormControl className="w-24 desktop:max-w-[96px] desktop:w-full min-w-[96px] shrink-0">
                    <InputLabel id="demo-select-small">Top-Bottom</InputLabel>
                    <Select
                      label="Top-Bottom"
                      value={direction}
                      onChange={(event: SelectChangeEvent) => {
                        setDirection(event.target.value);
                      }}
                    >
                      <MenuItem value={'asc'}>Bottom</MenuItem>
                      <MenuItem value={'desc'}>Top</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl className="w-24 desktop:max-w-[96px] desktop:w-full min-w-[96px] shrink-0">
                    <InputLabel id="demo-select-small">Count</InputLabel>
                    <Select
                      label="Count"
                      value={count.toString()}
                      onChange={(event: SelectChangeEvent) => {
                        setCount(+event.target.value);
                      }}
                    >
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl className="w-full desktop:max-w-full">
                    <InputLabel id="demo-select-small">Type</InputLabel>
                    <Select
                      label="Type"
                      value={type}
                      renderValue={(t) => {
                        const title = t.split('_').join(' ');
                        return <span className="capitalize">{title}</span>;
                      }}
                      onChange={(event: SelectChangeEvent) => {
                        setType(event.target.value);
                      }}
                    >
                      {TypeList.map((t) => {
                        const title = t.split('_').join(' ');
                        return (
                          <MenuItem key={t} value={t} className="capitalize">
                            {title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <DatePicker
                    label="Start date"
                    value={startDate}
                    disableMaskedInput
                    className="w-full desktop:max-w-full"
                    inputFormat="dd MMM y"
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    label="End date"
                    value={endDate}
                    disableMaskedInput
                    className="w-full desktop:max-w-full"
                    inputFormat="dd MMM y"
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>
            </div>
          </div>
          {topBotReport.isLoading ? (
            <div className="flex items-end justify-center w-full my-9">
              <span className="mr-4">Please wait</span>
              <Spinner size="small" />
            </div>
          ) : (
            <div className="flex flex-col w-full">
              <div className="grid w-full h-12 grid-cols-12 text-xs font-medium text-gray-700 bg-gray-100 rounded print:text-[8px] print:border-b print:border-b-gray-200">
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2" title="Name">
                    Name
                  </span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-2 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2" title="Name">
                    Email
                  </span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-2 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2" title="Job">
                    Job
                  </span>
                </div>
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2" title="Scheduled">
                    Duration
                  </span>
                </div>
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2" title="Scheduled">
                    Average
                  </span>
                </div>
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2" title="Actions">
                    Actions
                  </span>
                </div>
              </div>
              <div className="flex flex-col w-full divide-y-[1px] divide-gray-100">
                {topBotReport.data?.map((report, i) => {
                  return (
                    <div
                      key={`${report.staff_id}-${i}`}
                      className="grid w-full grid-cols-12 text-xs print:text-[8px] font-medium text-gray-700 rounded h-14 print:h-10"
                    >
                      <div className="flex items-center justify-center h-full col-span-3 text-center capitalize md:col-span-2 md:flex line-clamp-2">
                        {report.staff_name}
                      </div>
                      <div className="items-center justify-center hidden h-full col-span-2 text-center capitalize md:col-span-2 md:flex line-clamp-2">
                        {report.staff_email}
                      </div>
                      <div className="items-center justify-center hidden h-full col-span-2 text-center capitalize md:flex md:col-span-2 line-clamp-2">
                        {report.staff_job}
                      </div>
                      <div className="flex items-center justify-center h-full col-span-3 text-center capitalize md:flex md:col-span-2 line-clamp-2">
                        {report.duration}
                      </div>
                      <div className="flex items-center justify-center h-full col-span-3 text-center capitalize md:col-span-2 md:flex line-clamp-2">
                        {report.average}
                      </div>
                      <div className="flex items-center justify-center h-full col-span-3 space-x-2 text-center capitalize md:col-span-2 md:flex line-clamp-2 print:hidden">
                        <button
                          type="button"
                          className=""
                          disabled={false}
                          onClick={() => handleShowDetails(report.staff_id)}
                        >
                          <EyeIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
        title={`Are you sure you want to proceed and lock?`}
        description={
          <span className="mt-1 text-base text-gray-500">
            Once locked, any modifications to records prior to{' '}
            <b className="inline-block">{formatDateToTimeZone(endDate, 'dd-MM-y')}</b> will be
            permanently disabled.
          </span>
        }
      />
    </>
  );
};

export default TopBottomReport;
