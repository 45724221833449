import { useEffect } from 'react';
import { usePrintWorkTimeSlice } from '../../store/print-worktime-slice';
import { useNavigate } from 'react-router-dom';

const PrintWorkTime = () => {
  const navigate = useNavigate();
  const data = usePrintWorkTimeSlice((state) => state.data);

  useEffect(() => {
    window.print();
  }, []);

  return (
    <>
      <div className="mx-auto space-y-0 max-w-7xl pb-9 print:p-4 print:font-sans print:max-w-none print:w-full">
        <div className="flex items-center justify-between my-4 print:hidden">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="w-32 py-2 text-sm text-gray-700 border border-gray-200 rounded bg-gray-50"
          >
            Back
          </button>
          <button
            type="button"
            onClick={() => window.print()}
            className="w-32 py-2 text-sm text-gray-700 border border-gray-200 rounded bg-gray-50"
          >
            Print
          </button>
        </div>
        <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9 print:shadow-none print:p-0 print:border-none">
          <div className="flex flex-col w-full">
            <div className="grid w-full h-12 grid-cols-10 text-xs font-medium text-gray-700 bg-gray-100 rounded print:text-[8px] print:grid-cols-10 print:border-b print:border-b-gray-200">
              <div className="flex items-center justify-center h-full col-span-1">Name</div>
              <div className="flex items-center justify-center h-full col-span-1">Job</div>
              <div className="flex items-center justify-center h-full col-span-1">Scheduled</div>
              <div className="flex items-center justify-center h-full col-span-1">Worked</div>
              <div className="flex items-center justify-center h-full col-span-1">
                Used Time Off
              </div>
              <div className="flex items-center justify-center h-full col-span-1">Owed</div>
              <div className="flex items-center justify-center h-full col-span-1">Extra</div>
              <div className="flex items-center justify-center h-full col-span-1">Used Breaks</div>
              <div className="flex items-center justify-center h-full col-span-1">Total Break</div>
              <div className="flex items-center justify-center h-full col-span-1">
                Unscheduled Working Time
              </div>
            </div>
            <div className="flex flex-col w-full divide-y-[1px] divide-gray-100">
              {data.map((report, i) => {
                return (
                  <div
                    key={`${report.staff_id}-${i}`}
                    className="grid w-full grid-cols-10 text-xs print:text-[8px] print:grid-cols-10 font-medium text-gray-700 rounded h-14 print:h-10"
                  >
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.staff_name}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.staff_job}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.schedules_duration}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.clocks_duration}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.total_leave_requests_duration}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.owed_schedules_duration}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.extra_schedules_duration}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.clocks_breaks_duration}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.schedules_breaks_duration}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.un_schedules_duration}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintWorkTime;
