import http from './axios-instance';
import { Login, LoginRequest } from '../interfaces/login-interface';
import { Register, RegisterRequest } from '../interfaces/register-interface';
import { ForgotPassword, ForgotPasswordRequest } from '../interfaces/forgot-password-interface';
import { VerifyRequest } from '../interfaces/verify-interface';
import { ResetPassword, ResetPasswordRequest } from '../interfaces/reset-password-interface';
import { ConfirmPassword, ConfirmPasswordRequest } from '../interfaces/confirm-password-interface';
import { SendVerify, SendVerifyRequest } from '../interfaces/send-verify-interface';

const login = async (data: LoginRequest): Promise<Login> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/login`;
  const res = await http.post(url, data);
  return res.data;
};

const register = async (data: RegisterRequest): Promise<Register> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/register`;
  const res = await http.post(url, data);
  return res.data;
};

const forgotPassword = async (data: ForgotPasswordRequest): Promise<ForgotPassword> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/forget_password`;
  const res = await http.post(url, data);
  return res.data;
};

const verify = async (data: VerifyRequest): Promise<Login> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/verify_email`;
  const res = await http.post(url, data);
  return res.data;
};

const sendVerifyCode = async (data: SendVerifyRequest): Promise<SendVerify> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/sendverifyemail`;
  const res = await http.post(url, data);
  return res.data;
};

const resetPassword = async (data: ResetPasswordRequest): Promise<ResetPassword> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/reset_password`;
  const res = await http.post(url, data);
  return res.data;
};

const confirmPassword = async (data: ConfirmPasswordRequest): Promise<ConfirmPassword> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/change_password`;
  const res = await http.post(url, data);
  return res.data;
};

const authService = {
  login,
  register,
  forgotPassword,
  verify,
  sendVerifyCode,
  resetPassword,
  confirmPassword,
};

export default authService;
