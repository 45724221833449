import PrevPage from '../../components/ui/prev-page';

const Terms = () => {
  return (
    <main className="px-4 py-24 mx-auto max-w-7xl">
      <PrevPage to="./" />
      <h1 className="my-6 text-4xl font-semibold text-gray-700">Terms of Use</h1>
      <p className="text-sm leading-7 text-gray-500">
        By downloading or using Selected&apos;s products, these terms will automatically apply to
        you – please read them carefully before using them. You are not allowed to copy, modify, or
        use our trademarks in any way. You are also not allowed to attempt to extract the source
        code of our applications or make derivative versions. Our products and all the intellectual
        property rights related to them belong to Selected LTD. We reserve the right to make changes
        to our products or to charge for their services at any time and for any reason, but we will
        always inform you of any charges. Selected applications&apos; personal data you provide to
        us is used to improve our services. It is your responsibility to keep your phone and access
        to the apps securely. We recommend that you do not jailbreak or root your phone, as it could
        compromise your phone’s security features and our applications&apos; functionality. Our
        applications use third-party services, and their terms and conditions also apply to you. We
        are not responsible for any loss or charges you may incur while using our apps, such as
        roaming data charges. We cannot guarantee that our apps are always updated and correct, and
        we are not liable for any loss you may experience as a result of relying solely on their
        functionalities. We may update or terminate our apps at any time without notice. By using
        our apps, you agree to these terms and conditions. If you have any questions or suggestions,
        please contact us at the email address provided on our website.
      </p>
    </main>
  );
};

export default Terms;
