import { create } from 'zustand';
import { TopBotReport } from '../interfaces/top-bot-report';

type State = {
  data: TopBotReport[];
};

type Actions = {
  updateData: (workspace: TopBotReport[]) => void;
  reset: () => void;
};

const initialState: State = {
  data: [],
};

export const usePrintTopBottomReportSlice = create<State & Actions>()((set) => ({
  ...initialState,
  updateData(data: TopBotReport[]) {
    set({ data });
  },
  reset: () => {
    set({ ...initialState });
  },
}));
