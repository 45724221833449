import { useState } from 'react';
import {
  addMonths,
  subMonths,
  getYear,
  startOfWeek,
  endOfWeek,
  startOfDay,
  addDays,
  isSameDay,
} from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { enGB } from 'date-fns/locale';
import { formatDateToTimeZone, utcDate } from '../../utils/format-date';

const Calendar = () => {
  const [today] = useState(utcDate(startOfDay(new Date())));
  const [activeDate, setActiveDate] = useState(utcDate(startOfDay(new Date())));
  const weekStartDate = startOfWeek(activeDate, { locale: enGB, weekStartsOn: 1 });
  const weekEndDate = endOfWeek(activeDate, { locale: enGB, weekStartsOn: 1 });
  const prevMonth = formatDateToTimeZone(subMonths(activeDate, 1), 'MMM');
  const currentMonth = formatDateToTimeZone(activeDate, 'MMM');
  const nextMonth = formatDateToTimeZone(addMonths(activeDate, 1), 'MMM');
  const weekDays = Array.from(Array(7).keys()).map((idx) => {
    const dateForEachDay = addDays(utcDate(weekStartDate), idx);
    return {
      id: idx,
      date: dateForEachDay,
      formatedDate: formatDateToTimeZone(dateForEachDay, 'EEEEE'),
    };
  });

  const handlePrevMonth = () => {
    setActiveDate(subMonths(activeDate, 1));
  };

  const handleNextMonth = () => {
    setActiveDate(addMonths(activeDate, 1));
  };

  const handlePrevWeek = () => {
    setActiveDate(addDays(weekStartDate, -1));
  };

  const handleNextWeek = () => {
    setActiveDate(addDays(weekEndDate, 1));
  };

  const handleToday = () => {
    setActiveDate(new Date());
  };

  return (
    <div className="block w-full px-4 bg-white border shadow rounded-3xl">
      <div className="flex flex-col w-full">
        <div className="flex items-center justify-between">
          <button
            onClick={handleToday}
            className="w-24 mt-6 mb-2 ml-2 text-sm text-white transition duration-200 rounded bg-brand-primary hover:bg-brand-primary-dark h-9"
          >
            Today
          </button>
          <span className="mr-2 text-base font-semibold text-gray-800">{getYear(activeDate)}</span>
        </div>
        <div className="flex items-center justify-between w-full text-gray-800 border-b h-14">
          <button
            type="button"
            onClick={() => handlePrevMonth()}
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <ChevronLeftIcon className="w-6 h-6" aria-hidden="true" />
          </button>
          <button
            // onClick={() => handleNextMonth()}
            disabled
            className="flex-auto w-10 text-[12px] font-medium text-center text-gray-400"
          >
            {prevMonth}
          </button>
          <button className="flex-auto w-10 text-[14px] font-semibold text-center text-gray-800">
            {currentMonth}
          </button>
          <button
            // onClick={() => changeMonth(nextMonth)}
            disabled
            className="flex-auto w-10 text-[12px] font-medium text-center text-gray-400"
          >
            {nextMonth}
          </button>
          <button
            type="button"
            onClick={() => handleNextMonth()}
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <ChevronRightIcon className="w-6 h-6" aria-hidden="true" />
          </button>
        </div>
        <div className="flex items-center justify-between w-full text-gray-800">
          <button
            type="button"
            onClick={() => handlePrevWeek()}
            className={`-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500`}
          >
            <ChevronLeftIcon className="w-6 h-6" aria-hidden="true" />
          </button>
          <div className="w-full mx-2 overflow-hidden h-28">
            <div className="grid w-full h-full grid-cols-7">
              {weekDays.map((d) => {
                return (
                  <div key={d.id} className="flex flex-col items-center justify-center">
                    <span className="text-xs font-medium text-gray-500">{d.formatedDate}</span>
                    <span
                      className={`${
                        isSameDay(d.date, today)
                          ? 'text-white bg-gray-800'
                          : 'text-gray-800 bg-transparent'
                      }  flex items-center justify-center w-8 h-8 shrink-0 rounded-full mt-4 text-xs`}
                      // onClick={() => changeDay(d.day)}
                    >
                      {formatDateToTimeZone(d.date, 'dd')}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <button
            type="button"
            onClick={() => handleNextWeek()}
            className={`-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500`}
          >
            <ChevronRightIcon className="w-6 h-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
