import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CreateLeaveReqRequest } from '../interfaces/create-leave-req-request';
import { CreateLeaveReqResponse } from '../interfaces/create-leave-req-response';
import calendarService from '../services/calendar-service';
import leaveService from '../services/leave-service';
import { customToastError } from '../utils/custom-toast-error';

export function useCreateLeaveRequest(
  configOptions: UseMutationOptions<CreateLeaveReqResponse, any, any, any> = {},
) {
  const queryClient = useQueryClient();
  return useMutation((body: CreateLeaveReqRequest) => leaveService.createLeaveRequest(body), {
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
    onSettled: () => {
      queryClient.invalidateQueries([leaveService.leavesRequestsQueryKey]);
      queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
      queryClient.invalidateQueries([calendarService.leaveRequestsQueryKey]);
    },
    ...configOptions,
  });
}
