import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from './components/guards/require-auth';
import { requestForToken } from './firebase';
import Login from './pages/login/login';
import Register from './pages/register/register';
import ForgotPassword from './pages/forgot-password/forgot-password';
import CheckOtp from './pages/check-otp/check-otp';
import ConfirmPassword from './pages/confirm-password/confirm-password';
import NewWorkspaceName from './pages/new-workspace-name/new-workspace-name';
import ResetPassword from './pages/reset-password/reset-password';
import Profile from './pages/profile/profile';
import Home from './pages/home/home';
import ChangePassword from './pages/change-password/change-password';
import WelcomePage from './pages/welcome-page/welcome-page';
import Workspace from './pages/workspace/workspace';
import Dashboard from './pages/dashboard/dashboard';
import EmployeeWorkSpace from './pages/employee-work-space/employee-work-space';
import AttendanceList from './pages/attendance-list/attendance-list';
import Attendance from './pages/attendance/attendance';
import AttendanceDetails from './pages/attendance-details/attendance-details';
import CalendarReports from './pages/calendar-reports/calendar-reports';
import LeavesOfAbsences from './pages/leaves-of-absences/leaves-of-absences';
import Employees from './pages/employees/employees';
import EmployeesList from './pages/employees-list/employees-list';
import EmployeesCreate from './pages/employees-create/employees-create';
import Settings from './pages/settings/settings';
import Company from './pages/company/company';
import Locations from './pages/locations/locations';
import JobTitle from './pages/job-title/job-title';
import Department from './pages/department/department';
import NotFound from './pages/not-found/not-found';
import EmployeeDetails from './pages/employee-details/employee-details';
import ShiftsRequest from './pages/shifts-request/shifts-request';
import StaffsWorktimeReport from './pages/staffs-worktime-report/staffs-worktime-report';
import LogsReport from './pages/logs-report/logs-report';
import NewLocation from './pages/new-location/new-location';
import LogDetails from './pages/log-details/log-details';
import Privacy from './pages/privacy/privacy';
import Terms from './pages/terms/terms';
import RequireLocation from './components/guards/require-location';
import StaffsWorktimeDetails from './pages/staffs-worktime-details/staffs-worktime-details';
import PrintWorkTime from './pages/print-worktime/print-worktime';
import PrintWorkTimeDetails from './pages/print-worktime-details/print-worktime-details';
import TimeOff from './pages/time-off/time-off';
import AttendanceDetailsDate from './pages/attendance-details-date/attendance-details-date';
import LockSystem from './pages/lock-system/lock-system';
import SyncWithTipping from './pages/sync-with-tipping/sync-with-tipping';
import StaffsWorkTimeClocksReport from './pages/staffs-worktime-clocks/staffs-worktime-clocks';
import PrintWorkTimeDetailsClocks from './pages/print-worktime-details-clocks/print-worktime-details-clocks';
import TopBottomReport from './pages/top-bottom-report/top-bottom-report';
import PrintTopBottom from './pages/print-top-bottom/print-top-bottom';
import StaffsWorkTimeSimple from './pages/staffs-worktime-simple/staffs-worktime-simple';
import PrintWorkTimeSimple from './pages/print-worktime-simple/print-worktime-simple';
import DevicesList from './pages/devices-list/devices-list';
import NewDevice from './pages/new-device/new-device';

// const Login = Loadable(
//   lazy(async () => {
//     return Promise.all([
//       import('@pages/authentication/login/login'),
//       new Promise((resolve) => setTimeout(resolve, 300)),
//     ]).then(([moduleExports]) => moduleExports);
//   })
// );

const App = () => {
  useEffect(() => {
    requestForToken();
  }, []);

  return (
    <Routes>
      <Route path="privacy" element={<Privacy />} />
      <Route path="terms" element={<Terms />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="check-otp" element={<CheckOtp />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="confirm-password" element={<ConfirmPassword />} />
      <Route path="nw-name" element={<RequireAuth component={<NewWorkspaceName />} />} />
      <Route path="profile" element={<RequireAuth component={<Profile />} />} />
      <Route path="change-password" element={<RequireAuth component={<ChangePassword />} />} />
      <Route path="/" element={<RequireAuth component={<Home />} />}>
        <Route path="welcome-page" element={<WelcomePage />} />
        <Route path=":slug" element={<RequireLocation component={<Workspace />} />}>
          <Route path="" element={<Dashboard />} />
          <Route path="calendar" element={<CalendarReports />} />
          <Route path="devices" element={<DevicesList />} />
          <Route path="new-device" element={<NewDevice />} />
          <Route path="edit-device/:id" element={<NewDevice />} />
          <Route path="employee-work-space" element={<EmployeeWorkSpace />}>
            <Route path="attendance" element={<Attendance />}>
              <Route path="" element={<AttendanceList />} />
            </Route>
            <Route path="attendance-details/:attendanceId" element={<AttendanceDetails />} />
            <Route
              path="attendance-details/:attendanceId/:from/:to/:date"
              element={<AttendanceDetailsDate />}
            />
            <Route path="leaves-of-absences" element={<LeavesOfAbsences />} />
            <Route path="shifts-swap" element={<ShiftsRequest />} />
            <Route path="employees" element={<Employees />}>
              <Route path="" element={<EmployeesList />} />
              <Route path="employees-create" element={<EmployeesCreate />} />
              <Route path="employee-details/:id" element={<EmployeeDetails />} />
            </Route>
          </Route>
          <Route path="staffs-worktime-report" element={<StaffsWorktimeReport />} />
          <Route path="staffs-worktime-report/simple/:id" element={<StaffsWorkTimeSimple />} />
          <Route path="staffs-worktime-report/:id" element={<StaffsWorktimeDetails />} />
          <Route path="staffs-worktime-report/:id/:date" element={<StaffsWorkTimeClocksReport />} />
          <Route path="top-bottom-report" element={<TopBottomReport />} />
          <Route path="lock-system" element={<LockSystem />} />
          <Route path="logs-report" element={<LogsReport />} />
          <Route path="logs-report/:id" element={<LogDetails />} />
          <Route path="settings" element={<Settings />}>
            <Route path="" element={<Department />} />
            <Route path="department" element={<Department />} />
            <Route path="job-title" element={<JobTitle />} />
            <Route path="time-off" element={<TimeOff />} />
            <Route path="company" element={<Company />} />
            <Route path="locations" element={<Locations />} />
            <Route path="new-location" element={<NewLocation />} />
            <Route path="new-location/:id" element={<NewLocation />} />
          </Route>
        </Route>
      </Route>
      <Route path="print-worktime" element={<PrintWorkTime />} />
      <Route path="print-worktime-details" element={<PrintWorkTimeDetails />} />
      <Route path="print-worktime-simple" element={<PrintWorkTimeSimple />} />
      <Route path="print-worktime-clocks" element={<PrintWorkTimeDetailsClocks />} />
      <Route path="print-top-bottom" element={<PrintTopBottom />} />
      <Route path="app-sync/:merchantId/:appName/:businessId" element={<SyncWithTipping />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
