import Dialog from '@mui/material/Dialog';
import { AxiosError } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useEmployees } from '../../../hooks/use-employees';
import staffsService from '../../../services/staffs-service';
import React, { useEffect, useState } from 'react';
import { useDepartments } from '../../../hooks/use-departments';
import { useJobs } from '../../../hooks/use-jobs';
import { Department } from '../../../interfaces/department-interface';
import { Job } from '../../../interfaces/job-interface';
import { Employee } from '../../../interfaces/employee-interface';
import Popover from '@mui/material/Popover';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';
import { customToastError } from '../../../utils/custom-toast-error';

interface IForm {
  body: string;
  staff_ids: number[];
}

interface SelectedStaff {
  depId: number;
  depName: string;
  active: boolean;
  isOpen: boolean;
  jobs: {
    jobId: number;
    jobName: string;
    active: boolean;
    staffs: {
      staffId: number;
      staffName: string;
      active: boolean;
    }[];
  }[];
}
[];

interface MultipleNotificationsModalProps {
  show: boolean;
  closeModal: () => void;
}

const schema = yup
  .object({
    body: yup
      .string()
      .min(0, 'too short')
      .max(255, 'too long')
      .required('Please provide a Message'),
    staff_ids: yup
      .array()
      .of(yup.number())
      .test('length', 'Please Select an Employee', (v) => {
        if (v && v.length > 0) {
          return true;
        }
        return false;
      }),
  })
  .required();

const createList = (
  departments: Department[] = [],
  jobs: Job[] = [],
  employees: Employee[] = [],
) => {
  const list = departments.map((d) => {
    const js = jobs.flatMap((j) => {
      if (j.department_id === d.id) {
        const staffs = employees.flatMap((e) => {
          if (e.job_id === j.id) {
            return [
              {
                staffId: e.id,
                staffName: `${capitalizeFirstLetter(`${e.first_name} ${e.last_name}`)}`,
                active: false,
              },
            ];
          }
          return [];
        });
        return [
          {
            jobId: j.id,
            jobName: capitalizeFirstLetter(j.title),
            active: false,
            staffs,
          },
        ];
      }
      return [];
    });
    return {
      depId: d.id,
      depName: capitalizeFirstLetter(d.title),
      active: false,
      isOpen: false,
      jobs: js,
    };
  });
  return list;
};

const calculateLength = (selectedStaff: SelectedStaff[]) => {
  let staffs = 0;
  selectedStaff.forEach((d) => {
    d.jobs.forEach((j) => {
      j.staffs.forEach((s) => {
        if (s.active) {
          staffs = staffs + 1;
        }
      });
    });
  });
  return staffs;
};

const MultipleNotificationsModal = ({ show, closeModal }: MultipleNotificationsModalProps) => {
  const [selectedStaffs, setSelectedStaffs] = useState<SelectedStaff[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { data: departments = [] } = useDepartments();
  const { data: jobs = [] } = useJobs();
  const { data: employees = [] } = useEmployees();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      body: '',
      staff_ids: [],
    },
    resolver: yupResolver<any>(schema),
  });
  const body = watch('body');
  const currentBodyLength = 255 - (body ?? '').length;

  useEffect(() => {
    if (departments.length && jobs.length && employees.length) {
      setSelectedStaffs(createList(departments, jobs, employees));
    }
  }, [departments, jobs, employees]);

  const {
    mutate,
    isLoading,
    reset: resetMutation,
  } = useMutation(staffsService.sendNotification, {
    onSuccess: () => {
      toast.success('Notification Sent.');
      handleCloseModal();
    },
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
      // console.log(data.response?.data);
    },
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (isLoading) {
      return;
    }
    mutate({ ...data, title: 'You have a Message' });
  };

  const calcStaffIds = (sss: SelectedStaff[]) => {
    let staff_ids: number[] = [];
    sss.forEach((dep) => {
      dep.jobs.forEach((job) => {
        job.staffs.forEach((staff) => {
          if (staff.active) {
            staff_ids = [...staff_ids, staff.staffId];
          }
        });
      });
    });
    setValue('staff_ids', staff_ids);
  };

  const handleCloseModal = () => {
    resetMutation();
    reset({
      body: '',
      staff_ids: [],
    });
    setSelectedStaffs(createList(departments, jobs, employees));
    closeModal();
  };

  const handleALDepartment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target as HTMLInputElement;
    const depId = +id.split('-')[0];
    const sss = selectedStaffs.map((dep) => {
      if (dep.depId === depId) {
        return {
          ...dep,
          active: checked,
          jobs: dep.jobs.map((job) => {
            return {
              ...job,
              active: checked,
              staffs: job.staffs.map((staff) => {
                return { ...staff, active: checked };
              }),
            };
          }),
        };
      }
      return dep;
    });
    setSelectedStaffs(sss);
    calcStaffIds(sss);
  };

  const handleALJob = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target as HTMLInputElement;
    const str = id.split('-');
    const depId = +str[0];
    const jobId = +str[1];
    const sss = selectedStaffs.map((a) => {
      if (a.depId === depId) {
        return {
          ...a,
          jobs: a.jobs.map((j) => {
            if (j.jobId === jobId) {
              return {
                ...j,
                active: checked,
                staffs: j.staffs.map((staff) => {
                  return { ...staff, active: checked };
                }),
              };
            }
            return j;
          }),
        };
      }
      return a;
    });
    setSelectedStaffs(sss);
    calcStaffIds(sss);
  };

  const handleALStaff = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target as HTMLInputElement;
    const str = id.split('-');
    const depId = +str[0];
    const jobId = +str[1];
    const staffId = +str[2];
    const sss = selectedStaffs.map((a) => {
      if (a.depId === depId) {
        return {
          ...a,
          jobs: a.jobs.map((j) => {
            if (j.jobId === jobId) {
              return {
                ...j,
                staffs: j.staffs.map((s) => {
                  if (s.staffId === staffId) {
                    return { ...s, active: checked };
                  }
                  return s;
                }),
              };
            }
            return j;
          }),
        };
      }
      return a;
    });
    setSelectedStaffs(sss);
    calcStaffIds(sss);
  };

  const handleToggleDepartment = (id: number) => {
    setSelectedStaffs((deps) => {
      return deps.map((dep) => {
        if (dep.depId === id) {
          return {
            ...dep,
            isOpen: !dep.isOpen,
          };
        }
        return dep;
      });
    });
  };

  const handleOpenAccessLevel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAccessLevel = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const selectedStaffsNumber = calculateLength(selectedStaffs);

  return (
    <Dialog onClose={handleCloseModal} open={show} fullWidth={true} maxWidth={'sm'}>
      <div className="flex flex-col w-full p-6">
        <div className="pb-6 text-lg font-medium leading-6 text-gray-900 border-b">
          Sending Notifications to Multiple Employees
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-2">
          <div className="grid grid-cols-1 m-6 md:m-0 md:mt-4 gap-y-2 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <div className="mt-2">
                <button
                  type="button"
                  onClick={handleOpenAccessLevel}
                  className="w-full h-10 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded shadow-sm hover:bg-indigo-700"
                >
                  <>{selectedStaffsNumber} Employee Selected</>
                </button>
                <Popover
                  id="al"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleCloseAccessLevel}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div className="flex flex-col p-4 select-none">
                    <div className="relative grid gap-2 bg-white w-[250px] max-h-[250px] overflow-y-auto divide-y divide-gray-100">
                      {selectedStaffs.map((dep) => {
                        const depKey = `${dep.depId}-${dep.depName}`;
                        return (
                          <React.Fragment key={depKey}>
                            <div className="flex items-center flex-1 p-2 mr-2 bg-gray-200">
                              <input
                                type="checkbox"
                                id={depKey}
                                checked={dep.active}
                                onChange={(e) => handleALDepartment(e)}
                                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={depKey}
                                className="flex-1 block ml-4 mr-4 text-sm font-medium text-gray-700"
                              >
                                {dep.depName}
                              </label>
                              <ChevronDownIcon
                                onClick={() => handleToggleDepartment(dep.depId)}
                                className={`cursor-pointer h-5 w-5 transition ${
                                  !dep.isOpen ? 'rotate-180 transform' : ''
                                }`}
                              />
                            </div>
                            {dep.isOpen &&
                              dep.jobs.map((job) => {
                                const jobKey = `${dep.depId}-${job.jobId}-${job.jobName}`;
                                return (
                                  <React.Fragment key={jobKey}>
                                    <div className="flex items-center flex-1 p-2 pl-4 mr-2 bg-gray-100">
                                      <input
                                        type="checkbox"
                                        id={jobKey}
                                        checked={job.active}
                                        onChange={(e) => handleALJob(e)}
                                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor={jobKey}
                                        className="flex-1 block ml-4 text-sm font-medium text-gray-700"
                                      >
                                        {job.jobName}
                                      </label>
                                    </div>
                                    {job.staffs.map((staff) => {
                                      const staffKey = `${dep.depId}-${job.jobId}-${staff.staffId}-${staff.staffName}`;
                                      return (
                                        <React.Fragment key={staffKey}>
                                          <div className="flex items-center flex-1 p-2 pl-8 mr-2">
                                            <input
                                              type="checkbox"
                                              id={staffKey}
                                              checked={staff.active}
                                              onChange={(e) => handleALStaff(e)}
                                              className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                            />
                                            <label
                                              htmlFor={staffKey}
                                              className="flex-1 block ml-4 text-sm font-medium text-gray-700"
                                            >
                                              {staff.staffName}
                                            </label>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                  </React.Fragment>
                                );
                              })}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </Popover>
              </div>
              <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                {errors.staff_ids?.message}
              </p>
            </div>
            <div className="sm:col-span-6">
              <label
                htmlFor="body"
                className="flex items-center justify-between text-sm font-medium text-gray-700"
              >
                <span>Message</span>
                <span className={`text-sm ${errors.body?.message ? 'text-red-500' : ''}`}>
                  {currentBodyLength >= 0 ? currentBodyLength : 0}/255
                </span>
              </label>
              <div className="mt-2">
                <textarea
                  id="body"
                  rows={4}
                  {...register('body')}
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                {errors.body?.message}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between w-full pt-4 h-[72px] mx-6 mt-4 border-t md:mx-0">
            <button
              type="button"
              onClick={handleCloseModal}
              className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Close
            </button>
            <button
              type="submit"
              className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default MultipleNotificationsModal;
