import { Navigate, useLocation } from 'react-router-dom';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';

export interface RequireLocationProps {
  component: JSX.Element;
}

export function RequireLocation({ component }: RequireLocationProps) {
  const activeWorkSpace = useActiveWorkspaceSlice((state) => state.workspace)!;
  const isWsLocationExist = activeWorkSpace?.default_location_id;
  const laborYearCommencement = activeWorkSpace?.labor_year_commencement;
  // const laborYearCommencement = 3;
  const location = useLocation();
  const path = decodeURIComponent(location.pathname);

  if (
    path === `/${activeWorkSpace.sub_domain}/settings/company` ||
    path === `/${activeWorkSpace.sub_domain}/settings/new-location/headquarter`
  ) {
    return component;
  }

  if (!isWsLocationExist) {
    return <Navigate to={`/${activeWorkSpace.sub_domain}/settings/new-location/headquarter`} />;
  }

  if (
    (!laborYearCommencement || laborYearCommencement <= 0) &&
    path !== `/${activeWorkSpace.sub_domain}/settings/company`
  ) {
    return <Navigate to={`/${activeWorkSpace.sub_domain}/settings/company`} />;
  }
  return component;
}

export default RequireLocation;
