import DropLoading from './drop-loading';
import './loading.scss';

// Dark: bg-[#2c3e50]
// Blue: bg-[#2980b9]
// Green: bg-[#27ae60]
// Gray: bg-[#2c3e50]
// Orange: bg-[#ffcb65]

export function Loading() {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full h-screen bg-brand-primary">
      <DropLoading />
    </div>
  );
}

export default Loading;
