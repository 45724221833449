import { create } from 'zustand';

type State = {
  email: string | null;
};

type Actions = {
  updateEmail: (email: string) => void;
  reset: () => void;
};

const initialState: State = {
  email: null,
};

export const useOtpSlice = create<State & Actions>()((set) => ({
  ...initialState,
  updateEmail: (email) => {
    set({ email });
  },
  reset: () => {
    set({ ...initialState });
  },
}));
