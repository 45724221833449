import { useState } from 'react';
import { useTitle } from 'react-use';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import { formatDateToTimeZone } from '../../utils/format-date';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import workspaceService from '../../services/workspace-service';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import Confirmation from '../../components/libs/confirmation';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';
import CompanyForm, { months } from './components/company-form';
import { customToastError } from '../../utils/custom-toast-error';
import { useLocations } from '../../hooks/use-locations';
import { useGetWorkspaces } from '../../hooks/workspaces/use-get-workspaces';

const Sorter = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

const Company = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Company`);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const activeWorkspace = useActiveWorkspaceSlice((state) => state.workspace);
  const updateActiveWorkspace = useActiveWorkspaceSlice((state) => state.updateActiveWorkspace);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { data: locationsData = [] } = useLocations();
  const locationTitle =
    locationsData.find((l) => l.id === activeWorkspace?.default_location_id)?.title ?? '-';
  const daysOfWeek = Object.keys(activeWorkspace?.weekends ?? []).sort((a, b) => {
    return (Sorter as any)[a] - (Sorter as any)[b];
  });
  const month = months.find((m) => m.id === activeWorkspace?.labor_year_commencement);
  const { data: workspaces = [] } = useGetWorkspaces();

  const { mutate: deleteWs, isLoading: deleteWsIsLoading } = useMutation(
    workspaceService.deleteWorkspace,
    {
      onSuccess: () => {
        toast.success(`Workspace Deleted Successfuly`);
        queryClient.invalidateQueries([workspaceService.workspacesQueryKey]);
        if (workspaces.length > 1) {
          updateActiveWorkspace(workspaces[0]);
          navigate(`/${workspaces[0].sub_domain}`);
        } else {
          navigate(`/`);
        }
      },
      onError: (data: AxiosError<any>) => {
        customToastError('Error', data.response?.data.message ?? 'Sorry there is a problem');
      },
    },
  );

  const handleConfirmation = (bool: boolean) => {
    if (bool) {
      deleteWs();
    }
  };

  const handleEditMode = () => {
    setEditMode((em) => !em);
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleDeleteWorkspace = () => {
    if (deleteWsIsLoading) {
      return;
    }
    handleOpenConfirmation();
  };
  if (!activeWorkspace) {
    return null;
  }

  return (
    <div className="w-full mx-auto space-y-8 pb-14">
      <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9">
        <div className="flex w-full pb-6 font-medium border-b">
          {editMode ? 'Edit Workspace' : 'Workspace Details'}
        </div>
        {editMode ? (
          <CompanyForm workspace={activeWorkspace!} handleEditMode={handleEditMode} />
        ) : (
          <>
            <div className="grid w-full grid-cols-12 gap-6 mt-6">
              <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
                <span className="px-4 py-2 font-medium rounded bg-gray-50">Workspace Name</span>
                <span className="px-4 py-2">{activeWorkspace?.title}</span>
              </div>
              <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
                <span className="px-4 py-2 font-medium rounded bg-gray-50">Business Name</span>
                <span className="px-4 py-2">{activeWorkspace?.sub_domain}</span>
              </div>
              <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
                <span className="px-4 py-2 font-medium rounded bg-gray-50">Created At</span>
                <span className="px-4 py-2">
                  {formatDateToTimeZone(activeWorkspace!.created_at, 'dd-MM-y HH:mm')}
                </span>
              </div>
              <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
                <span className="px-4 py-2 font-medium rounded bg-gray-50">Workspace Email</span>
                <span className="px-4 py-2">{activeWorkspace?.email}</span>
              </div>
              <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
                <span className="px-4 py-2 font-medium rounded bg-gray-50">
                  Workspace Time zone
                </span>
                <span className="px-4 py-2">{activeWorkspace?.timezone}</span>
              </div>
              <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
                <span className="px-4 py-2 font-medium rounded bg-gray-50">
                  Labor Year Commencement
                </span>
                <span className="px-4 py-2 capitalize">{month?.name}</span>
              </div>
              <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
                <span className="px-4 py-2 font-medium rounded bg-gray-50">Location</span>
                <span className="px-4 py-2 capitalize">{locationTitle}</span>
              </div>
              <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
                <span className="px-4 py-2 font-medium rounded bg-gray-50">Public Holiday</span>
                <span className="px-4 py-2 capitalize">
                  {activeWorkspace.holidays?.length <= 1
                    ? `${activeWorkspace.holidays?.length} Holiday`
                    : `${activeWorkspace.holidays?.length} Holidays`}
                </span>
              </div>
              <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
                <span className="px-4 py-2 font-medium rounded bg-gray-50">Weekends</span>
                <span className="flex items-center px-4 py-2 space-x-2 capitalize">
                  {daysOfWeek.map((v) => {
                    const isWeekend = activeWorkspace.weekends[v];
                    if (isWeekend) {
                      return <div key={v}>{v}</div>;
                    }
                    return null;
                  })}
                </span>
              </div>
            </div>
            <div className="flex flex-col w-full mt-12 space-y-4 md:space-y-0 md:space-x-4 md:items-center md:justify-end md:flex-row">
              <button
                type="button"
                disabled={deleteWsIsLoading}
                className="flex justify-center px-4 py-3 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleEditMode}
              >
                Edit Workspace
              </button>
              <button
                type="button"
                disabled={deleteWsIsLoading}
                className="flex justify-center px-4 py-3 text-sm font-medium text-white bg-red-500 border border-transparent rounded shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                onClick={handleDeleteWorkspace}
              >
                Delete {capitalizeFirstLetter(activeWorkspace?.title)} Workspace
              </button>
            </div>
          </>
        )}
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
        title="Are you sure you want to delete the workspace?"
        description="Warning: Deleting Workspace This action cannot be undone, and all data associated with the workspace will be permanently removed."
      />
    </div>
  );
};

export default Company;
