import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import clockService from '../services/clock-service';
import { customToastError } from '../utils/custom-toast-error';
import {
  CreateWorkTimeAttendanceRequest,
  CreateWorkTimeAttendanceResponse,
} from '../interfaces/create-worktime-attendance';

export function useCreateWorkTimeAttendance(
  configOptions: UseMutationOptions<CreateWorkTimeAttendanceResponse, any, any, any> = {},
) {
  return useMutation(
    (body: CreateWorkTimeAttendanceRequest) => clockService.createWorkTimeAttendance(body),
    {
      onError: (data: AxiosError) => {
        const err = data.response?.data as any;
        customToastError('Error', err?.message ?? 'Sorry there was a problem');
      },
      // onSettled: () => {
      // },
      ...configOptions,
    },
  );
}
