import { useState } from 'react';
import { ArrowUpIcon } from '@heroicons/react/24/outline';
import { Resource } from '../calendar-reports';
import AlarmCheckIcon from '~icons/mdi/alarm-check';
import CaledarCheckOutlineIcon from '~icons/mdi/calendar-check-outline';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/solid';
import VideoPlayer from '../../../components/libs/video-player';

interface CalendarFooterProps {
  employeesLength: number;
  resources: Resource[];
}

const calcHasBreak = (resources: Resource[]) => {
  let brs = 0;
  resources.forEach((res) => {
    res.schedules.forEach((el) => {
      brs = brs + el.breaks.length;
    });
  });
  return brs;
};

const CalendarFooter = ({ employeesLength, resources }: CalendarFooterProps) => {
  const [showScheduleVideoPlayer, setShowScheduleVideoPlayer] = useState(false);
  const [showGuidelineVideoPlayer, setShowGuidelineVideoPlayer] = useState(false);
  const hasBreak = calcHasBreak(resources);

  const handleOpenShowScheduleVideoPlayer = () => {
    setShowScheduleVideoPlayer(true);
  };

  const handleCloseShowScheduleVideoPlayer = () => {
    setShowScheduleVideoPlayer(false);
  };

  const handleOpenShowGuidelineVideoPlayer = () => {
    setShowGuidelineVideoPlayer(true);
  };

  const handleCloseShowGuidelineVideoPlayer = () => {
    setShowGuidelineVideoPlayer(false);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center w-full p-6 mt-6 space-y-2 border border-gray-100 rounded item-center bg-gray-50">
        {/* <span className="px-6 py-2 text-sm font-medium bg-green-200 border border-green-500 rounded text-sky-700">
        Employees: {employeesLength}
      </span> */}
        {employeesLength === 1 && resources.length === 1 && !resources[0].schedules.length ? (
          <>
            <span className="flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-lg animate-bounce">
              <ArrowUpIcon className="w-8 h-8 text-indigo-600" />
            </span>
            <h2 className="max-w-3xl px-4 py-4 font-medium text-center text-green-600 bg-green-100 border border-green-600 rounded shadow-xl">
              Press + to schedule your employee
            </h2>
          </>
        ) : null}
        {employeesLength === 1 &&
        resources.length === 1 &&
        resources[0].schedules.length &&
        hasBreak === 0 ? (
          <>
            <span className="flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-lg animate-bounce">
              <ArrowUpIcon className="w-8 h-8 text-indigo-600" />
            </span>
            <h2 className="max-w-3xl px-4 py-4 font-medium text-center text-green-600 bg-green-100 border border-green-600 rounded shadow-xl">
              You&apos;ve just scheduled the first employee! To Add a Break, open the Schedule &gt;
              Press + Add Break
            </h2>
          </>
        ) : null}
        <div className="w-full space-y-4">
          <div className="flex items-center justify-between w-full gap-2 pb-4 mb-2 font-medium text-gray-600 border-b">
            <span>Calendar Guideline</span>
            <div className="flex flex-col space-y-2">
              <button
                onClick={handleOpenShowScheduleVideoPlayer}
                className="text-sm text-right text-blue-600 underline transition underline-offset-2 hover:text-blue-800"
              >
                Click here to see how to Add Schedule
              </button>
              <button
                onClick={handleOpenShowGuidelineVideoPlayer}
                className="text-sm text-right text-blue-600 underline transition underline-offset-2 hover:text-blue-800"
              >
                Click here to see The Guideline
              </button>
            </div>
          </div>
          <div className="grid w-full grid-cols-2 gap-2 mb-2">
            <div className="flex items-center">
              <span className="w-4 h-4 bg-green-400 rounded"></span>
              <span className="ml-2 text-sm font-medium">Scheduled</span>
            </div>
            <div className="flex items-center">
              <span className="w-4 h-4 bg-red-400 rounded"></span>
              <span className="ml-2 text-sm font-medium">Day off from scheduled shift</span>
            </div>
          </div>
          <div className="grid w-full grid-cols-3 gap-2 px-4 py-2 bg-indigo-400 rounded">
            <span className="text-white">Hourly Time Off Request</span>
            <span className="text-white">Daily Time Off Request</span>
            <span className="text-white">Shift Swap Request</span>
          </div>
          <div className="grid w-full grid-cols-3 gap-2 px-4">
            <div className="flex flex-col space-y-2">
              <div className="flex items-center">
                <span className="flex items-center justify-center w-8 h-8 bg-indigo-400 rounded">
                  <AlarmCheckIcon className="w-6 h-6 text-white" />
                </span>
                <span className="ml-2 text-sm font-medium">Accepted</span>
              </div>
              <div className="flex items-center">
                <span className="flex items-center justify-center w-8 h-8 bg-red-400 rounded">
                  <AlarmCheckIcon className="w-6 h-6 text-white" />
                </span>
                <span className="ml-2 text-sm font-medium">Rejected</span>
              </div>
              <div className="flex items-center">
                <span className="flex items-center justify-center w-8 h-8 bg-orange-400 rounded">
                  <AlarmCheckIcon className="w-6 h-6 text-white" />
                </span>
                <span className="ml-2 text-sm font-medium">Pending</span>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex items-center">
                <span className="flex items-center justify-center w-8 h-8 bg-indigo-400 rounded">
                  <CaledarCheckOutlineIcon className="w-6 h-6 text-white" />
                </span>
                <span className="ml-2 text-sm font-medium">Accepted</span>
              </div>
              <div className="flex items-center">
                <span className="flex items-center justify-center w-8 h-8 bg-red-400 rounded">
                  <CaledarCheckOutlineIcon className="w-6 h-6 text-white" />
                </span>
                <span className="ml-2 text-sm font-medium">Rejected</span>
              </div>
              <div className="flex items-center">
                <span className="flex items-center justify-center w-8 h-8 bg-orange-400 rounded">
                  <CaledarCheckOutlineIcon className="w-6 h-6 text-white" />
                </span>
                <span className="ml-2 text-sm font-medium">Pending</span>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex items-center">
                <span className="flex items-center justify-center w-8 h-8 bg-green-400 rounded">
                  <ArrowsRightLeftIcon className="w-6 h-6 text-white" />
                </span>
                <span className="ml-2 text-sm font-medium">Accepted</span>
              </div>
              <div className="flex items-center">
                <span className="flex items-center justify-center w-8 h-8 bg-indigo-400 rounded">
                  <ArrowsRightLeftIcon className="w-6 h-6 text-white" />
                </span>
                <span className="ml-2 text-sm font-medium">Waiting</span>
              </div>
              <div className="flex items-center">
                <span className="flex items-center justify-center w-8 h-8 bg-red-400 rounded">
                  <ArrowsRightLeftIcon className="w-6 h-6 text-white" />
                </span>
                <span className="ml-2 text-sm font-medium">Rejected</span>
              </div>
              <div className="flex items-center">
                <span className="flex items-center justify-center w-8 h-8 bg-orange-400 rounded">
                  <ArrowsRightLeftIcon className="w-6 h-6 text-white" />
                </span>
                <span className="ml-2 text-sm font-medium">Pending</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoPlayer
        closeModal={handleCloseShowScheduleVideoPlayer}
        show={showScheduleVideoPlayer}
        title={'Add Schedule'}
        url={import.meta.env.VITE_PREFIX_SCHEDULE_VIDEO}
      />
      <VideoPlayer
        closeModal={handleCloseShowGuidelineVideoPlayer}
        show={showGuidelineVideoPlayer}
        title={'Calendar Guideline'}
        url={import.meta.env.VITE_PREFIX_GUIDELINE_VIDEO}
      />
    </>
  );
};

export default CalendarFooter;
