import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import clockService from '../services/clock-service';
import reportsService from '../services/reports-service';
import { customToastError } from '../utils/custom-toast-error';
import {
  RejectScheduleAttendanceRequest,
  RejectScheduleAttendanceResponse,
} from '../interfaces/reject-schedule-attendance';

interface MutationData {
  body: RejectScheduleAttendanceRequest;
  source: 'CALENDAR' | 'ATTENDANCE';
}

export function useRejectScheduleAttendance(
  configOptions: UseMutationOptions<RejectScheduleAttendanceResponse, any, MutationData, any> = {},
) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ body, source = 'ATTENDANCE' }: MutationData) => {
      return clockService.rejectScheduleAttendance(body, source);
    },
    {
      onError: (data: AxiosError) => {
        const err = data.response?.data as any;
        customToastError('Error', err?.message ?? 'Sorry there was a problem');
      },
      onSettled: () => {
        queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
      },
      ...configOptions,
    },
  );
}
