import { useTitle } from 'react-use';
import { Outlet } from 'react-router-dom';

const Settings = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Settings`);

  return <Outlet />;
};

export default Settings;
