/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTitle } from 'react-use';
import { startOfWeek, addDays, endOfWeek, differenceInMinutes, differenceInDays } from 'date-fns';
import { enGB } from 'date-fns/locale';
import CalendarHeader from './components/calendar-header';
import CalendarToolbar from './components/calendar-toolbar';
import CalendarBody from './components/calendar-body';
import { useEmployees } from '../../hooks/use-employees';
import { useCalendarSlice } from '../../store/calendar-slice';
import { Calendar } from '../../interfaces/calendar-interface';
import { groupBy } from '../../utils/group-by';
import { useCalendarLeaveRequests } from '../../hooks/use-calendar-leave-requests';
import { useCalendar } from '../../hooks/use-calendar';
import { LeaveReq } from '../../interfaces/calendar-leave-request-interface';
import { FirebaseMessageType, useFirebaseSlice } from '../../store/firebase-slice';
import { useQueryClient } from '@tanstack/react-query';
import { matchSorter } from 'match-sorter';
import CalendarFooter from './components/calendar-footer';
import Spinner from '../../components/spinner';
import calendarService from '../../services/calendar-service';
import { formatDateToTimeZone, utcDate } from '../../utils/format-date';
import EmptyStateCalendar from '../../components/empty-state-calendar';
import { useDepartments } from '../../hooks/use-departments';
import { useJobs } from '../../hooks/use-jobs';
import { useNavigate } from 'react-router-dom';
import { Employee } from '../../interfaces/employee-interface';
import staffsService from '../../services/staffs-service';
import toast from 'react-hot-toast';

export interface WeekDays {
  id: number;
  date: Date;
  formattedDate: string;
}

type Status = 'OFFLINE' | 'ONLINE' | 'BREAK';

export interface Resource {
  id: number;
  attachment: string | null | undefined;
  name: string;
  jobTitle: string;
  jobId: number;
  jobIds: number[];
  depTitle: string;
  depId: number;
  schedules: Calendar[];
  totalMins: number;
  status: Status;
  leaveRequests: LeaveReq[];
}

const handleLiveClock = (liveClock: Employee['live_clocks'][0] | undefined): Status => {
  if (
    liveClock &&
    !liveClock.clock_out &&
    (!liveClock.breaks.length || liveClock?.breaks.at(-1)?.end)
  ) {
    return 'ONLINE';
  } else if (
    liveClock &&
    !liveClock.clock_out &&
    liveClock.breaks.length &&
    !liveClock?.breaks.at(-1)?.end
  ) {
    return 'OFFLINE';
  }
  return 'OFFLINE';
};

const CalendarReports = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Calendar Reports`);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [term, setTerm] = useState('');
  const firebaseMessage = useFirebaseSlice((state) => state.message);
  const updateContextMenu = useCalendarSlice((state) => state.updateContextMenu);
  const activeResource = useCalendarSlice((state) => state.activeResource);
  const updateActiveResource = useCalendarSlice((state) => state.updateActiveResource);
  const activeDate = useCalendarSlice((state) => state.activeDate);
  const weekStartDate = startOfWeek(activeDate, { locale: enGB, weekStartsOn: 1 });
  const weekEndDate = endOfWeek(activeDate, { locale: enGB, weekStartsOn: 1 });
  const from = utcDate(weekStartDate).getTime();
  const to = utcDate(weekEndDate).getTime();
  const { data: employees = [], isLoading: employeesLoading } = useEmployees();
  const { data: leaveRequests = [] } = useCalendarLeaveRequests(from, to);
  const { data: schedules = {}, isLoading: schedulesLoading } = useCalendar(from, to);
  const { data: jobs = [] } = useJobs();
  const departments = useDepartments();
  const filteredEmployees = useMemo(() => {
    if (!term) {
      return employees;
    }
    return matchSorter(employees, term, {
      keys: ['first_name', 'last_name', 'email', 'mobile'],
    });
  }, [employees, term, leaveRequests]);
  const weekDays = Array.from(Array(7).keys()).map((idx) => {
    const dateForEachDay = addDays(utcDate(weekStartDate), idx);
    return {
      id: idx,
      date: dateForEachDay,
      formattedDate: formatDateToTimeZone(dateForEachDay, 'E'),
    };
  });

  useEffect(() => {
    const type = firebaseMessage?.type as FirebaseMessageType | undefined;
    if (type === 'leaveRequestCreated' || type === 'leaveRequestEdited') {
      queryClient.invalidateQueries([calendarService.leaveRequestsQueryKey]);
    } else {
      queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
      queryClient.invalidateQueries([staffsService.staffsQueryKey]);
    }
  }, [firebaseMessage]);

  useEffect(() => {
    const workingHourIsNotSet = employees.find((e) => e.hour_per_month < 1 || e.day_hour < 1);
    if (workingHourIsNotSet) {
      toast.error('Please set working hours first');
      navigate('../employee-work-space/employees');
    }
  }, [employees]);

  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      // resetCalendarState();
    };
  }, []);

  useEffect(() => {
    if (activeResource) {
      updateActiveResource({
        ...activeResource!,
        leaveRequests: activeResource!.leaveRequests.map((lr) => {
          const findLr = leaveRequests.find((el) => el.id === lr.id);
          if (findLr) {
            return findLr;
          }
          return lr;
        }),
      });
    }
  }, [leaveRequests]);

  const handleContextMenu = useCallback((event: any) => {
    event.preventDefault();
    updateContextMenu(null);
  }, []);

  const resources: Resource[] = filteredEmployees.map((emp) => {
    let lrs: LeaveReq[] = [];
    const userSchedules =
      schedules[emp.id.toString()]?.filter(
        (sch) => sch.built_by_staff === false || sch.built_by_staff === 0,
      ) ?? [];
    const status = handleLiveClock(emp.live_clocks?.at(-1));
    leaveRequests.forEach((lr) => {
      if (lr.staff_id === emp.id) {
        if (lr.all_day === 0 && lr.status === 'approved') {
          let splitLrs: LeaveReq[] = [];
          const diff = differenceInDays(new Date(lr.end_at), new Date(lr.start_at));
          for (let index = 0; index <= diff; index++) {
            let day = addDays(lr.start_at, index);
            splitLrs = [
              ...splitLrs,
              {
                ...lr,
                start_at: day.getTime(),
              },
            ];
            day = addDays(day, 1);
          }
          lrs = [...lrs, ...splitLrs];
        } else {
          lrs = [...lrs, lr];
        }
      }
    });
    let totalMins = 0;
    userSchedules.forEach((sch) => {
      totalMins = totalMins + differenceInMinutes(sch.end_at, sch.start_at);
    });
    const department = departments.data?.find((d) => d.id === emp.job.department_id);
    return {
      id: emp.id,
      attachment: emp.attachment,
      name: `${emp.first_name} ${emp.last_name}`,
      jobTitle: emp.job.title,
      jobId: emp.job.id,
      jobIds: emp.job_ids ?? [],
      depTitle: department?.title ?? emp.job.department_id.toString(),
      depId: emp.job.department_id,
      schedules: userSchedules,
      totalMins,
      status,
      leaveRequests: lrs,
    };
  });
  const resourceGroup = groupBy(resources, 'depTitle');

  const handleTermChanges = useCallback((term: string) => {
    setTerm(term);
  }, []);

  if (employeesLoading) {
    return (
      <div className="flex items-end justify-center w-full my-9">
        <span className="mr-4">Please wait</span>
        <Spinner size="small" />
      </div>
    );
  }

  if (employeesLoading === false && !employees.length) {
    return (
      <EmptyStateCalendar
        path="../employee-work-space/employees/employees-create?redirect=calendar"
        buttonName="Add Employee"
        message="No employees yet! Press “Add Employee” to get started"
        extraMessage="You can add one or more employees to your Workspace. Just provide us with basic information. We will send them an email with login data and a link to download the app.
        You can also download our tool and enjoy it on your phone."
        showBtn={true}
        showImg={true}
        videoUrl={import.meta.env.VITE_PREFIX_ADD_EMPLOYEE_VIDEO}
        videoTitle={'Add Employee'}
      />
    );
  }

  return (
    <div className="mx-auto space-y-8 pb-14 w-[1336px] lg:w-full">
      <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9">
        <CalendarToolbar
          weekStartDate={weekStartDate}
          weekEndDate={weekEndDate}
          employeesLoading={employeesLoading}
          schedulesLoading={schedulesLoading}
          schedules={schedules}
          resources={resources}
        />
        <div className="flex flex-col flex-1 w-full overflow-hidden border rounded-lg">
          <CalendarHeader weekDays={weekDays} handleTermChanges={handleTermChanges} />
          <CalendarBody weekDays={weekDays} resourceGroup={resourceGroup} />
        </div>
        <CalendarFooter employeesLength={employees?.length ?? 0} resources={resources} />
      </div>
    </div>
  );
};

export default CalendarReports;
