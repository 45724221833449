import { Profile, ProfileRequest } from '../interfaces/profile-interface';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import http from './axios-instance';

export interface IChangePassword {
  password: string;
  new_password: string;
  password_confirmation: string;
}

const logout = async (): Promise<any> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/profile/log_out`;
  const res = await http.post(url, null);
  return res.data;
};

const changeImage = async (data: FormData): Promise<Profile> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/profile/change_image`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const changePassword = async (data: IChangePassword): Promise<any> => {
  // const workspace = useActiveWorkspaceSlice.getState().workspace;
  // const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/profile/change_password`;
  const res = await http.post(url, data);
  return res.data;
};

const changeProfile = async (data: ProfileRequest): Promise<Profile> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/profile/change_profile`;
  const res = await http.post(url, data);
  return res.data;
};

const sendFirebaseToken = async (data: {
  device_token: string;
  meta: string;
}): Promise<Profile> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/profile/store_device`;
  const res = await http.post(url, data);
  return res.data;
};

const profileService = {
  logout,
  changeImage,
  changePassword,
  changeProfile,
  sendFirebaseToken,
};

export default profileService;
