import { useEffect, useState } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useCalendarSlice } from '../../../store/calendar-slice';
import { dateUtc, formatDateToTimeZone, utcDate } from '../../../utils/format-date';
import {
  getHours,
  getMinutes,
  isAfter,
  setHours,
  setMinutes,
  isValid,
  isSameDay,
  format,
} from 'date-fns';
import calendarService, { WorktimeDeleteType } from '../../../services/calendar-service';
import { AxiosError } from 'axios';
import { useJobs } from '../../../hooks/use-jobs';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';
import Select from '@mui/material/Select';
import { FormControl, MenuItem, useMediaQuery } from '@mui/material';
import { customToastError } from '../../../utils/custom-toast-error';
import { UpdateWorktimeRequest } from '../../../interfaces/worktime-interface';
import Confirmation from '../../../components/libs/confirmation';
import { useActiveWorkspaceSlice } from '../../../store/active-workspace-slice';

interface IForm {
  start_at: string | Date | null;
  end_at: string | Date | null;
  job_id: any;
}

const schema = yup
  .object({
    job_id: yup.number().positive().required('Please select a job'),
    start_at: yup
      .string()
      .transform((value) => value ?? undefined)
      .required('Please fill the Start Time')
      .test('isReq', 'Invalid Time', (val: any, ctx) => {
        if (val && isValid(new Date(val))) {
          return true;
        }
        return false;
      }),
    end_at: yup
      .string()
      .transform((value) => value ?? undefined)
      .required('Please fill the End Time')
      .test('isReq', 'Invalid Time', (val: any, ctx) => {
        const start = ctx.parent.start_at;
        if (start && val && isAfter(new Date(val), new Date(start))) {
          return true;
        }
        return false;
      }),
  })
  .required();

const AddWorktime = () => {
  const matches = useMediaQuery('(min-width:1024px)');
  const queryClient = useQueryClient();
  const ws = useActiveWorkspaceSlice((state) => state.workspace);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const selectedDate = useCalendarSlice((state) => state.selectedDate);
  const activeResource = useCalendarSlice((state) => state.activeResource);
  const worktimeForUpdate = useCalendarSlice((state) => state.worktimeForUpdate);
  const toggleScheduleModal = useCalendarSlice((state) => state.updateShowScheduleModal);
  const updateWorktimeForUpdate = useCalendarSlice((state) => state.updateWorktimeForUpdate);
  const updateScheduleModalTab = useCalendarSlice((state) => state.updateScheduleModalTab);
  const updateActiveResource = useCalendarSlice((state) => state.updateActiveResource);
  const { data: jobs = [] } = useJobs();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<IForm>({
    defaultValues: {
      start_at: null,
      end_at: null,
      job_id: activeResource?.jobId ?? 0,
    },
    resolver: yupResolver<any>(schema),
  });
  const options: any[] = jobs
    .filter((j) => activeResource?.jobIds?.includes(j.id) || j.id === activeResource?.jobId)
    .map((j) => {
      return {
        value: j.id,
        label: capitalizeFirstLetter(j.title),
      };
    });

  useEffect(() => {
    if (worktimeForUpdate) {
      setValue('start_at', dateUtc(worktimeForUpdate.start_at));
      setValue('end_at', dateUtc(worktimeForUpdate.end_at));
      setValue('job_id', worktimeForUpdate.job_id ?? activeResource!.jobId);
    }
  }, [worktimeForUpdate]);

  const { mutate: createWorktime, isLoading: createWorktimeIsLoading } = useMutation(
    calendarService.createWorktime,
    {
      onSuccess: () => {
        updateWorktimeForUpdate(null);
        handleCloseModal();
        queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
      },
      onError: (data: AxiosError) => {
        const err = data.response?.data as any;
        customToastError('Error', err?.message ?? 'Sorry there was a problem');
        // console.log(data.response?.data);
      },
    },
  );

  const { mutate: updateWorktime, isLoading: updateWorktimeIsLoading } = useMutation(
    ({ params, type }: { params: UpdateWorktimeRequest; type: WorktimeDeleteType }) =>
      calendarService.updateWorktime(params, type),
    {
      onSuccess: (data, variables) => {
        updateWorktimeForUpdate(null);
        updateActiveResource({
          ...activeResource!,
          schedules: activeResource!.schedules
            .filter((s) => s.staff_id === data.staff_id)
            .map((s) => {
              if (s.id === variables.params.working_object_id) {
                return {
                  ...s,
                  staff_id: data.staff_id,
                  start_at: +data.start_at,
                  end_at: +data.end_at,
                };
              }
              return s;
            }),
        });
        updateScheduleModalTab('Worktime List');
        queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
      },
      onError: (data: AxiosError) => {
        const err = data.response?.data as any;
        customToastError('Error', err?.message ?? 'Sorry there was a problem');
        // console.log(data.response?.data);
      },
    },
  );

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (createWorktimeIsLoading || updateWorktimeIsLoading) {
      return;
    }
    if (
      !data.start_at ||
      !data.end_at ||
      data.start_at === 'Invalid Date' ||
      data.end_at === 'Invalid Date'
    ) {
      customToastError('Error', `Please enter valid Start and End Times`);
      return;
    }
    const dayString = format(selectedDate!, 'EEEE').toLowerCase();
    const isWeekend = ws?.weekends?.[dayString];
    let isHolidayAlreadyExist = false;
    ws?.holidays.forEach((h: { date: number; description: string }) => {
      if (isSameDay(h.date, selectedDate!)) {
        isHolidayAlreadyExist = true;
      }
    });
    if (isWeekend || isHolidayAlreadyExist) {
      handleOpenConfirmation();
    } else {
      handleConfirmation(true);
    }
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && (!createWorktimeIsLoading || !updateWorktimeIsLoading)) {
      const data = getValues();
      const sH = getHours(new Date(data.start_at!));
      const sM = getMinutes(new Date(data.start_at!));
      const eH = getHours(new Date(data.end_at!));
      const eM = getMinutes(new Date(data.end_at!));
      const start = setHours(setMinutes(selectedDate!, sM), sH);
      const end = setHours(setMinutes(selectedDate!, eM), eH);
      const start_at = utcDate(start).getTime();
      const end_at = utcDate(end).getTime();
      if (!worktimeForUpdate) {
        createWorktime({
          staff_id: activeResource!.id,
          job_id: data.job_id,
          start_at,
          end_at,
        });
      } else {
        updateWorktime({
          params: {
            job_id: data.job_id,
            start_at,
            end_at,
            working_object_id: worktimeForUpdate.id,
          },
          type: 'CALENDAR',
        });
      }
    }
  };

  const handleCloseModal = () => {
    toggleScheduleModal(false);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full h-full flex flex-col items-center justify-between min-h-[400px]"
      >
        <div className="w-full divide-y divide-gray-200">
          <div className="flex items-center justify-between pb-6 text-sm font-medium">
            <span className="text-gray-700">
              You are scheduling{' '}
              <span className="font-semibold capitalize first-letter:capitalize">
                {activeResource?.name}
              </span>{' '}
              on:
            </span>
            <span className="text-blue-700">
              {formatDateToTimeZone(selectedDate, 'eeee, dd LLL , yyyy')}
            </span>
          </div>
          <div className="grid grid-cols-1 pt-6 gap-y-2 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <Controller
                name="start_at"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    label="Start Time"
                    ampm={false}
                    {...field}
                    renderInput={(params) => (
                      <TextField
                        fullWidth={true}
                        {...params}
                        size="small"
                        error={!!errors?.start_at?.message}
                      />
                    )}
                  />
                )}
              />
              <p className="h-8 pt-2 text-red-500 first-letter:capitalize">
                {errors?.start_at?.message}
              </p>
            </div>
            <div className="sm:col-span-3">
              <Controller
                name="end_at"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    label="End Time"
                    ampm={false}
                    {...field}
                    renderInput={(params) => (
                      <TextField
                        fullWidth={true}
                        {...params}
                        size="small"
                        error={!!errors?.end_at?.message}
                      />
                    )}
                  />
                )}
              />
              <p className="h-8 pt-2 text-red-500 first-letter:capitalize">
                {errors.end_at?.message}
              </p>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="staffs" className="block text-sm font-medium text-gray-700">
                Job
              </label>
              <div className="mt-2">
                <Controller
                  name="job_id"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      {/* <InputLabel id="demo-select-small">Type</InputLabel> */}
                      <Select autoWidth={matches ? false : true} label="" {...field}>
                        {options.map((j) => {
                          return (
                            <MenuItem key={j.value} value={j.value}>
                              {j.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between w-full pt-6 mx-6 mt-6 border-t md:mx-0">
          <button
            type="button"
            disabled={createWorktimeIsLoading || updateWorktimeIsLoading}
            onClick={handleCloseModal}
            className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Close
          </button>
          <button
            type="submit"
            disabled={createWorktimeIsLoading || updateWorktimeIsLoading}
            className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Confirm
          </button>
        </div>
      </form>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
      />
    </>
  );
};

export default AddWorktime;
