import { useRef } from 'react';
import { DocumentDuplicateIcon, UserPlusIcon } from '@heroicons/react/24/solid';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Calendar } from '../../../interfaces/calendar-interface';
import { useCalendarSlice } from '../../../store/calendar-slice';
import { Resource } from '../calendar-reports';
import CalendarCopyModal from './calendar-copy-modal';
import { utcDate } from '../../../utils/format-date';
import calendarService from '../../../services/calendar-service';
import reportsService from '../../../services/reports-service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Tooltip } from '@mui/material';
import CalendarFlagModal from './calendar-flag-modal';
import { customToastError } from '../../../utils/custom-toast-error';

interface CalendarToolsProps {
  resources: Resource[];
  loading: boolean;
  schedules: Record<string, Calendar[]>;
  weekStartDate: Date;
  weekEndDate: Date;
}

const CalendarTools = ({
  loading,
  schedules,
  // resources,
  weekStartDate,
  weekEndDate,
}: CalendarToolsProps) => {
  const queryClient = useQueryClient();
  const csvInputFileRef = useRef<HTMLInputElement | null>(null);
  const updateShowCopyModal = useCalendarSlice((state) => state.updateShowCopyModal);
  const updateShowFlagModal = useCalendarSlice((state) => state.updateShowFlagModal);
  const from = utcDate(weekStartDate).getTime();
  const to = utcDate(weekEndDate).getTime();

  const { mutate: importMutate } = useMutation(reportsService.importCalendar, {
    onSuccess: () => {
      toast.success('Imported Successfuly');
      handleClearCsvInputFIle();
      queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
    },
    onError: (data: AxiosError) => {
      if (data.response?.status === 406) {
        const err = data.response?.data as any;
        customToastError('Error', err?.message ?? 'Sorry there was a problem');
        updateShowFlagModal(true);
      } else {
        handleClearCsvInputFIle();
      }
    },
  });

  const handleClearCsvInputFIle = () => {
    csvInputFileRef.current!.value = '';
  };

  const handleCsvInputFileWithFlag = (flag: 'skip' | 'overwrite' | null = null) => {
    const file = csvInputFileRef.current!.files?.[0] as File | undefined;
    if (file && flag) {
      const formData = new FormData();
      formData.append('start', String(from));
      formData.append('importFile', file);
      formData.append('flag', flag);
      importMutate(formData);
    }
  };

  const handleCopyCalendar = () => {
    if (loading) {
      toast.error('Please wait data is loading.');
      return;
    }
    if (!Object.values(schedules).length) {
      toast.error('There is no Schedule to copy.');
      return;
    }
    updateShowCopyModal(true);
  };

  return (
    <>
      <div className="flex items-center justify-end space-x-2 item-center">
        <Tooltip title="Add Employee">
          <Link
            to={`../employee-work-space/employees/employees-create?redirect=calendar`}
            className="flex items-center justify-center w-10 h-10 text-white transition rounded bg-brand-primary"
          >
            <UserPlusIcon className="w-6 h-6" />
          </Link>
        </Tooltip>
        <Tooltip title="Copy">
          <button
            type="button"
            onClick={handleCopyCalendar}
            className="flex items-center justify-center w-10 h-10 text-white transition rounded bg-brand-primary"
          >
            <DocumentDuplicateIcon className="w-6 h-6" />
          </button>
        </Tooltip>
      </div>
      <CalendarCopyModal schedules={schedules} from={from} to={to} />
      <CalendarFlagModal
        handleCsvInputFileWithFlag={handleCsvInputFileWithFlag}
        handleClearCsvInputFIle={handleClearCsvInputFIle}
      />
    </>
  );
};

export default CalendarTools;
