import { toast } from 'react-hot-toast';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const customToastError = (title = 'Error', context = 'Please try again!') => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } max-w-md w-full bg-red-100 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4 py-6">
        <div className="flex items-start">
          <div className="flex-shrink-0 p-2 bg-red-400 rounded-full">
            <XMarkIcon className="w-8 h-8 text-white" />
          </div>
          <div className="flex-1 ml-4">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            <p className="mt-1 text-sm text-gray-600">{context}</p>
          </div>
        </div>
      </div>
      {/* <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="flex items-center justify-center w-full p-4 text-sm font-medium text-indigo-600 border border-transparent rounded-none rounded-r-lg hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Close
        </button>
      </div> */}
    </div>
  ));
};
