export function groupBy<T>(collection: T[], property: keyof T) {
  let val: any;
  let index: number;
  const values: T[] = [];
  const result: T[][] = [];
  for (let i = 0; i < collection.length; i++) {
    val = collection[i][property];
    index = values.indexOf(val);
    if (index > -1) result[index].push(collection[i]);
    else {
      values.push(val);
      result.push([collection[i]]);
    }
  }
  return result;
}
