import { Tab, useCalendarSlice } from '../../../store/calendar-slice';
import { Resource, WeekDays } from '../calendar-reports';
import { isSameDay, format } from 'date-fns';
import { PlusIcon } from '@heroicons/react/24/solid';
import { dateUtc } from '../../../utils/format-date';
import EmployeeInfo from './employee-info';
import { allowToModify } from '../../../utils/allow-to-modify';
import Tooltip from '@mui/material/Tooltip';
import TimeOffIndicator from './time-off-indicator';
import { StarIcon, ArrowsRightLeftIcon } from '@heroicons/react/24/solid';
import { Draggable, DraggableProvidedDragHandleProps, Droppable } from 'react-beautiful-dnd';
import { calcResource } from '../../../utils/calc-resource';
import { useActiveWorkspaceSlice } from '../../../store/active-workspace-slice';

interface EmployeeRowProps {
  weekDays: WeekDays[];
  resource: Resource;
  dragHandler: DraggableProvidedDragHandleProps | null | undefined;
}

const EmployeeRow = ({ weekDays, resource, dragHandler }: EmployeeRowProps) => {
  const ws = useActiveWorkspaceSlice((state) => state.workspace);
  const updateContextMenuData = useCalendarSlice((state) => state.updateContextMenuData);
  const updateContextMenu = useCalendarSlice((state) => state.updateContextMenu);
  const updateScheduleModalTab = useCalendarSlice((state) => state.updateScheduleModalTab);
  const updateShowScheduleModal = useCalendarSlice((state) => state.updateShowScheduleModal);
  const updateSelectedDate = useCalendarSlice((state) => state.updateSelectedDate);
  const updateActiveResource = useCalendarSlice((state) => state.updateActiveResource);

  const handleNewSchedule = (resource: Resource, date: Date, tab: Tab) => {
    if (tab !== 'Time off Requests') {
      resource.leaveRequests.forEach((ev) => {
        if (tab === 'Add Worktime' && isSameDay(ev.start_at, date) && ev.status === 'pending') {
          tab = 'Time off Requests';
        }
      });
    }
    updateScheduleModalTab(tab);
    updateActiveResource(resource);
    updateSelectedDate(date);
    updateShowScheduleModal(true);
  };

  const handleEditSchedule = (tab: Tab, resource: Resource, date: Date) => {
    updateScheduleModalTab(tab);
    updateActiveResource(resource);
    updateSelectedDate(date);
    updateShowScheduleModal(true);
  };

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, date: Date) => {
    e.stopPropagation();
    e.preventDefault();
    const canModify = allowToModify(date);
    const schedules = resource.schedules.filter((ev) => {
      return isSameDay(dateUtc(ev.start_at), dateUtc(date));
    });
    const find = resource.leaveRequests.find(
      (ev) => isSameDay(ev.start_at, date) && ev.all_day === 0 && ev.status === 'approved',
    );
    if (find) return;
    updateContextMenuData({
      canModify,
      date,
      resource: { ...resource, schedules },
    });
    updateContextMenu({ x: e.pageX, y: e.pageY });
  };

  return (
    <>
      <div key={resource.id} className="flex items-center h-20 border-b group-last:last:border-b-0">
        <EmployeeInfo
          status={resource.status}
          name={resource.name}
          attachment={resource.attachment}
          totalMins={resource.totalMins}
          jobTitle={resource.jobTitle}
          dragHandler={dragHandler}
        />
        <div className="grid w-full h-20 grid-cols-7 overflow-hidden bg-gray-50">
          {weekDays.map(({ id, date }, idx) => {
            const dayString = format(date, 'EEEE').toLowerCase();
            const isWeekend = ws?.weekends?.[dayString];
            const transformedResource = calcResource(date, resource);
            let isHoliday = false;
            ws?.holidays?.forEach((h: { date: number }) => {
              if (isSameDay(date, h.date)) {
                isHoliday = true;
              }
            });
            return (
              <Droppable key={id} droppableId={`${resource.id}-${date.getTime()}`} type="date-slot">
                {(provided, snapshot) => {
                  return (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <>
                        {isHoliday || isWeekend || transformedResource.dayLeaveAccepted.length ? (
                          <div
                            onContextMenu={(e) => handleContextMenu(e, date)}
                            onClick={() =>
                              handleEditSchedule(
                                'Worktime List',
                                { ...resource, schedules: transformedResource.schedules },
                                date,
                              )
                            }
                            className={`h-20 flex items-center justify-center font-medium text-sm text-gray-600 border-l p-2 cursor-pointer ${
                              !transformedResource.canModify && 'bg-gray-50'
                            }`}
                          >
                            <div
                              className={`relative flex flex-col items-center justify-center w-full h-full rounded ${
                                (isHoliday || isWeekend) && transformedResource.schedules.length
                                  ? 'bg-sky-400'
                                  : 'bg-red-400'
                              }`}
                            >
                              <span
                                className="text-sm font-medium text-center text-white rounded line-clamp-2 "
                                title={
                                  isWeekend
                                    ? 'Weekend'
                                    : isHoliday
                                    ? 'Public Holiday'
                                    : transformedResource.dayLeaveAccepted?.[0]?.leave?.title
                                }
                              >
                                <span className="font-semibold">
                                  {isWeekend ? 'Weekend' : isHoliday ? 'Public Holiday' : 'Day Off'}
                                </span>
                                <span className="text-xs text-center capitalize line-clamp-1">
                                  {transformedResource.reason ? (
                                    transformedResource.reason
                                  ) : (isHoliday || isWeekend) &&
                                    transformedResource.schedules.length ? (
                                    <>
                                      <span className="font-semibold">
                                        {transformedResource.start}
                                      </span>
                                      <span className="mx-[2px]">-</span>
                                      <span className="font-semibold">
                                        {transformedResource.end}
                                      </span>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </span>
                            </div>
                          </div>
                        ) : transformedResource.schedules.length ? (
                          <div
                            onContextMenu={(e) => handleContextMenu(e, date)}
                            className={`relative h-20 flex items-center justify-end font-medium text-sm text-gray-600 border-l p-2 ${
                              !transformedResource.canModify && 'bg-gray-50'
                            }`}
                            key={`${resource.id}-${transformedResource.schedules
                              .at(0)!
                              .id!.toString()}`}
                          >
                            <Draggable
                              draggableId={`${resource.id}-${transformedResource.schedules
                                .at(0)!
                                .start_at!.toString()}`}
                              index={idx}
                            >
                              {(provided, snapshot) => (
                                <>
                                  <div
                                    onClick={() =>
                                      handleEditSchedule(
                                        'Worktime List',
                                        { ...resource, schedules: transformedResource.schedules },
                                        date,
                                      )
                                    }
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="relative flex flex-col items-center justify-center flex-1 w-full h-full overflow-hidden bg-green-200 border border-green-500 rounded cursor-pointer text-sky-700"
                                  >
                                    <span className="text-xs font-medium text-center 2xl:text-sm line-clamp-1">
                                      <span className="font-semibold">
                                        {transformedResource.start}
                                      </span>
                                      <span className="mx-[2px]">-</span>
                                      <span className="font-semibold">
                                        {transformedResource.end}
                                      </span>
                                    </span>
                                    <span className="text-[10px] 2xl:text-xs text-center capitalize line-clamp-1">
                                      {transformedResource.brTime}
                                    </span>
                                    {transformedResource.jobTitles.length > 0 ? (
                                      <div className="flex items-center justify-center">
                                        <StarIcon className="-mt-1 w-4 h-4 mr-[2px] text-red-400 shrink-0" />
                                        <span className="text-xs text-center capitalize break-all line-clamp-1">
                                          {transformedResource.jobTitles[0]}
                                        </span>
                                      </div>
                                    ) : null}
                                    {transformedResource.scheduleBySupervisor.length > 1 &&
                                    !transformedResource.jobTitles.length ? (
                                      <span className="text-xs text-center capitalize line-clamp-1">
                                        {`${transformedResource.scheduleBySupervisor.length} Split Shifts`}
                                      </span>
                                    ) : null}
                                  </div>
                                  {snapshot.isDragging ? (
                                    <div className="absolute flex flex-col items-center justify-center overflow-hidden !w-[calc(100%-16px)] bg-green-200 border border-green-500 rounded cursor-pointer top-2 left-2 right-2 bottom-2 text-sky-700">
                                      <span className="text-xs font-medium text-center 2xl:text-sm line-clamp-1">
                                        <span className="font-semibold">
                                          {transformedResource.start}
                                        </span>
                                        <span className="mx-[2px]">-</span>
                                        <span className="font-semibold">
                                          {transformedResource.end}
                                        </span>
                                      </span>
                                      <span className="text-[10px] 2xl:text-xs text-center capitalize line-clamp-1">
                                        {transformedResource.brTime}
                                      </span>
                                      {transformedResource.jobTitles.length > 0 ? (
                                        <div className="flex items-center justify-center">
                                          <StarIcon className="-mt-1 w-4 h-4 mr-[2px] text-red-400 shrink-0" />
                                          <span className="text-xs text-center capitalize break-all line-clamp-1">
                                            {transformedResource.jobTitles[0]}
                                          </span>
                                        </div>
                                      ) : null}
                                      {transformedResource.scheduleBySupervisor.length > 1 &&
                                      !transformedResource.jobTitles.length ? (
                                        <span className="text-xs text-center capitalize line-clamp-1">
                                          {`${transformedResource.scheduleBySupervisor.length} Split Shifts`}
                                        </span>
                                      ) : null}
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </Draggable>
                            {transformedResource.isLeaveRequestActive ||
                            transformedResource.sw.length > 0 ? (
                              <div className="flex flex-col items-center justify-between ml-1 h-full max-w-[24px] space-y-1">
                                {transformedResource.isLeaveRequestActive ? (
                                  <div
                                    className="w-full h-full cursor-pointer"
                                    onClick={() =>
                                      handleEditSchedule(
                                        'Time off Requests',
                                        {
                                          ...resource,
                                          schedules: transformedResource.schedules,
                                        },
                                        date,
                                      )
                                    }
                                  >
                                    <TimeOffIndicator
                                      hourLeaveAccepted={transformedResource.hourLeaveAccepted}
                                      dayLeavePending={transformedResource.dayLeavePending}
                                      hourLeavePending={transformedResource.hourLeavePending}
                                      hourLeaveDeclined={transformedResource.hourLeaveDeclined}
                                      dayLeaveDeclined={transformedResource.dayLeaveDeclined}
                                    />
                                  </div>
                                ) : null}
                                {transformedResource.sw.length > 0 ? (
                                  <div
                                    className={`flex items-center justify-center h-full w-full cursor-pointer rounded bg-indigo-400 ${
                                      transformedResource.sw.at(-1)?.status === 'pending'
                                        ? '!bg-orange-400'
                                        : ''
                                    } ${
                                      transformedResource.sw.at(-1)?.status === 'approved'
                                        ? '!bg-green-400'
                                        : ''
                                    } ${
                                      transformedResource.sw.at(-1)?.status === 'declined'
                                        ? '!bg-red-400'
                                        : ''
                                    }`}
                                    onClick={() =>
                                      handleEditSchedule(
                                        'Shift Swap Requests',
                                        {
                                          ...resource,
                                          schedules: transformedResource.schedules,
                                        },
                                        date,
                                      )
                                    }
                                  >
                                    <Tooltip
                                      title={
                                        transformedResource.sw.at(-1)?.status === 'pending'
                                          ? 'Pending Shift Swap Request'
                                          : transformedResource.sw.at(-1)?.status === 'approved'
                                          ? 'Approved Shift Swap Request'
                                          : transformedResource.sw.at(-1)?.status === 'declined'
                                          ? 'Rejected Shift Swap Request'
                                          : 'Pending Approval Shift Swap Request'
                                      }
                                      placement="top"
                                    >
                                      <span>
                                        <ArrowsRightLeftIcon className="w-6 h-6 text-white" />
                                      </span>
                                    </Tooltip>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div
                            onContextMenu={(e) => handleContextMenu(e, date)}
                            className={`relative h-20 flex items-center justify-center font-medium text-sm text-gray-600 border-l p-2 ${
                              !transformedResource.canModify && 'bg-gray-50'
                            }`}
                          >
                            <div
                              onClick={() => handleNewSchedule(resource, date, 'Add Worktime')}
                              className="relative flex flex-col items-center justify-center flex-1 w-full h-full overflow-hidden bg-gray-100 border border-gray-300 rounded"
                            >
                              <Tooltip title="Add Schedule" placement="top">
                                <span>
                                  <PlusIcon className="w-6 h-6 text-blue-400 cursor-pointer" />
                                </span>
                              </Tooltip>
                            </div>
                            <div
                              className={`h-full cursor-pointer ${
                                transformedResource.hourLeaveAccepted.length ||
                                transformedResource.dayLeavePending.length ||
                                transformedResource.hourLeavePending.length ||
                                transformedResource.hourLeaveDeclined.length ||
                                transformedResource.dayLeaveDeclined.length
                                  ? 'ml-1'
                                  : ''
                              }`}
                              onClick={() => handleNewSchedule(resource, date, 'Time off Requests')}
                            >
                              <TimeOffIndicator
                                hourLeaveAccepted={transformedResource.hourLeaveAccepted}
                                dayLeavePending={transformedResource.dayLeavePending}
                                hourLeavePending={transformedResource.hourLeavePending}
                                hourLeaveDeclined={transformedResource.hourLeaveDeclined}
                                dayLeaveDeclined={transformedResource.dayLeaveDeclined}
                              />
                            </div>
                          </div>
                        )}
                        {provided.placeholder}
                      </>
                    </div>
                  );
                }}
              </Droppable>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EmployeeRow;
