import { useQuery } from '@tanstack/react-query';
import leaveService from '../services/leave-service';

export function useLeaveRequests(page?: number, offset?: number) {
  return useQuery(
    [leaveService.leavesRequestsQueryKey, page, offset],
    () => leaveService.getLeaveRequests({ page, offset }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );
}
