import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { getHours, getMinutes, isBefore, setHours, setMinutes, setSeconds } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { dateUtc, utcDate } from '../../../utils/format-date';
import reportsService from '../../../services/reports-service';
import { useAttendanceClockSlice } from '../../../store/attendance-clock-slice';
import clockService from '../../../services/clock-service';
import { AxiosError } from 'axios';
import { customToastError } from '../../../utils/custom-toast-error';

interface IForm {
  text: string;
  start_at: string | Date | null;
  end_at: string | Date | null;
}

const schema = yup
  .object({
    text: yup.string().required('Please fill the reason'),
    start_at: yup
      .date()
      .transform((value) => value ?? undefined)
      .required('Please fill the Start Time'),
    end_at: yup
      .date()
      .transform((value) => value ?? undefined)
      .required('Please fill the End Time'),
  })
  .required();

const EditClockModal = () => {
  const queryClient = useQueryClient();
  const clock = useAttendanceClockSlice((state) => state.clock);
  const isClockOpen = useAttendanceClockSlice((state) => state.isClockOpen);
  const resetClock = useAttendanceClockSlice((state) => state.updateClock);
  const closeModal = useAttendanceClockSlice((state) => state.updateIsClockOpen);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<IForm>({
    resolver: yupResolver<any>(schema),
  });

  useEffect(() => {
    if (clock) {
      setValue('start_at', dateUtc(clock.clock_in));
      setValue('end_at', dateUtc(clock.clock_out!));
    } else {
      setValue('start_at', null);
      setValue('end_at', null);
    }
  }, [clock]);

  const { mutate: updateClock, isLoading: updateClockIsLoading } = useMutation(
    clockService.updateClock,
    {
      onSuccess: (data) => {
        handleCloseModal();
      },
      onError(error: AxiosError<any>) {
        customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
      },
      onSettled: () => {
        queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
      },
    },
  );

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (updateClockIsLoading) {
      return;
    }
    const startH = setHours(clock!.clock_in, getHours(new Date(data.start_at!)));
    const startM = setMinutes(startH, getMinutes(new Date(data.start_at!)));
    const clock_in = utcDate(setSeconds(startM, 0)).getTime();
    const endH = setHours(clock!.clock_out!, getHours(new Date(data.end_at!)));
    const endM = setMinutes(endH, getMinutes(new Date(data.end_at!)));
    const clock_out = utcDate(setSeconds(endM, 0)).getTime();
    if (isBefore(endM, startM)) {
      customToastError('Error', `Please Select a valid time`);
      return;
    }
    updateClock({
      status_edited_comment: data.text,
      clock_id: clock!.id,
      clock_in,
      clock_out,
    });
  };

  const handleCloseModal = () => {
    closeModal(false);
    resetClock(null);
    reset({
      end_at: null,
      start_at: null,
      text: '',
    });
  };

  return (
    <Dialog onClose={handleCloseModal} open={isClockOpen} fullWidth={true} maxWidth={'sm'}>
      <div className="flex flex-col w-full p-6">
        <div className="pb-6 mb-6 text-lg font-medium leading-6 text-gray-900 border-b">
          Edit Clock
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
          <div className="grid grid-cols-1 pt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <Controller
                name="start_at"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    label="Start Time"
                    ampm={false}
                    renderInput={(params) => <TextField fullWidth={true} {...params} />}
                  />
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <Controller
                name="end_at"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    label="End Time"
                    ampm={false}
                    renderInput={(params) => <TextField fullWidth={true} {...params} />}
                  />
                )}
              />
            </div>
            <div className="sm:col-span-6">
              <textarea
                {...register('text')}
                className="w-full h-40 p-4 border-gray-200 rounded"
                placeholder="reason..."
              ></textarea>
            </div>
          </div>
          <div className="flex items-center justify-between w-full pt-6 h-[72px] mx-6 mt-6 border-t md:mx-0">
            <button
              type="button"
              onClick={handleCloseModal}
              className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Close
            </button>
            <button
              type="submit"
              className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default EditClockModal;
