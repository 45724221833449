import { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Dialog from '@mui/material/Dialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import shiftsService from '../../../services/shifts-service';
import { useAvailableStaffs } from '../../../hooks/use-available-staffs';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import { SelectedShift } from '../shifts-request';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';
import { ChangeShiftResponse } from '../../../interfaces/change-shift-interface';

interface ChangeStatusModalProps {
  show: boolean;
  closeModal: (data?: ChangeShiftResponse) => void;
  selectedShift: SelectedShift;
}

interface StatusList {
  id: SelectedStatus;
  name: string;
  color: string;
  step: Step | null;
}

type SelectedStatus = 'pending' | 'approved' | 'waiting_for_staff_approval' | 'declined';

type Step = 'SELECT_STATUS' | 'REASON' | 'SELECT_STAFFS';

const sl: StatusList[] = [
  // { id: 'pending', name: 'Pending', color: 'gray', step: null },
  { id: 'declined', name: 'Declined', color: 'red', step: 'REASON' },
  {
    id: 'waiting_for_staff_approval',
    name: 'Waiting for Staff Approval',
    color: 'yellow',
    step: 'SELECT_STAFFS',
  },
  { id: 'approved', name: 'Approved', color: 'green', step: 'SELECT_STAFFS' },
];

interface IForm {
  description: string;
}

const schema = yup
  .object({
    description: yup
      .string()
      .required('Please provide a Message')
      .max(250, 'Description is too long'),
  })
  .required();

const ChangeStatusModal = ({ show, closeModal, selectedShift }: ChangeStatusModalProps) => {
  const queryClient = useQueryClient();
  const { data: availableStaffs = [] } = useAvailableStaffs(selectedShift?.wtId);
  const [selectedStatus, setSelectedStatus] = useState<StatusList | null>(null);
  const [step, setStep] = useState<Step>('SELECT_STATUS');
  const [selectedSingleStaff, setSelectedSingleStaff] = useState<any>('');
  const [error, setError] = useState<string | null>(null);
  const [selectedMultipleStaffs, setSelectedMultipleStaffs] = useState<any>([]);
  const status = selectedShift?.status.split('_').join(' ') ?? 'Pending';
  const statusList = sl.filter((s) => s.id !== selectedShift?.status);
  const filteredAvailableStaffs = availableStaffs.filter((s) => {
    const find = selectedShift?.staffs.find((e: any) => e.id === s.id);
    if (find) {
      return false;
    }
    return true;
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IForm>({
    resolver: yupResolver<any>(schema),
  });

  const {
    mutate,
    isLoading,
    reset: shiftSwapReset,
    error: shiftSwapError,
  } = useMutation(shiftsService.changeShiftRequest, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([shiftsService.shiftsRequestQueryKey]);
      handleCLoseModal(data);
    },
  });

  const handleCLoseModal = (data?: ChangeShiftResponse) => {
    closeModal(data);
    handleResetStep();
    shiftSwapReset();
    setError(null);
  };

  const handleChangeStatus = (sl: StatusList) => {
    setSelectedStatus(sl);
    if (!sl.step) {
      mutate({ shift_request_id: selectedShift!.shiftId, status: sl.id });
    } else {
      setStep(sl.step);
    }
  };

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (isLoading) {
      return;
    }
    mutate({
      shift_request_id: selectedShift!.shiftId,
      description: data.description,
      status: selectedStatus!.id,
    });
  };

  const handleMultipleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setSelectedMultipleStaffs(typeof value === 'string' ? value.split(',') : value);
    setError(null);
  };

  const handleSingleChange = (event: SelectChangeEvent) => {
    setSelectedSingleStaff(event.target.value);
    setError(null);
  };

  const handleSave = () => {
    if (isLoading) {
      return;
    }
    if (selectedStatus!.id === 'approved') {
      if (!selectedSingleStaff) {
        setError('Please Select a Staff.');
        return;
      }
      mutate({
        shift_request_id: selectedShift!.shiftId,
        status: selectedStatus!.id,
        assigned_staff_id: selectedSingleStaff,
      });
    } else {
      if (!selectedMultipleStaffs?.length) {
        setError('Please Select a Staff.');
        return;
      }
      mutate({
        shift_request_id: selectedShift!.shiftId,
        status: selectedStatus!.id,
        staff_ids: selectedMultipleStaffs,
      });
    }
  };

  const handleResetStep = () => {
    setStep('SELECT_STATUS');
    setSelectedStatus(null);
    reset({ description: '' });
    setSelectedSingleStaff('');
    setSelectedMultipleStaffs([]);
  };

  if (!selectedShift) {
    return null;
  }

  return (
    <Dialog onClose={() => handleCLoseModal()} open={show} fullWidth={true} maxWidth={'sm'}>
      <div className="flex flex-col w-full p-6">
        <div className="flex items-center justify-between pb-6 mb-6 text-lg font-medium leading-6 text-gray-900 border-b">
          <div className="flex flex-col font-normal">
            <span>Shift Swap Requested by {selectedShift?.name}</span>
            <span className="mt-2 text-sm text-gray-500">
              <span className="capitalize">Status: {status}</span>
              {selectedStatus ? (
                <span>
                  {' to '}
                  {selectedStatus?.name}
                </span>
              ) : null}
            </span>
          </div>
          <button
            type="button"
            onClick={() => handleCLoseModal()}
            className="transition border border-transparent rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        {selectedShift.staffsStatus ? (
          <div className="flex flex-col items-center w-full h-64 overflow-y-auto divide-y-2">
            {selectedShift.staffs.map((staff: any) => {
              const ss = staff.pivot.status.split('_').join(' ') ?? 'Pending';
              return (
                <div key={staff.id} className="flex items-center justify-between w-full h-14">
                  <span className="font-medium">
                    {capitalizeFirstLetter(`${staff.first_name} ${staff.last_name}`)}
                  </span>
                  <span className="font-medium capitalize">{ss}</span>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="grid w-full grid-cols-12 gap-6">
            {step === 'SELECT_STATUS'
              ? statusList.map((sl) => {
                  return (
                    <div
                      key={sl.id}
                      className={`flex items-center justify-center w-full transition aspect-square uppercase text-center text-xs lg:text-base font-medium lg:font-bold rounded cursor-pointer ${
                        sl.color === 'red' ? 'bg-red-100 hover:bg-red-200 text-red-800' : ''
                      } ${
                        sl.color === 'gray' ? 'bg-gray-100 hover:bg-gray-200 text-gray-800' : ''
                      } ${
                        sl.color === 'yellow'
                          ? 'bg-yellow-100 hover:bg-yellow-200 text-yellow-800'
                          : ''
                      } ${
                        sl.color === 'green' ? 'bg-green-100 hover:bg-green-200 text-green-800' : ''
                      } ${statusList.length === 3 ? 'col-span-4' : 'col-span-6'}
            `}
                      onClick={() => handleChangeStatus(sl)}
                    >
                      {sl.name}
                    </div>
                  );
                })
              : null}
            {step === 'REASON' ? (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col justify-between w-full h-full col-span-12"
              >
                <div className="grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="description"
                        rows={4}
                        {...register('description')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.description?.message}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full pt-4 h-[72px] mt-4 border-t">
                  <button
                    type="button"
                    onClick={handleResetStep}
                    className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </form>
            ) : null}
            {step === 'SELECT_STAFFS' ? (
              <div className="flex flex-col justify-between w-full h-full col-span-12">
                {selectedStatus?.id === 'approved' ? (
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      {/* <InputLabel id="multi-staff">Staff</InputLabel> */}
                      <Select value={selectedSingleStaff} onChange={handleSingleChange}>
                        {selectedShift?.staffs.length ? (
                          <ListSubheader className="!text-gray-300">
                            Selected Staff by {selectedShift?.name}
                          </ListSubheader>
                        ) : null}
                        {selectedShift?.staffs.length
                          ? selectedShift?.staffs.map((s: any) => {
                              return (
                                <MenuItem key={s.id} value={s.id}>
                                  {s.first_name} {s.last_name}
                                </MenuItem>
                              );
                            })
                          : null}
                        <ListSubheader className="!text-gray-300">Available Staff</ListSubheader>
                        {availableStaffs.map((s) => {
                          return (
                            <MenuItem key={s.id} value={s.id}>
                              {s.first_name} {s.last_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      {/* <InputLabel id="single-staff">Staffs</InputLabel> */}
                      <Select
                        value={selectedMultipleStaffs}
                        multiple={true}
                        onChange={handleMultipleChange}
                      >
                        {selectedShift?.staffs.length ? (
                          <ListSubheader className="!text-gray-300">
                            Selected Staff by {selectedShift?.name}
                          </ListSubheader>
                        ) : null}
                        {selectedShift?.staffs.length
                          ? selectedShift?.staffs.map((s: any) => {
                              return (
                                <MenuItem key={s.id} value={s.id}>
                                  {s.first_name} {s.last_name}
                                </MenuItem>
                              );
                            })
                          : null}
                        <ListSubheader className="!text-gray-300">Available Staff</ListSubheader>
                        {filteredAvailableStaffs.map((s) => {
                          return (
                            <MenuItem key={s.id} value={s.id}>
                              {s.first_name} {s.last_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                )}
                <div className="flex items-center justify-between w-full pt-4 h-[72px] mt-4 border-t">
                  <button
                    type="button"
                    onClick={handleResetStep}
                    className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Back
                  </button>
                  {(shiftSwapError as any)?.response?.status === 406 ? (
                    <p className="px-4 font-medium text-center text-red-500 font-sm">
                      {(shiftSwapError as any)?.response?.data?.message}
                    </p>
                  ) : null}
                  {error && !shiftSwapError ? (
                    <p className="px-4 font-medium text-center text-red-500 font-sm">{error}</p>
                  ) : null}
                  <button
                    type="button"
                    onClick={handleSave}
                    disabled={isLoading}
                    className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ChangeStatusModal;
