import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import reportsService from '../services/reports-service';
import { ClockInReportsData } from '../interfaces/clockin-reports-interface';

export function useStaffClockInReports(
  id: number | string | undefined,
  from: number | null | undefined,
  to: number | null | undefined,
  configOptions: UseQueryOptions<ClockInReportsData> = {},
) {
  return useQuery({
    enabled: Boolean(id) && Boolean(from) && Boolean(to),
    queryKey: [reportsService.clockInReportsQueryKey, id, from, to],
    queryFn: () => reportsService.getClockInReports(+id!, from, to),
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 3,
    ...configOptions,
  });
}
