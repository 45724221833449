import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import devicesService from '../../services/devices-service';
import { Device } from '../../interfaces/central-devices/device';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';

export function useGetCentralDevice(
  id: string | undefined,
  configOptions: UseQueryOptions<any, any, Device, any> = {},
) {
  const activeWorkspace = useActiveWorkspaceSlice((state) => state.workspace);

  return useQuery({
    enabled: Boolean(id),
    queryKey: [devicesService.centralDeviceQueryKey, id, activeWorkspace?.id],
    queryFn: () => devicesService.getCentralDevice(id!),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    ...configOptions,
  });
}
