import { useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import Toggle from '../../components/ui/toggle';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { RegisterRequest } from '../../interfaces/register-interface';
import authService from '../../services/auth-service';
import FormError from '../../components/form-error';
import Spinner from '../../components/spinner';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import UnSplashImage from '../../components/libs/unsplash-image';
import { useOtpSlice } from '../../store/otp-slice';
import { customToastError } from '../../utils/custom-toast-error';

interface RegisterForm extends RegisterRequest {
  terms: boolean;
}
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup
  .object({
    first_name: yup
      .string()
      .required('Please enter your First name')
      .min(2, ({ min }) => `must be at least ${min} characters`),
    last_name: yup
      .string()
      .required('Please enter your Last name')
      .min(2, ({ min }) => `must be at least ${min} characters`),
    // website_address: yup.string().url('Must be valid URL'),
    phone: yup.string(),
    // .matches(phoneRegExp, 'Please enter a valid Phone Number')
    // company_name: yup.string().required('This field is required'),
    email: yup
      .string()
      .email('Please enter a valid Email')
      .required(' Please enter your Email address'),
    terms: yup.bool().oneOf([true], 'Field must be checked'),
    password: yup
      .string()
      .required('Please enter a Password')
      .min(6, ({ min }) => `must be at least ${min} characters`),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password'), undefined], 'Passwords must match'),
  })
  .required();

const Register = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Register`);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const updateEmail = useOtpSlice((state) => state.updateEmail);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RegisterForm>({
    defaultValues: { terms: true },
    resolver: yupResolver<any>(schema),
  });

  const {
    mutate,
    isLoading,
    error: registerError,
  } = useMutation(authService.register, {
    onSuccess: (data, { email }) => {
      toast.success(`Registration has been successful.`);
      sendCodeMutate({ email });
    },
  });

  const { mutate: sendCodeMutate, isLoading: sendCodeIsLoading } = useMutation(
    authService.sendVerifyCode,
    {
      onSuccess: (data, { email }) => {
        toast.success(`Please verify your email first`);
        updateEmail(email);
        navigate(`/check-otp?email=${email}`);
      },
    },
  );

  const onSubmit: SubmitHandler<RegisterForm> = (data) => {
    const { terms, ...rest } = data;
    if (!terms) {
      customToastError('Error', `Please accept the terms`);
    } else {
      mutate(rest);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="fixed top-0 bottom-0 right-0 left-0 z-[1]">
          <UnSplashImage />
        </div>
        <div className="z-50 w-full max-w-sm my-10 bg-white rounded-md shadow-lg lg:max-w-xl p-9">
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-3xl font-bold ">Let&apos;s get started!</h1>
            <p className="mt-4 text-sm font-medium text-center text-indigo-600">
              Create your own account in seconds!
            </p>
          </div>
          <div className="mt-2">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="grid grid-cols-1 m-6 md:m-0 md:mt-6 gap-y-1 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700 capitalize"
                    >
                      First name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="first-name"
                        {...register('first_name')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="pt-1 text-sm text-red-500 capitalize h-7 first-letter:capitalize">
                      {errors.first_name?.message}
                    </p>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700 capitalize"
                    >
                      Last name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="last-name"
                        {...register('last_name')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="pt-1 text-sm text-red-500 capitalize h-7 first-letter:capitalize">
                      {errors.last_name?.message}
                    </p>
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 capitalize"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        type="email"
                        {...register('email')}
                        autoComplete="new-email"
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="pt-1 text-sm text-red-500 capitalize h-7 first-letter:capitalize">
                      {errors.email?.message}
                    </p>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700 capitalize"
                    >
                      Password
                    </label>
                    <div className="relative mt-1">
                      <input
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        {...register('password')}
                        autoComplete="new-password"
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      <button
                        type="button"
                        className="absolute top-[6px] right-4"
                        onClick={handleShowPassword}
                      >
                        {showPassword ? (
                          <EyeIcon className="w-6 h-6 text-gray-600 transition hover:text-gray-800" />
                        ) : (
                          <EyeSlashIcon className="w-6 h-6 text-gray-600 transition hover:text-gray-800" />
                        )}
                      </button>
                    </div>
                    <p className="pt-1 text-sm text-red-500 capitalize h-7 first-letter:capitalize">
                      {errors.password?.message}
                    </p>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="confirm-password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Confirm Password
                    </label>
                    <div className="relative mt-1">
                      <input
                        id="confirm-password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        {...register('password_confirmation')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      <button
                        type="button"
                        className="absolute top-[6px] right-4"
                        onClick={handleShowConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <EyeIcon className="w-6 h-6 text-gray-600 transition hover:text-gray-800" />
                        ) : (
                          <EyeSlashIcon className="w-6 h-6 text-gray-600 transition hover:text-gray-800" />
                        )}
                      </button>
                    </div>
                    <p className="pt-1 text-sm text-red-500 capitalize h-7 first-letter:capitalize">
                      {errors.password_confirmation?.message}
                    </p>
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number (Optional)
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center">
                        <label htmlFor="country" className="capitalize sr-only">
                          Country
                        </label>
                        <select
                          id="country"
                          name="country"
                          autoComplete="country"
                          defaultValue="UK"
                          className="h-full py-0 pl-3 text-gray-500 bg-transparent border-transparent rounded-md focus:ring-indigo-500 focus:border-indigo-500 pr-7 sm:text-sm"
                        >
                          <option>UK</option>
                          {/* <option>ES</option> */}
                        </select>
                      </div>
                      <input
                        type="tel"
                        id="phone-number"
                        {...register('phone')}
                        className="block w-full py-2 pl-16 pr-3 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="+447975777666"
                      />
                    </div>
                    <p className="pt-1 text-sm text-red-500 capitalize h-7 first-letter:capitalize">
                      {errors.phone?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-start">
                <Controller
                  name="terms"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Toggle {...field} />}
                />
                <span className={`ml-4 ${errors.terms?.message ? 'text-red-500' : ''}`}>
                  By selecting this, you agree to the{' '}
                  <Link to="/privacy" className="font-medium underline">
                    Privacy Policy
                  </Link>{' '}
                  and{' '}
                  <Link to="/terms" className="font-medium underline">
                    Terms of Use
                  </Link>
                  .
                </span>
              </div>
              <div className="mx-6 md:mx-0">
                <button
                  disabled={isLoading || sendCodeIsLoading}
                  type="submit"
                  className="flex justify-center w-full px-4 py-4 mt-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {!isLoading && !sendCodeIsLoading ? 'Sign Up' : <Spinner size="small" />}
                </button>
              </div>

              {(registerError as any)?.response?.data?.errors ? (
                Object.entries((registerError as any).response.data.errors).map(([key, val], i) => {
                  return <FormError key={i} error={(val as Array<string>)[0]} takeSpace={true} />;
                })
              ) : (
                <FormError error={registerError} takeSpace={true} />
              )}
            </form>
            <div className="flex flex-col items-center mx-6 mt-2 space-y-2 md:mx-0">
              <div className="flex items-center w-full">
                <span className="font-medium text-gray-500">
                  Already have an account?
                  <Link to="/login" className="ml-2 text-base font-medium text-gray-600 underline">
                    Login
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;
