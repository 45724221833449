import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Popover from '@mui/material/Popover';
import { BellIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../../utils/tailwind-class-names';
import TimeLogoUrl from '../../../../assets/manifest/favicon-96x96.png';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthSlice } from '../../../store/auth-slice';
import { useActiveWorkspaceSlice } from '../../../store/active-workspace-slice';
import profileService from '../../../services/profile-service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FirebaseMessageType, useFirebaseSlice } from '../../../store/firebase-slice';
import { useNotifications } from '../../../hooks/use-notifications';
import notificationService from '../../../services/notification-service';
import { Notification } from '../../../interfaces/notification-interface';
import { greetings } from '../../../utils/greetings';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';
import IconHand from '~icons/emojione/hand-with-fingers-splayed';
import { addHours, isBefore } from 'date-fns';
import { dateUtc } from '../../../utils/format-date';
import { customToastError } from '../../../utils/custom-toast-error';
import { Drawer } from '@mui/material';
import WorkspacesBar from './workspaces-bar';
import SideBar from '../../workspace/components/side-bar';

const Header = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const logout = useAuthSlice((state) => state.reset);
  const activeWorkspace = useActiveWorkspaceSlice((state) => state.workspace);
  const firebaseMessage = useFirebaseSlice((state) => state.message);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [avatarError, setAvatarError] = useState(false);
  const attachment = useAuthSlice((state) => state.attachment);
  const firstName = useAuthSlice((state) => state.firstName);
  const lastName = useAuthSlice((state) => state.lastName);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const { data: notifs = [] } = useNotifications(activeWorkspace?.sub_domain);

  useEffect(() => {
    const type = firebaseMessage?.type as FirebaseMessageType | undefined;
    if (type === 'leaveRequestCreated' || type === 'leaveRequestEdited') {
      queryClient.invalidateQueries([notificationService.notificationsQueryKey]);
    }
  }, [firebaseMessage]);

  useEffect(() => {
    setShowBanner(true);
  }, [activeWorkspace]);

  const handleOpenNotif = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!notifs.length) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNotif = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { mutate, isLoading } = useMutation(profileService.logout, {
    onSuccess: () => {
      logout();
    },
    onError: () => {
      customToastError('Error', `Sorry There is a server problem`);
    },
  });

  const { mutate: seenNotification, isLoading: seenNotificationIsLoading } = useMutation(
    notificationService.seenNotification,
    {
      onMutate: async (notifId) => {
        await queryClient.cancelQueries([notificationService.notificationsQueryKey]);
        const previousNotifs = queryClient.getQueryData([
          notificationService.notificationsQueryKey,
        ]);

        queryClient.setQueryData([notificationService.notificationsQueryKey], (old: any) => {
          if (old) {
            return { ...old, data: notifs.filter((n) => n.id !== notifId) };
          }
          return undefined;
        });
        return { previousNotifs };
      },
      onError: (err, notifId, context: any) => {
        queryClient.setQueryData(
          [notificationService.notificationsQueryKey],
          context.previousNotifs,
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries([notificationService.notificationsQueryKey]);
      },
    },
  );

  const handleSeenNotif = (notif: Notification['data'][0]) => {
    if (notif.seen === 0) {
      if (seenNotificationIsLoading) {
        return true;
      }
      seenNotification(notif.id);
    }
    if (notif.type === 'shiftRequestCreated' || notif.type === 'leaveRequestEdited') {
      navigate(`/${activeWorkspace!.sub_domain}/employee-work-space/shifts-swap`);
    }
    if (notif.type === 'leaveRequestEdited' || notif.type === 'leaveRequestCreated') {
      navigate(`/${activeWorkspace!.sub_domain}/employee-work-space/leaves-of-absences`);
    }
    handleCloseNotif();
  };

  const handleLogout = () => {
    if (isLoading) {
      return;
    }
    mutate();
  };

  const handleCloseBanner = () => {
    setShowBanner(false);
  };

  const handleEditWorkspace = () => {
    navigate(`/${activeWorkspace!.sub_domain}/settings/company`);
  };

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsDrawerOpen((state) => !state);
  };

  return (
    <>
      <header className="flex items-center flex-shrink-0 h-16 bg-white border-b border-gray-200 print:hidden">
        {/* Logo area */}
        <div className="border-b border-brand-primary md:flex-shrink-0">
          <button
            // to="/"
            // onClick={() => clearActiveWorkspace()}
            className="flex items-center justify-center w-20 h-16 bg-brand-primary focus:outline-none"
          >
            <img className="w-auto h-12 rounded" src={TimeLogoUrl} alt="Time Panel" />
          </button>
        </div>
        {/* Desktop nav area */}
        <div className="flex items-center justify-between flex-1">
          <div className="items-center hidden sm:flex">
            <div className="relative flex flex-col items-start ml-4 text-gray-400 focus-within:text-gray-500">
              <div className="flex items-center">
                <span>Good {greetings()}</span>
                <span className="mx-[4px] font-medium">{`${capitalizeFirstLetter(
                  firstName!,
                )} ${capitalizeFirstLetter(lastName!)}!`}</span>
                <IconHand className="h-5 w-5 ml-[4px] -top-1" />
              </div>
              <div className="flex items-center text-sm">
                <span>
                  Welcome to{' '}
                  <span className="font-bold text-indigo-600">
                    {capitalizeFirstLetter(activeWorkspace?.title)}
                  </span>
                </span>
              </div>
            </div>
          </div>
          {showBanner &&
          activeWorkspace?.sub_domain !== activeWorkspace?.title &&
          isBefore(dateUtc(new Date()), addHours(dateUtc(activeWorkspace!.created_at), 2)) ? (
            <div className="items-center hidden px-2 py-2 ml-2 text-sm text-white bg-blue-100 border border-blue-800 rounded sm:flex">
              <span onClick={handleEditWorkspace} className="text-blue-800 cursor-pointer">
                <span className="font-semibold">{activeWorkspace?.sub_domain}</span>&nbsp; is the
                unique name we&apos;ve generated for your business. Edit it if you want.
              </span>
              <XMarkIcon
                onClick={handleCloseBanner}
                className="w-6 h-6 ml-2 text-blue-800 cursor-pointer"
              />
            </div>
          ) : null}
          <div className="flex items-center flex-shrink-0 pr-4 ml-auto space-x-10">
            <div className="flex items-center space-x-8">
              <button
                type="button"
                onClick={handleOpenNotif}
                className="relative p-1 -mx-1 text-gray-400 bg-white rounded-full hover:text-gray-500"
              >
                {notifs.length ? (
                  <span className="h-[12px] w-[12px] bg-red-500 rounded-full block absolute top-0 right-[4px]"></span>
                ) : null}
                <BellIcon className="w-6 h-6" aria-hidden="true" />
              </button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseNotif}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className="flex flex-col p-4">
                  <div className="flex flex-col items-start justify-center bg-sky-200 px-2 mb-4 text-sky-700 text-sm w-[300px] h-12 rounded border border-sky-400">
                    <span className="font-medium">{notifs.length} Unread Notification</span>
                  </div>
                  <div className="relative grid gap-2 bg-white w-[300px] overflow-y-auto divide-y divide-gray-100">
                    {notifs.map((item) => (
                      <button
                        onClick={() => handleSeenNotif(item)}
                        key={item.id}
                        className={`flex flex-col py-2 px-4 mr-2 transition ${
                          item.seen === 0 ? 'bg-gray-200' : ''
                        } ${item.seen === 1 ? 'hover:bg-gray-100' : ''}`}
                      >
                        <p className="text-base font-medium text-left text-gray-900">
                          {item.title}
                        </p>
                        <p className="mt-1 text-sm text-left text-gray-500 line-clamp-2">
                          {item.body}
                        </p>
                      </button>
                    ))}
                  </div>
                </div>
              </Popover>
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2">
                  {attachment && !avatarError ? (
                    <img
                      className="w-8 h-8 rounded-full"
                      src={attachment}
                      alt="user"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        // currentTarget.src = 'image_path_here';
                        setAvatarError(true);
                      }}
                    />
                  ) : (
                    <span className="block w-8 h-8 overflow-hidden bg-gray-100 rounded-full">
                      <svg
                        className="w-full h-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  )}
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-30 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/profile"
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700',
                            )}
                          >
                            {' '}
                            Your Profile{' '}
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={handleLogout}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700 cursor-pointer',
                            )}
                          >
                            {' '}
                            Sign Out{' '}
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <button type="button" className="flex lg:hidden" onClick={toggleDrawer}>
                <Bars3Icon className="w-6 h-6 border-none" />
              </button>
            </div>
          </div>
        </div>
      </header>
      <Drawer anchor={'left'} open={isDrawerOpen} onClose={toggleDrawer}>
        <div className="flex overflow-hidden">
          <WorkspacesBar toggleDrawer={toggleDrawer} />
          <SideBar toggleDrawer={toggleDrawer} />
        </div>
      </Drawer>
    </>
  );
};

export default Header;
