import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { compress, decompress } from 'lz-string';
import { Login } from '../interfaces/login-interface';
import { Profile } from '../interfaces/profile-interface';

type State = {
  id: number | null;
  attachment: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  verified: number | null;
  email: string | null;
  token: string | null;
  exp: string | null;
};

type Actions = {
  updateUser: (data: Login) => void;
  updateProfile: (data: Profile) => void;
  reset: () => void;
};

const initialState: State = {
  id: null,
  attachment: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  verified: null,
  token: null,
  exp: null,
};

export const useAuthSlice = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      updateUser: (data: Login) => {
        const user = {
          id: data.user.id,
          attachment: data.user.attachment,
          firstName: data.user.first_name,
          lastName: data.user.last_name,
          email: data.user.email,
          phone: data.user.phone,
          verified: data.user.verified,
          token: data.access_token,
          exp: data.expire_date,
        };
        set(user);
      },
      updateProfile: (data: Profile) => {
        const user = {
          id: data.id,
          attachment: data.attachment,
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          phone: data.phone,
          verified: data.verified,
        };
        set(user);
      },
      reset: () => {
        set({ ...initialState });
      },
    }),
    {
      name: `${import.meta.env.VITE_PREFIX_CACHE_KEY}_USER`,
      storage: createJSONStorage(() => localStorage),
      // serialize: (data) => compress(JSON.stringify(data)),
      // deserialize: (data) => {
      //   const decompressData = decompress(data) as string;
      //   return JSON.parse(decompressData);
      // },
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !['isAuthenticated'].includes(key)),
        ),
    },
  ),
);
