import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTitle } from 'react-use';
import PrevPage from '../../components/ui/prev-page';
import profileService, { IChangePassword } from '../../services/profile-service';
import { useMutation } from '@tanstack/react-query';
import FormError from '../../components/form-error';
import { useAuthSlice } from '../../store/auth-slice';
import toast from 'react-hot-toast';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

const schema = yup
  .object({
    password: yup
      .string()
      .required('this field is required')
      .min(6, (m) => `Must be atleast ${m.min} characters`),
    new_password: yup
      .string()
      .required('this field is required')
      .min(6, (m) => `Must be atleast ${m.min} characters`),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('new_password'), undefined], 'Passwords must match'),
  })
  .required('this field is required');

const ChangePassword = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Change Password`);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const logout = useAuthSlice((state) => state.reset);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IChangePassword>({
    resolver: yupResolver<any>(schema),
  });

  const { mutate, isLoading, error } = useMutation(profileService.changePassword, {
    onSuccess: (data) => {
      toast.success(`Password changed successfully.`);
      logout();
    },
  });

  const onSubmit: SubmitHandler<IChangePassword> = async (data) => {
    if (isLoading) {
      return;
    }
    mutate(data);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="p-9">
        <div className="w-full max-w-md mx-auto">
          <PrevPage to="/profile" />
          <div className="w-full bg-white rounded-md shadow-lg p-9">
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-3xl font-bold ">Change Password</h1>
              <p className="mt-10 text-sm text-center text-gray-600">
                To change the password, enter your current password in the field below. Then, enter
                your new password, retype it for verification, and press &quot;Save&quot;
              </p>
            </div>
            <div className="mt-8">
              <div className="mt-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="relative mt-1">
                      <input
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        {...register('password')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      <button
                        type="button"
                        className="absolute top-[6px] right-4"
                        onClick={handleShowPassword}
                      >
                        {showPassword ? (
                          <EyeIcon className="w-6 h-6 text-gray-600 transition hover:text-gray-800" />
                        ) : (
                          <EyeSlashIcon className="w-6 h-6 text-gray-600 transition hover:text-gray-800" />
                        )}
                      </button>
                    </div>
                    <p className="h-8 pt-2 text-sm text-red-500 capitalize">
                      {errors.password?.message}
                    </p>
                  </div>
                  <div className="mb-1">
                    <label
                      htmlFor="new-password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      New Password
                    </label>
                    <div className="relative mt-1">
                      <input
                        id="new-password"
                        type={showNewPassword ? 'text' : 'password'}
                        {...register('new_password')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      <button
                        type="button"
                        className="absolute top-[6px] right-4"
                        onClick={handleShowNewPassword}
                      >
                        {showNewPassword ? (
                          <EyeIcon className="w-6 h-6 text-gray-600 transition hover:text-gray-800" />
                        ) : (
                          <EyeSlashIcon className="w-6 h-6 text-gray-600 transition hover:text-gray-800" />
                        )}
                      </button>
                    </div>
                    <p className="h-8 pt-2 text-sm text-red-500 capitalize">
                      {errors.new_password?.message}
                    </p>
                  </div>
                  <div className="mb-1">
                    <label
                      htmlFor="confirm-password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Confirm New Password
                    </label>
                    <div className="relative mt-1">
                      <input
                        id="confirm-password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        {...register('password_confirmation')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      <button
                        type="button"
                        className="absolute top-[6px] right-4"
                        onClick={handleShowConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <EyeIcon className="w-6 h-6 text-gray-600 transition hover:text-gray-800" />
                        ) : (
                          <EyeSlashIcon className="w-6 h-6 text-gray-600 transition hover:text-gray-800" />
                        )}
                      </button>
                    </div>
                    <p className="h-8 pt-2 text-sm text-red-500 capitalize">
                      {errors.password_confirmation?.message}
                    </p>
                  </div>
                  <div className="">
                    <button
                      type="submit"
                      className="flex justify-center w-full px-4 py-4 mt-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Save
                    </button>
                  </div>
                  <div className="mt-6">
                    <FormError error={error} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
