export interface AccessLevelStaffs {
  depId: number;
  depName: string;
  active: boolean;
  isOpen: boolean;
  jobs: {
    jobId: number;
    jobName: string;
    active: boolean;
    staffs: {
      staffId: number;
      staffName: string;
      active: boolean;
    }[];
  }[];
}
[];

export const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const;

export const daySorter = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const sortDates = (dates: { start: Date; end: Date; row: number; breaks: any[] }[]) => {
  return dates.sort((a, b) => {
    return new Date(a.start).getTime() - new Date(b.start).getTime();
  });
};

export const generateFormDate = (data: any) => {
  const formData = new FormData();
  for (const key in data) {
    if (key === 'image') {
      if (data[key] && data[key]?.length) {
        formData.append('image', data[key]?.[0]);
      } else if (data.staff_id && !data[key]?.length && !data.attachment) {
        formData.append('image', 'delete');
      }
    } else if (key === 'password') {
      if (data[key]) {
        formData.append('password', data[key]);
      }
    } else if (key === 'work_days') {
      if (data[key]) {
        formData.append(key, JSON.stringify(data[key]));
      }
    } else {
      if (Array.isArray(data[key])) {
        for (let i = 0; i < data[key].length; i++) {
          formData.append(`${key}[]`, data[key][i]);
        }
      } else {
        formData.append(key, data[key]);
      }
    }
  }
  return formData as any;
};

export const calculateLength = (accessLevelStaffs: AccessLevelStaffs[]) => {
  let staffs = 0;
  accessLevelStaffs.forEach((d) => {
    d.jobs.forEach((j) => {
      j.staffs.forEach((s) => {
        if (s.active) {
          staffs = staffs + 1;
        }
      });
    });
  });
  return staffs;
};
