import PrevPage from '../../components/ui/prev-page';

const Privacy = () => {
  return (
    <main className="px-4 py-24 mx-auto max-w-7xl">
      <PrevPage to="./" />
      <h1 className="my-6 text-4xl font-semibold text-gray-700">Privacy Policy</h1>
      <p className="text-sm text-gray-500">
        This page informs you of our policies regarding the collection, use, and disclosure of
        personal information when you use Selected&apos;s products and services. By using our
        products and services, you agree to the collection and use of information in accordance with
        this policy. Selected is the data controller and can be contacted at:
      </p>
      <p>data@selected.org</p>
      <p>01273 725577</p>
      <p>Unit 4, Gordon Mews, Portslade, BN41 1HU</p>
      <h2 className="mb-2 text-lg font-semibold text-gray-700 mt-9">GDPR</h2>
      <p className="text-sm text-gray-500">
        For users in the European Union, we adhere to the Regulation (EU) 2016/679 of the European
        Parliament and of the Council of 27 April 2016, known as the General Data Protection
        Regulation (the &quot;GDPR&quot;). For users in the United Kingdom, we adhere to the GDPR as
        enshrined in the Data Protection Act 2018.
      </p>
      <h2 className="mb-2 text-lg font-semibold text-gray-700 mt-9">Consent </h2>
      <p className="text-sm text-gray-500">
        By using our Site, users agree that they consent to 1. The conditions set out in this
        Privacy Policy. When the legal basis for us processing your personal data is that you have
        provided your consent to that processing, you may withdraw your consent at any time.
      </p>
      <h2 className="mb-2 text-lg font-semibold text-gray-700 mt-9">Personal Data</h2>
      <p className="text-sm text-gray-500">
        We may ask you to provide us with certain personally identifiable information that can be
        used to contact or identify you. Personally identifiable information may include, but is not
        limited to, your name, phone number, and email address. We use this information to provide
        and improve our services and will not share it with anyone except as described in this
        Privacy Policy.
      </p>
      <h2 className="mb-2 text-lg font-semibold text-gray-700 mt-9">
        Who We Share Personal Data With{' '}
      </h2>
      <p className="text-sm text-gray-500">Employees</p>
      <p className="text-sm text-gray-500">
        We may disclose user data to any member of our organization who reasonably needs access to
        user data to achieve the purposes set out in this Privacy Policy. Other Disclosures We will
        not sell or share your data with other third parties, except in the following cases: 1. If
        the law requires it; 2. If it is required for any legal proceeding; 3. To prove or protect
        our legal rights; and 4. To buyers or potential buyers of this company in the event that we
        seek to sell the company. If you follow hyperlinks from our Site to another Site, please
        note that we are not responsible for and have no control over their privacy policies and
        practices.
      </p>
      <h2 className="mb-2 text-lg font-semibold text-gray-700 mt-9">
        How We Protect Your Personal Data{' '}
      </h2>
      <p className="text-sm text-gray-500">
        To protect your security, we use the strongest available browser encryption and store all
        our data on servers in secure facilities. All data is only accessible to our employees. Our
        employees are bound by strict confidentiality agreements and a breach of this agreement
        would result in the employee&apos;s termination. While we take all reasonable precautions to
        ensure that user data is secure and that users are protected, there always remains the risk
        of harm. The Internet as a whole can be insecure at times and therefore, we are unable to
        guarantee the security of user data beyond what is reasonably practical.
      </p>
      <h2 className="mb-2 text-lg font-semibold text-gray-700 mt-9">Your Rights as a User</h2>
      <p className="text-sm text-gray-500">
        Under the GDPR, you have the following rights: 1. Right to be informed; 2. Right of access;
        3. Right to rectification; 4. Right to erasure; 5. Right to restrict processing; 6. Right to
        data portability; and 7. Right to object
      </p>
      <h2 className="mb-2 text-lg font-semibold text-gray-700 mt-9">Children</h2>
      <p className="text-sm text-gray-500">
        The minimum age to use our website is 16 years of age. We do not knowingly collect or use
        personal data from children under 16 years of age. If we learn that we have collected
        personal data from a child under 16 years of age, the personal data will be deleted as soon
        as possible. If a child under 16 years of age has provided us with personal data, their
        parent or guardian may contact our data protection officer.
      </p>
      <h2 className="mb-2 text-lg font-semibold text-gray-700 mt-9">Cookie Policy </h2>
      <p className="text-sm text-gray-500">
        A cookie is a small file, stored on a user&apos;s hard drive by a website. Its purpose is to
        collect data relating to the user&apos;s browsing habits. You have the option to either
        accept or refuse these cookies and know when a cookie is being sent to your computer.If you
        choose to refuse our cookies, you may not be able to use some portions of our services.
      </p>
      <h2 className="mb-2 text-lg font-semibold text-gray-700 mt-9">Log Data</h2>
      <p className="text-sm text-gray-500">
        We collect information that your browser sends whenever you use our services. This Log Data
        may include information such as your computer&apos;s Internet Protocol (&quot;IP&quot;)
        address, browser type, browser version, the pages of our services that you visit, the time
        and date of your visit, the time spent on those pages, and other statistics. In addition, we
        may use third-party services such as Google Analytics that collect, monitor, and analyze
        this type of information to increase our services functionality.
      </p>
      <h2 className="mb-2 text-lg font-semibold text-gray-700 mt-9">
        How to Access, Modify, Delete, or Challenge the Data Collected
      </h2>
      <p className="text-sm text-gray-500">
        If you would like your data to be deleted or modified in any way, or you have questions
        regarding the data collected, please contact our data protection officer here:
      </p>
      <p>Almu Vilchez</p>
      <p>data@selected.org</p>
      <p>01273 705050</p>
      <p>Unit 4, Gordon Mews, Portslade, BN41 1HU</p>
      <h2 className="mb-2 text-lg font-semibold text-gray-700 mt-9">Complaints </h2>
      <p className="text-sm text-gray-500">
        If you have any complaints about how we process your personal data, please contact us
        through the contact methods listed in the Contact Information section so that we can, where
        possible, resolve the issue.
      </p>
      <p className="text-sm text-gray-500">
        Contact Information If you have any questions, concerns or complaints, you can contact our
        data protection officer, Almu Vilchez, at:
      </p>
      <p>data@selected.org </p>
      <p>01273 705050</p>
      <p>Unit 4, Gordon Mews, Portslade, BN41 1HU</p>
    </main>
  );
};

export default Privacy;
