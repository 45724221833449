import { useQuery } from '@tanstack/react-query';
import shiftsService from '../services/shifts-service';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';

export function useAvailableStaffs(work_object_id: number | undefined) {
  const activeWorkspace = useActiveWorkspaceSlice((state) => state.workspace);

  return useQuery(
    [shiftsService.availableStaffsQueryKey, work_object_id, activeWorkspace?.id],
    () => shiftsService.getAvailableStaffs({ work_object_id: work_object_id! }),
    {
      enabled: Boolean(work_object_id),
      refetchOnWindowFocus: false,
    },
  );
}
