import { useQuery } from '@tanstack/react-query';
import calendarService from '../services/calendar-service';

export function useCalendar(from: number | null, to: number | null) {
  return useQuery(
    [calendarService.workingTimesQueryKey, from, to],
    () => calendarService.getCalendarWorkingTimes({ from, to }),
    {
      enabled: Boolean(from) && Boolean(to),
      refetchOnWindowFocus: false,
      staleTime: 1000 * 5,
    },
  );
}
