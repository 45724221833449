import { useRef, useState } from 'react';
import { useTitle } from 'react-use';
import Spinner from '../../components/spinner';
import { useLogsReport } from '../../hooks/use-logs-report';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';
import { formatDate, utcDate } from '../../utils/format-date';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { EyeIcon } from '@heroicons/react/24/solid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { endOfDay, isAfter, startOfDay } from 'date-fns';

const LogsReport = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Logs`);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const startParam = searchParams.get('start');
  const endParam = searchParams.get('end');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(
    startParam ? new Date(+startParam) : null,
  );
  const [endDate, setEndDate] = useState<Date | null>(endParam ? new Date(+endParam) : null);
  const [page, setPage] = useState(1);
  const [merchant, setMerchant] = useState(true);
  const [staff, setStaff] = useState(true);
  const start = startDate ? utcDate(startOfDay(startDate)).toISOString() : undefined;
  const end = endDate ? utcDate(endOfDay(endDate)).toISOString() : undefined;
  const causer =
    merchant && staff
      ? undefined
      : merchant && !staff
      ? 'App\\Models\\Merchant'
      : 'App\\Models\\Staff';
  const { data: logs, isLoading: logsIsLoading } = useLogsReport(
    page,
    undefined,
    start,
    end,
    causer,
  );

  const handleNextPage = () => {
    if (logs && page < logs.last_page) {
      setPage((oldPage) => oldPage + 1);
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handlePrevPage = () => {
    if (logs && page > 1) {
      setPage((oldPage) => oldPage - 1);
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <>
      <div className="w-full mx-auto space-y-8 pb-14" ref={containerRef}>
        <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9">
          <div className="flex flex-col w-full pb-6 mb-6 font-medium border-b">
            <span>Logs</span>
            <p className="mt-2 text-sm font-normal text-gray-400">System log data</p>
            <div className="flex flex-col mt-6 md:flex-row md:items-center md:justify-between">
              <div className="flex items-center space-x-4">
                <button
                  type="button"
                  className={`flex items-center justify-center space-x-1 relative w-32 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded ${
                    merchant ? 'bg-gray-200' : 'hover:bg-gray-50'
                  }`}
                  onClick={() => setMerchant(!merchant)}
                >
                  <span>Merchant</span>
                  <span className="text-xs text-gray-500">{merchant ? '(ON)' : '(OFF)'}</span>
                </button>
                <button
                  type="button"
                  className={`flex items-center justify-center space-x-1 relative w-32 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded ${
                    staff ? 'bg-gray-200' : ''
                  }`}
                  onClick={() => setStaff(!staff)}
                >
                  <span>Staff</span>
                  <span className="text-xs text-gray-500">{staff ? '(ON)' : '(OFF)'}</span>
                </button>
              </div>
              <div className="flex flex-col mt-8 space-y-4 md:space-y-0 md:space-x-4 md:flex-row md:mt-0">
                <DatePicker
                  label="Start date"
                  value={startDate}
                  disableMaskedInput
                  inputFormat="dd MMM y"
                  onChange={(newValue) => {
                    if (newValue) {
                      searchParams.delete('start');
                      searchParams.append('start', new Date(newValue).getTime().toString());
                      setSearchParams(searchParams);
                    }
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} placeholder="Start Date" />}
                />
                <DatePicker
                  label="End date"
                  value={endDate}
                  disableMaskedInput
                  inputFormat="dd MMM y"
                  onChange={(newValue) => {
                    if (newValue && startDate && isAfter(newValue, startDate)) {
                      const endD = endOfDay(newValue);
                      searchParams.delete('end');
                      searchParams.append('end', endD.getTime().toString());
                      setSearchParams(searchParams);
                      setEndDate(endD);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} placeholder="End Date" />}
                />
              </div>
            </div>
          </div>
          {logsIsLoading ? (
            <div className="flex items-end justify-center w-full my-9">
              <span className="mr-4">Please wait</span>
              <Spinner size="small" />
            </div>
          ) : (
            <>
              {' '}
              <div className="flex flex-col w-full">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <div className="min-w-full divide-y divide-gray-300">
                        <div className="grid items-center w-full h-16 grid-cols-6 text-gray-900 bg-gray-50">
                          <div className="flex items-center justify-center col-span-2 text-sm font-semibold md:col-span-1">
                            Date
                          </div>
                          <div className="items-center justify-center hidden col-span-1 text-sm font-semibold md:col-span-1 md:flex">
                            Type
                          </div>
                          <div className="flex items-center justify-center col-span-2 text-sm font-semibold md:col-span-1">
                            Name
                          </div>
                          <div className="items-center justify-center hidden col-span-1 text-sm font-semibold md:col-span-1 md:flex">
                            Subject
                          </div>
                          <div className="items-center justify-center hidden col-span-1 text-sm font-semibold md:col-span-1 md:flex">
                            Event
                          </div>
                          <div className="flex items-center justify-center col-span-2 text-sm font-semibold md:col-span-1">
                            Actions
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col bg-white divide-y divide-gray-200">
                        {logs?.data.map((log) => {
                          const reason = log.subject_type.split('\\').at(-1);
                          const causer = log.causer_type.split('\\').at(-1);
                          return (
                            <div
                              key={log.id}
                              className="grid w-full h-20 grid-cols-6 text-xs md:text-sm"
                            >
                              <div className="flex flex-col items-center justify-center col-span-2 font-medium text-gray-900 md:col-span-1 ">
                                <span className="font-medium text-center text-gray-900 first-letter:capitalize line-clamp-2">
                                  {formatDate(log.created_at)}
                                </span>
                              </div>
                              <div className="flex-col items-center justify-center hidden col-span-1 font-medium text-gray-900 md:col-span-1 md:flex ">
                                <span className="font-medium text-center text-gray-900 first-letter:capitalize line-clamp-2">
                                  {capitalizeFirstLetter(causer)}
                                </span>
                              </div>
                              <div className="flex flex-col items-center justify-center col-span-2 font-medium text-gray-900 md:col-span-1 ">
                                <span className="font-medium text-center text-gray-900 first-letter:capitalize line-clamp-2">
                                  {capitalizeFirstLetter(
                                    `${log.causer.first_name} ${log.causer.last_name}`,
                                  )}
                                </span>
                              </div>
                              <div className="flex-col items-center justify-center hidden col-span-1 font-medium text-gray-900 md:col-span-1 md:flex ">
                                <span className="font-medium text-center text-gray-900 first-letter:capitalize line-clamp-2">
                                  {reason}
                                </span>
                              </div>
                              <div className="flex-col items-center justify-center hidden col-span-1 font-medium text-gray-900 md:col-span-1 md:flex ">
                                <span className="font-medium text-center text-gray-900 first-letter:capitalize line-clamp-2">
                                  {capitalizeFirstLetter(log.event)}
                                </span>
                              </div>
                              <div className="flex flex-col items-center justify-center col-span-2 font-medium text-gray-900 md:col-span-1 ">
                                <button
                                  type="button"
                                  className="mx-4"
                                  onClick={() => navigate(`./${log.id}`)}
                                >
                                  <EyeIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <nav
                className="flex items-center justify-between w-full pt-6 bg-white border-t border-gray-200"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{logs?.from ?? 0}</span> to{' '}
                    <span className="font-medium">{logs?.to ?? 0}</span> of{' '}
                    <span className="font-medium">{logs?.total ?? 0}</span> results
                  </p>
                </div>
                <div className="flex justify-between flex-1 sm:justify-end">
                  <button
                    className="relative py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md w-36 hover:bg-gray-50"
                    onClick={handlePrevPage}
                  >
                    Previous Page
                  </button>
                  <button
                    className="relative py-2 ml-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md w-36 hover:bg-gray-50"
                    onClick={handleNextPage}
                  >
                    Next Page
                  </button>
                </div>
              </nav>{' '}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LogsReport;
