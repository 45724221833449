import { useState } from 'react';
import { StaffClockInReport } from '../../../interfaces/clockin-reports-interface';
import { dateUtc, formatDateToTimeZone } from '../../../utils/format-date';
import { NoSymbolIcon } from '@heroicons/react/24/solid';
import PencilIcon from '~icons/carbon/edit';
import CheckIcon from '~icons/carbon/checkmark';
import TrashIcon from '~icons/carbon/trash-can';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import reportsService from '../../../services/reports-service';
import Confirmation from '../../../components/libs/confirmation';
import clockService from '../../../services/clock-service';
import { useAttendanceClockSlice } from '../../../store/attendance-clock-slice';
import { AxiosError } from 'axios';
import { customToastError } from '../../../utils/custom-toast-error';
import ChangeStatus, { ChangeStatusModelContext } from './change-status-modal';
import { useChangeClockStatus } from '../../../hooks/use-change-clock-status';
import ConfirmationWithReasonModal, {
  ConfirmationWithReasonModalData,
} from './confirmation-with-reason-modal';
import { useRejectClockAttendance } from '../../../hooks/use-reject-clock-attendance';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';
import { isSameDay } from 'date-fns';

interface ClockRowProps {
  clock: StaffClockInReport['clocks'][0];
  isOvertime: boolean;
}
const ClockRow = ({ clock, isOvertime }: ClockRowProps) => {
  const queryClient = useQueryClient();
  const updateClock = useAttendanceClockSlice((state) => state.updateClock);
  const updateIsClockOpen = useAttendanceClockSlice((state) => state.updateIsClockOpen);
  const updateBreak = useAttendanceClockSlice((state) => state.updateBreak);
  const updateIsBreakOpen = useAttendanceClockSlice((state) => state.updateIsBreakOpen);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationWithReason, setShowConfirmationWithReason] = useState(false);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [breakId, setBreakId] = useState<number | null>(null);
  const changeClockStatus = useChangeClockStatus({
    onSettled: () => {
      queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
    },
    onError(error: AxiosError<any>) {
      customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
    },
  });
  const rejectClockAttendance = useRejectClockAttendance({
    onSettled: () => {
      queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
    },
    onError(error: AxiosError<any>) {
      customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
    },
  });
  const isDisabled =
    clock.status === 'rejected' || clock.status === 'edited' || clock.is_locked || isOvertime;

  const { mutate: deleteClock, isLoading: deleteClockIsLoading } = useMutation(
    clockService.deleteClock,
    {
      onSettled: () => {
        queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
      },
      onError(error: AxiosError<any>) {
        customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
      },
    },
  );

  const { mutate: deleteBreak, isLoading: deleteBreakIsLoading } = useMutation(
    clockService.deleteBreak,
    {
      onSettled: () => {
        queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
      },
      onError(error: AxiosError<any>) {
        setBreakId(null);
        customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
      },
    },
  );

  const handleConfirmation = (bool: boolean) => {
    if (bool && !deleteClockIsLoading && !breakId) {
      deleteClock(clock.id);
      return;
    }
    if (bool && breakId && !deleteBreakIsLoading) {
      deleteBreak(breakId);
      return;
    }
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleOpenConfirmationWithReason = () => {
    setShowConfirmationWithReason(true);
  };

  const handleCloseConfirmationWithReason = (data: ConfirmationWithReasonModalData) => {
    setShowConfirmationWithReason(false);
    if (data.confirm) {
      rejectClockAttendance.mutate({
        clock_id: clock.id,
        status_rejected_comment: data.text,
      });
    }
  };

  const handleOpenChangeStatus = () => {
    setShowChangeStatus(true);
  };

  const handleCloseChangeStatus = (ctx: ChangeStatusModelContext) => {
    setShowChangeStatus(false);
    if (ctx === 'ACCEPT') {
      changeClockStatus.mutate({ clock_id: clock.id, status: 'accepted' });
    } else if (ctx === 'PENDING') {
      changeClockStatus.mutate({ clock_id: clock.id, status: 'pending' });
    }
  };

  const handleDeleteBreak = (id: number) => {
    setBreakId(id);
    handleOpenConfirmation();
  };

  const handleChangeClockStatus = () => {
    if (
      changeClockStatus.isLoading ||
      !clock.clock_out ||
      isDisabled ||
      clock.clock_in === clock.clock_out
    ) {
      return;
    }
    handleOpenChangeStatus();
  };

  const handleEditClock = (cl: StaffClockInReport['clocks'][0]) => {
    if (!cl.clock_in || !cl.clock_out) {
      return;
    }
    updateClock(cl);
    updateIsClockOpen(true);
  };

  const handleEditBreak = (br: StaffClockInReport['clocks'][0]['breaks'][0]) => {
    if (!br.start || !br.end) {
      return;
    }
    updateBreak(br);
    updateIsBreakOpen(true);
  };

  const handleRejectClockAttendance = () => {
    handleOpenConfirmationWithReason();
  };

  return (
    <>
      <div className={`grid w-full grid-cols-5`}>
        <div className="flex items-center h-16 col-span-1 pl-2 text-sm font-medium text-left lg:pl-6">
          {formatDateToTimeZone(clock.clock_in, 'HH:mm')}
        </div>
        <div className="flex items-center h-16 col-span-1 pl-2 text-sm font-medium text-left lg:pl-6">
          {clock.clock_out && clock.clock_out !== clock.clock_in ? (
            isSameDay(dateUtc(clock.clock_in), dateUtc(clock.clock_out)) ? (
              formatDateToTimeZone(clock.clock_out, 'HH:mm')
            ) : (
              formatDateToTimeZone(clock.clock_out, 'dd-MM-y HH:mm')
            )
          ) : (
            <div
              className={`w-20 h-6 flex items-center justify-center rounded text-center font-medium text-xs uppercase text-gray-700 bg-blue-300`}
            >
              ONLINE
            </div>
          )}
        </div>
        <div className="flex items-center h-16 col-span-1 pl-2 text-sm font-medium text-left lg:pl-6">
          {clock?.breaks?.length ?? 0}
        </div>
        <div
          onClick={() => handleChangeClockStatus()}
          className={`group flex items-center h-16 col-span-1 pl-2 text-sm font-medium text-left lg:pl-6 space-x-4 ${
            rejectClockAttendance.isLoading ||
            !clock.clock_out ||
            isDisabled ||
            clock.clock_in === clock.clock_out
              ? ''
              : 'cursor-pointer'
          }`}
        >
          <div
            className={`w-20 h-6 flex items-center justify-center rounded text-center font-medium text-xs uppercase text-gray-700 ${
              clock.status === 'rejected' ? 'bg-red-300' : ''
            } ${clock.status === 'edited' ? 'bg-orange-300' : ''} ${
              clock.status === 'accepted' ? 'bg-green-300' : ''
            } ${clock.status === 'pending' ? 'bg-yellow-300' : ''}`}
          >
            {clock.status}
          </div>
          <button
            className="text-gray-400 transition hover:text-gray-800 disabled:text-gray-200"
            type="button"
            disabled={
              rejectClockAttendance.isLoading ||
              !clock.clock_out ||
              isDisabled ||
              clock.clock_in === clock.clock_out
            }
          >
            {clock.status === 'pending' ? (
              <CheckIcon className="w-6 h-6" />
            ) : (
              <PencilIcon className="w-6 h-6" />
            )}
          </button>
        </div>
        <div className="flex items-center h-16 col-span-1 pl-2 space-x-2 text-sm font-medium text-left lg:pl-6">
          <button
            type="button"
            className="text-red-400 transition hover:text-red-600 disabled:text-red-200"
            disabled={
              rejectClockAttendance.isLoading ||
              !clock.clock_out ||
              isDisabled ||
              clock.clock_in === clock.clock_out
            }
            onClick={() => handleRejectClockAttendance()}
          >
            <NoSymbolIcon className="w-6 h-6" />
          </button>
          <button
            type="button"
            className="text-gray-400 transition hover:text-gray-800 disabled:text-gray-200"
            disabled={
              rejectClockAttendance.isLoading ||
              !clock.clock_out ||
              isDisabled ||
              clock.clock_in === clock.clock_out
            }
            onClick={() => handleEditClock(clock)}
          >
            <PencilIcon className="w-6 h-6" />
          </button>
        </div>
        {clock.status === 'rejected' ? (
          <div className="flex items-center h-16 col-span-5 pl-2 space-x-2 text-xs font-medium text-left lg:pl-6">
            <span className="text-red-500">Reject reason:</span>
            <span>{capitalizeFirstLetter(clock?.status_rejected_comment ?? 'No Reason')}</span>
          </div>
        ) : null}
        {clock.status === 'edited' ? (
          <div className="flex items-center h-16 col-span-5 pl-2 space-x-2 text-xs font-medium text-left lg:pl-6">
            <span className="text-yellow-500">Edit reason:</span>
            <span>{capitalizeFirstLetter(clock?.status_edited_comment ?? 'No Reason')}</span>
          </div>
        ) : null}
        {clock.clock_in_late_reason ? (
          <div className="flex items-center h-16 col-span-5 pl-2 space-x-2 text-xs font-medium text-left lg:pl-6">
            <span className="text-red-500">Late clock-in reason:</span>
            <span>{capitalizeFirstLetter(clock?.clock_in_late_reason ?? 'No Reason')}</span>
          </div>
        ) : null}
        {clock.clock_out_soon_reason ? (
          <div className="flex items-center h-16 col-span-5 pl-2 space-x-2 text-xs font-medium text-left lg:pl-6">
            <span className="text-red-500">Early clock-out reason:</span>
            <span>{capitalizeFirstLetter(clock?.clock_out_soon_reason ?? 'No Reason')}</span>
          </div>
        ) : null}
        {clock.clock_out_late_reason ? (
          <div className="flex items-center h-16 col-span-5 pl-2 space-x-2 text-xs font-medium text-left lg:pl-6">
            <span className="text-red-500">Late clock-out reason:</span>
            <span>{capitalizeFirstLetter(clock?.clock_out_late_reason ?? 'No Reason')}</span>
          </div>
        ) : null}
        {clock.breaks?.length ? (
          <div className="flex flex-col w-full col-span-4 col-start-2">
            <div className="grid w-full grid-cols-4 font-medium text-gray-500 border-blue-100 border-[1px] rounded-tr-xl rounded-tl-xl bg-blue-100 h-14">
              <div className="flex items-center col-span-1 pl-2 text-sm font-medium text-left lg:pl-6">
                Break Start
              </div>
              <div className="flex items-center col-span-1 pl-2 text-sm font-medium text-left lg:pl-6">
                Break End
              </div>
              <div className="flex items-center col-span-1 col-start-4 pl-2 text-sm font-medium text-left lg:pl-6">
                Actions
              </div>
            </div>
            {clock.breaks?.map((br) => {
              return (
                <div key={br.id} className={`grid w-full grid-cols-4`}>
                  <div className="flex items-center h-16 col-span-1 pl-2 text-sm font-medium text-left lg:pl-6">
                    {formatDateToTimeZone(br.start, 'HH:mm')}
                  </div>
                  <div className="flex items-center h-16 col-span-1 pl-2 text-sm font-medium text-left lg:pl-6">
                    {br.end ? (
                      formatDateToTimeZone(br.end, 'HH:mm')
                    ) : (
                      <div
                        className={`w-20 h-6 flex items-center justify-center rounded text-center font-medium text-xs uppercase text-gray-700 bg-yellow-500`}
                      >
                        BREAK
                      </div>
                    )}
                  </div>
                  <div className="flex items-center h-16 col-span-1 col-start-4 pl-2 space-x-2 text-sm font-medium text-left lg:pl-6">
                    <button
                      type="button"
                      className="text-red-400 transition hover:text-red-600 disabled:text-red-200"
                      disabled={
                        deleteBreakIsLoading ||
                        br.is_locked ||
                        clock.is_locked ||
                        !br.end ||
                        isOvertime
                      }
                      onClick={() => handleDeleteBreak(br.id)}
                    >
                      <TrashIcon className="w-6 h-6" />
                    </button>
                    <button
                      type="button"
                      className="text-gray-400 transition hover:text-gray-800 disabled:text-gray-200"
                      disabled={
                        deleteBreakIsLoading ||
                        br.is_locked ||
                        clock.is_locked ||
                        !br.end ||
                        isOvertime
                      }
                      onClick={() => handleEditBreak(br)}
                    >
                      <PencilIcon className="w-6 h-6" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
      />
      <ConfirmationWithReasonModal
        show={showConfirmationWithReason}
        title="Are you sure about rejecting?"
        closeModal={handleCloseConfirmationWithReason}
      />
      <ChangeStatus
        title="Change Status"
        show={showChangeStatus}
        closeModal={handleCloseChangeStatus}
      />
    </>
  );
};

export default ClockRow;
