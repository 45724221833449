import { create } from 'zustand';
import { Workspace } from '../interfaces/workspace-interface';

type State = {
  workspace: Workspace | null;
};

type Actions = {
  updateActiveWorkspace: (workspace: Workspace) => void;
  reset: () => void;
};

const initialState: State = {
  workspace: null,
};

export const useActiveWorkspaceSlice = create<State & Actions>()((set) => ({
  ...initialState,
  updateActiveWorkspace(workspace: Workspace) {
    set({ workspace });
  },
  reset: () => {
    set({ ...initialState });
  },
}));
