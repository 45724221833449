import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useCalendarSlice } from '../../../store/calendar-slice';
import { formatDate, utcDate } from '../../../utils/format-date';
import {
  endOfDay,
  getHours,
  getMinutes,
  isAfter,
  setHours,
  setMinutes,
  startOfDay,
  isValid,
  format,
  isSameDay,
} from 'date-fns';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';
import { useCreateLeaveRequest } from '../../../hooks/use-create-leave-request';
import { useLeaves } from '../../../hooks/use-leaves';
import { AxiosError } from 'axios';
import { useEmployees } from '../../../hooks/use-employees';
import { FormControl, MenuItem, Select, useMediaQuery } from '@mui/material';
import { customToastError } from '../../../utils/custom-toast-error';
import { useActiveWorkspaceSlice } from '../../../store/active-workspace-slice';

interface IForm {
  start_at: string | Date | null;
  end_at: string | Date | null;
  staff_id: any;
  leave_id: any;
  reason: string;
  all_day: boolean;
}

const schema = yup
  .object({
    start_at: yup
      .string()
      .nullable()
      .transform((value) => value ?? null)
      .test('isValid', 'invalid field', (val, ctx) => {
        if (!val) {
          return true;
        }
        if (isValid(new Date(val))) {
          return true;
        }
        return false;
      })
      .test('isReq', 'invalid field', (val, ctx) => {
        const allDay = ctx.parent.all_day;
        if (allDay) {
          return true;
        }
        if (!val) {
          return false;
        }
        return true;
      }),
    end_at: yup
      .string()
      .nullable()
      .transform((value) => value ?? null)
      .test('isReq', 'invalid field', (val: any, ctx) => {
        const allDay = ctx.parent.all_day;
        const start = ctx.parent.start_at;
        if (allDay) {
          return true;
        }
        if (!isValid(new Date(start)) && isValid(new Date(val))) {
          return true;
        }
        if (isValid(new Date(start)) && val && isAfter(new Date(val), new Date(start))) {
          return true;
        }
        return false;
      }),
    leave_id: yup.number().typeError('Invalid Leave').required('This field is required'),
  })
  .required();

const AddTimeOff = () => {
  const matches = useMediaQuery('(min-width:1024px)');
  const selectedDate = useCalendarSlice((state) => state.selectedDate);
  const ws = useActiveWorkspaceSlice((state) => state.workspace);
  const activeResource = useCalendarSlice((state) => state.activeResource);
  const toggleScheduleModal = useCalendarSlice((state) => state.updateShowScheduleModal);
  const updateScheduleModalTab = useCalendarSlice((state) => state.updateScheduleModalTab);
  const updateActiveResource = useCalendarSlice((state) => state.updateActiveResource);
  const leaves = useLeaves();
  const { data: employees = [] } = useEmployees();
  const employee = [...employees]?.find((d) => d.id === activeResource?.id);
  const filteredTypes = (leaves.data ?? [])?.filter((type) => {
    if (employee) {
      const find = employee.leaves.find((l: any) => l.id === type.id);
      return !!find;
    }
    return true;
  });
  const createLeaveRequest = useCreateLeaveRequest({
    onSuccess: (data) => {
      updateScheduleModalTab('Time off Requests');
      updateActiveResource({
        ...activeResource!,
        leaveRequests: [...(activeResource?.leaveRequests ?? []), data],
      });
    },
    onError: (error: AxiosError) => {
      customToastError(
        'Error',
        (error?.response?.data as any)?.message ?? 'Sorry there was a problem',
      );
    },
  });
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      staff_id: activeResource?.id ?? '',
      start_at: null,
      end_at: null,
      all_day: true,
      leave_id: '',
      reason: '',
    },
    resolver: yupResolver<any>(schema),
  });
  const options: any[] = (filteredTypes ?? []).map((j) => {
    return {
      value: j.id,
      label: capitalizeFirstLetter(j.title),
    };
  });
  const allDay = watch('all_day');

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (createLeaveRequest.isLoading) {
      return;
    }
    const dayString = format(selectedDate!, 'EEEE').toLowerCase();
    const isWeekend = ws?.weekends?.[dayString];
    let isHolidayAlreadyExist = false;
    ws?.holidays.forEach((h: { date: number; description: string }) => {
      if (isSameDay(h.date, selectedDate!)) {
        isHolidayAlreadyExist = true;
      }
    });
    if (isWeekend || (isHolidayAlreadyExist && !data.all_day)) {
      customToastError('Error', 'Sorry, You cant add Time off.');
      return;
    }
    let start_at: number;
    let end_at: number;
    if (data.all_day) {
      start_at = utcDate(startOfDay(selectedDate!)).getTime();
      end_at = utcDate(endOfDay(selectedDate!)).getTime();
    } else {
      const sH = getHours(new Date(data.start_at!));
      const sM = getMinutes(new Date(data.start_at!));
      const eH = getHours(new Date(data.end_at!));
      const eM = getMinutes(new Date(data.end_at!));
      const start = setHours(setMinutes(selectedDate!, sM), sH);
      const end = setHours(setMinutes(selectedDate!, eM), eH);
      start_at = utcDate(start).getTime();
      end_at = utcDate(end).getTime();
    }
    createLeaveRequest.mutate({
      ...data,
      staff_id: +data.staff_id,
      leave_id: +data.leave_id,
      all_day: data.all_day ? 0 : 1,
      start_at,
      end_at,
    });
  };

  const handleCloseModal = () => {
    toggleScheduleModal(false);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full h-full flex flex-col items-center justify-between min-h-[400px]"
    >
      <div className="w-full divide-y divide-gray-200 h-[calc(100%-64px)] overflow-hidden overflow-y-auto pr-4 pb-14">
        <div className="flex items-center justify-between pb-6 text-sm font-medium">
          <span className="text-gray-700">
            You are scheduling{' '}
            <span className="font-semibold capitalize first-letter:capitalize">
              {activeResource?.name}
            </span>{' '}
            on:
          </span>
          <span className="text-blue-700">{formatDate(selectedDate, 'eeee, dd LLL , yyyy')}</span>
        </div>
        <div className="grid grid-cols-1 pt-6 gap-y-2 gap-x-4 sm:grid-cols-6">
          <div className="px-2 mb-4 sm:col-span-6">
            <div className="flex items-center w-full">
              <input
                type="checkbox"
                id="all-day"
                {...register('all_day')}
                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <label htmlFor="all-day" className="block ml-4 text-sm font-medium text-gray-700">
                Daily
              </label>
            </div>
          </div>
          {!allDay ? (
            <>
              <div className="sm:col-span-3">
                <Controller
                  name="start_at"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      label="Start Time"
                      ampm={false}
                      {...field}
                      renderInput={(params) => <TextField fullWidth={true} {...params} />}
                    />
                  )}
                />
                <p className="h-8 pt-2 text-red-500 first-letter:capitalize">
                  {errors?.start_at?.message}
                </p>
              </div>
              <div className="sm:col-span-3">
                <Controller
                  name="end_at"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      label="End Time"
                      ampm={false}
                      {...field}
                      renderInput={(params) => <TextField fullWidth={true} {...params} />}
                    />
                  )}
                />
                <p className="h-8 pt-2 text-red-500 first-letter:capitalize">
                  {errors.end_at?.message}
                </p>
              </div>
            </>
          ) : null}
          <div className="sm:col-span-6">
            <label htmlFor="staffs" className="block text-sm font-medium text-gray-700">
              Time off type
            </label>
            <div className="mt-2">
              <Controller
                name="leave_id"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <Select autoWidth={matches ? false : true} label="" {...field}>
                      {options.map((j) => {
                        return (
                          <MenuItem key={j.value} value={j.value}>
                            {j.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          </div>
          <div className="mt-4 sm:col-span-6">
            <label htmlFor="total" className="block text-sm font-medium text-gray-700">
              Reason
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="reason"
                {...register('reason')}
                autoComplete="given-name"
                className="block w-full h-10 px-3 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <p className="h-8 pt-2 text-red-500 first-letter:capitalize">
              {errors.reason?.message}
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full pt-6 mx-6 mt-6 border-t md:mx-0">
        <button
          type="button"
          onClick={handleCloseModal}
          className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Close
        </button>
        <button
          type="submit"
          className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Confirm
        </button>
      </div>
    </form>
  );
};

export default AddTimeOff;
