import { useEffect, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { useQuery } from '@tanstack/react-query';
import { unSplashImage } from '../../services/unsplash-service';

const UnSplashImage = () => {
  const [unSplashSrc, setUnSplashSrc] = useState<string | undefined>(undefined);
  const { data } = useQuery(['unsplash'], unSplashImage, {
    staleTime: Infinity,
    refetchIntervalInBackground: false
  });

  useEffect(() => {
    if (data && !unSplashSrc) {
      const img = new Image();
      img.onload = () => setUnSplashSrc(data?.urls.regular);
      img.src = data!.urls.regular;
    }
  }, [data]);

  return (
    <>
      {!unSplashSrc && data && (
        <Blurhash hash={data!.blur_hash} width={'100%'} height={'100%'} resolutionX={32} resolutionY={32} punch={1} />
      )}
      {unSplashSrc && <img className="object-cover w-full h-full" src={unSplashSrc} alt={data?.alt_description} />}
    </>
  );
};

export default UnSplashImage;
