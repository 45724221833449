import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrintWorkTimeClocksSlice } from '../../store/print-worktime-clocks-slice';
import { formatDate } from '../../utils/format-date';

const PrintWorkTimeDetailsClocks = () => {
  const navigate = useNavigate();
  const data = usePrintWorkTimeClocksSlice((state) => state.data);

  useEffect(() => {
    window.print();
  }, []);

  return (
    <>
      <div className="mx-auto space-y-0 max-w-7xl pb-9 print:p-4 print:font-sans print:max-w-none print:w-full">
        <div className="flex items-center justify-between my-4 print:hidden">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="w-32 py-2 text-sm text-gray-700 border border-gray-200 rounded bg-gray-50"
          >
            Back
          </button>
          <button
            type="button"
            onClick={() => window.print()}
            className="w-32 py-2 text-sm text-gray-700 border border-gray-200 rounded bg-gray-50"
          >
            Print
          </button>
        </div>
        <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9 print:shadow-none print:p-0 print:border-none">
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-between px-4 mb-2 text-[10px] text-gray-500">
              <div className="flex flex-col">
                <span className="font-medium capitalize">Name: {data?.name}</span>
                <span className="text-gray-300">Email: {data?.email}</span>
              </div>
              <div className="flex flex-col">
                <span className="capitalize">Job: {data?.job}</span>
              </div>
            </div>
            <div className="grid w-full h-12 grid-cols-4 text-xs font-medium text-gray-700 bg-gray-100 rounded print:text-[8px] print:grid-cols-4 print:border-b print:border-b-gray-200">
              <div className="flex items-center justify-center h-full col-span-1">Name</div>
              <div className="flex items-center justify-center h-full col-span-1">Job</div>
              <div className="flex items-center justify-center h-full col-span-1">Clock-in</div>
              <div className="flex items-center justify-center h-full col-span-1">Clock-out</div>
            </div>
            <div className="flex flex-col w-full divide-y-[1px] divide-gray-100">
              {data?.clocks.map((report, i) => {
                return (
                  <div
                    key={report.id}
                    className="grid w-full grid-cols-4 text-xs print:text-[8px] print:grid-cols-4 font-medium text-gray-700 rounded h-14 print:h-10"
                  >
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {formatDate(data.date_ms, 'dd-MM-y')}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.job_title}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.start}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.end}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintWorkTimeDetailsClocks;
