import { create } from 'zustand';

export type FirebaseMessageType =
  | 'clockIn'
  | 'clockOut'
  | 'takeBreak'
  | 'finishBreak'
  | 'leaveRequestCreated'
  | 'leaveRequestEdited';

interface Message {
  [key: string]: string;
}

type State = {
  token: string | null;
  notification: Message | undefined | null;
  message: Message | undefined | null;
  allNotification: Message[];
};

type Actions = {
  updateToken: (token: string) => void;
  updateNotification: (notification: Message | undefined) => void;
  updateMessage: (message: Message) => void;
};

const initialState: State = {
  token: null,
  notification: null,
  message: null,
  allNotification: [],
};

export const useFirebaseSlice = create<State & Actions>()((set, get) => ({
  ...initialState,
  updateToken: (token: string) => {
    set({ token });
  },
  updateNotification: (notification: Message | undefined | null) => {
    set({ notification });
  },
  updateMessage: (message: Message | null) => {
    const allNotification = get().allNotification;
    const type = message?.type as FirebaseMessageType | undefined;
    if (type === 'leaveRequestCreated' || type === 'leaveRequestEdited') {
      set({ message, allNotification: [...allNotification, message!] });
    } else {
      set({ message });
    }
  },
}));
