import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { utcDate } from '../../../utils/format-date';
import reportsService from '../../../services/reports-service';
import { DateTimePicker } from '@mui/x-date-pickers';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { customToastError } from '../../../utils/custom-toast-error';
import { useCreateWorkTimeAttendance } from '../../../hooks/use-create-worktime-attendance';
import { useJobs } from '../../../hooks/use-jobs';

interface UnscheduleClockModalProps {
  staffId: number;
  jobId: number;
  show: boolean;
  closeModal: () => void;
}

interface IForm {
  start_at: string | Date | null;
  end_at: string | Date | null;
  over_time: number;
  staff_id: number;
  job_id: number;
}

const schema = yup
  .object({
    job_id: yup.number().typeError('Invalid value').required('Invalid value'),
    start_at: yup
      .date()
      .transform((value) => value ?? undefined)
      .required('Please fill the Start Time'),
    end_at: yup
      .date()
      .transform((value) => value ?? undefined)
      .test('isReq', 'This field is Required', (val, ctx) => {
        const start = ctx.parent.start_at;
        if (start && val && isSameDay(start, val) && isAfter(val, start)) {
          return true;
        }
        return false;
      })
      .required('Please fill the End Time'),
  })
  .required();

const UnscheduleClockModal = ({ staffId, jobId, show, closeModal }: UnscheduleClockModalProps) => {
  const queryClient = useQueryClient();
  const { data: jobs = [] } = useJobs();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      start_at: null,
      end_at: null,
      staff_id: staffId,
      job_id: jobId,
      over_time: 1,
    },
    resolver: yupResolver<any>(schema),
  });
  const createWorkTimeAttendance = useCreateWorkTimeAttendance({
    onSuccess: (data) => {
      // toast.success('Clock-in Added');
      handleCloseModal();
      queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
    },
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (createWorkTimeAttendance.isLoading) {
      return;
    }
    const start_at = utcDate(data.start_at!).getTime();
    const end_at = utcDate(data.end_at!).getTime();
    if (isBefore(end_at, start_at)) {
      customToastError('Error', `Please Select a valid time`);
      return;
    }
    createWorkTimeAttendance.mutate({
      over_time: data.over_time ? true : false,
      staff_id: data.staff_id,
      job_id: data.job_id,
      start_at,
      end_at,
    });
  };

  const handleCloseModal = () => {
    reset({
      start_at: null,
      end_at: null,
      over_time: 1,
      staff_id: staffId,
      job_id: jobId,
    });
    closeModal();
  };

  return (
    <Dialog onClose={handleCloseModal} open={show} fullWidth={true} maxWidth={'sm'}>
      <div className="flex flex-col w-full p-6">
        <div className="pb-6 mb-6 text-lg font-medium leading-6 text-gray-900 border-b">
          Create New Clock-in
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
          <div className="grid grid-cols-1 pt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <Controller
                name="over_time"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="type-select-small">Type</InputLabel>
                    <Select label="Type" {...field}>
                      <MenuItem value={1}>Over Time</MenuItem>
                      <MenuItem value={0}>Schedule</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <div className="sm:col-span-6">
              <Controller
                name="job_id"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="job-select-small">Job</InputLabel>
                    <Select label="Job" {...field}>
                      {jobs.map((j) => {
                        return (
                          <MenuItem key={j.id} value={j.id}>
                            <span className="capitalize">{j.title}</span>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <Controller
                name="start_at"
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    disableMaskedInput
                    inputFormat="dd MMM y HH:mm"
                    disableFuture
                    renderInput={(props) => (
                      <TextField fullWidth={true} {...props} error={!!errors?.start_at?.message} />
                    )}
                    label="Start Time"
                    ampm={false}
                  />
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <Controller
                name="end_at"
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    disableFuture
                    disableMaskedInput
                    inputFormat="dd MMM y HH:mm"
                    renderInput={(props) => (
                      <TextField fullWidth={true} {...props} error={!!errors?.end_at?.message} />
                    )}
                    label="End Time"
                    ampm={false}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex items-center justify-between w-full pt-6 h-[72px] mx-6 mt-6 border-t md:mx-0">
            <button
              type="button"
              onClick={handleCloseModal}
              className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Close
            </button>
            <button
              type="submit"
              className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default UnscheduleClockModal;
