import { useState } from 'react';
import { ChevronDownIcon, NoSymbolIcon } from '@heroicons/react/24/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PencilIcon from '~icons/carbon/edit';
import CheckIcon from '~icons/carbon/checkmark';
import TrashIcon from '~icons/carbon/trash-can';
import { AxiosError } from 'axios';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';
import { useAttendanceClockSlice } from '../../../store/attendance-clock-slice';
import { useChangeScheduleStatus } from '../../../hooks/use-change-schedule-status';
import { useRejectScheduleAttendance } from '../../../hooks/use-reject-schedule-attendance';
import { customToastError } from '../../../utils/custom-toast-error';
import reportsService from '../../../services/reports-service';
import calendarService, { WorktimeDeleteType } from '../../../services/calendar-service';
import ConfirmationWithReasonModal, {
  ConfirmationWithReasonModalData,
} from './confirmation-with-reason-modal';
import ChangeStatusModal, { ChangeStatusModelContext } from './change-status-modal';
import { dateUtc, formatDateToTimeZone } from '../../../utils/format-date';
import { StaffClockInReport } from '../../../interfaces/clockin-reports-interface';
import Confirmation from '../../../components/libs/confirmation';
import ClockRow from './clock-row';
import { Collapse } from '@mui/material';
import { isSameDay } from 'date-fns';

interface ScheduleRowProps {
  schedule: StaffClockInReport;
}

const ScheduleRow = ({ schedule }: ScheduleRowProps) => {
  const queryClient = useQueryClient();
  const updateWorktime = useAttendanceClockSlice((state) => state.updateWorktime);
  const updateIsWorktimeOpen = useAttendanceClockSlice((state) => state.updateIsWorktimeOpen);
  const updateIsCreateWorktimeModalOpen = useAttendanceClockSlice(
    (state) => state.updateIsCreateWorktimeModalOpen,
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationWithReason, setShowConfirmationWithReason] = useState(false);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [openNestedClocks, setOpenNestedClocks] = useState(false);
  const isUserOnline = schedule.clocks.find((clock) => !clock.clock_out);

  const changeScheduleStatus = useChangeScheduleStatus({
    onSuccess: (data) => {
      queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
    },
    onError(error: AxiosError<any>) {
      customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
    },
  });
  const rejectScheduleAttendance = useRejectScheduleAttendance({
    onSuccess: (data) => {
      queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
    },
    onError(error: AxiosError<any>) {
      customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
    },
  });

  const { mutate: deleteWorktime, isLoading: deleteWorktimeIsLoading } = useMutation(
    ({ id, type }: { id: number; type: WorktimeDeleteType }) =>
      calendarService.deleteWorktime(id, type),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
      },
      onError(error: AxiosError<any>) {
        customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
      },
    },
  );

  const handleConfirmation = (bool: boolean) => {
    if (bool && !deleteWorktimeIsLoading) {
      deleteWorktime({ id: schedule.id, type: 'ATTENDANCE' });
      return;
    }
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleOpenConfirmationWithReason = () => {
    setShowConfirmationWithReason(true);
  };

  const handleCloseConfirmationWithReason = (data: ConfirmationWithReasonModalData) => {
    setShowConfirmationWithReason(false);
    if (data.confirm) {
      rejectScheduleAttendance.mutate({
        body: {
          working_object_id: schedule.id,
          status_rejected_comment: data.text,
        },
        source: 'ATTENDANCE',
      });
    }
  };

  const handleOpenChangeStatus = () => {
    setShowChangeStatus(true);
  };

  const handleCloseChangeStatus = (ctx: ChangeStatusModelContext) => {
    setShowChangeStatus(false);
    if (ctx === 'ACCEPT') {
      changeScheduleStatus.mutate({ working_object_id: schedule.id, status: 'accepted' });
    } else if (ctx === 'PENDING') {
      changeScheduleStatus.mutate({ working_object_id: schedule.id, status: 'pending' });
    }
  };

  const handleDeleteWt = () => {
    handleOpenConfirmation();
  };

  const handleChangeScheduleStatus = () => {
    if (
      changeScheduleStatus.isLoading ||
      schedule.status === 'rejected' ||
      schedule.status === 'edited' ||
      schedule.status === 'accepted' ||
      schedule.is_locked ||
      schedule.over_time === 0 ||
      !schedule.end_at ||
      schedule.start_at === schedule.end_at
    ) {
      return;
    }
    handleOpenChangeStatus();
  };

  const handleEditWorktime = (wt: StaffClockInReport) => {
    updateWorktime(wt);
    updateIsWorktimeOpen(true);
  };

  const handleCreateWorktime = (wt: StaffClockInReport) => {
    updateWorktime(wt);
    updateIsCreateWorktimeModalOpen(true);
  };

  const handleShowClockIns = () => {
    if (schedule?.clocks?.length) {
      setOpenNestedClocks((o) => !o);
    }
  };

  const handleRejectScheduleAttendance = () => {
    handleOpenConfirmationWithReason();
  };

  return (
    <>
      <div
        key={schedule.id}
        className={`grid w-full grid-cols-6 text-gray-500 border-t-[1px] border-t-gray-100 first:border-t-0 ${
          openNestedClocks ? 'bg-blue-50 table-row' : ''
        }`}
      >
        <div className="flex items-center h-16 col-span-2 pl-2 text-sm font-medium text-left md:col-span-1 lg:pl-6">
          {schedule?.over_time === 1 ? `Over Time` : `Schedule`}
        </div>
        <div className="flex items-center h-16 col-span-2 pl-2 text-sm font-medium text-left md:col-span-1 lg:pl-6">
          {formatDateToTimeZone(schedule.start_at, 'HH:mm')}
        </div>
        <div className="flex items-center h-16 col-span-2 pl-2 text-sm font-medium text-left md:col-span-1 lg:pl-6">
          {schedule.end_at && schedule.start_at !== schedule.end_at ? (
            isSameDay(dateUtc(schedule.start_at), dateUtc(schedule.end_at)) ? (
              formatDateToTimeZone(schedule.end_at, 'HH:mm')
            ) : (
              formatDateToTimeZone(schedule.end_at, 'dd-MM-y HH:mm')
            )
          ) : (
            <div
              className={`w-20 h-6 flex items-center justify-center rounded text-center font-medium text-xs uppercase text-gray-700 bg-blue-300`}
            >
              ONLINE
            </div>
          )}
        </div>
        <div className="items-center hidden h-16 col-span-1 pl-2 text-sm font-medium text-left md:flex lg:pl-6">
          {schedule.clocks.length ?? 0}
        </div>
        <div
          onClick={() => handleChangeScheduleStatus()}
          className={`group flex items-center h-16 col-span-6 md:col-span-1 pl-2 text-sm font-medium text-left lg:pl-6 space-x-4 ${
            schedule.status === 'rejected' ||
            schedule.status === 'edited' ||
            schedule.status === 'accepted' ||
            schedule.is_locked ||
            schedule.over_time === 0 ||
            !schedule.end_at ||
            schedule.start_at === schedule.end_at
              ? ''
              : 'cursor-pointer'
          }`}
        >
          <div
            className={`w-20 h-6 flex items-center justify-center rounded text-center font-medium text-xs uppercase text-gray-700 ${
              schedule.status === 'rejected' ? 'bg-red-300' : ''
            } ${schedule.status === 'edited' ? 'bg-orange-300' : ''} ${
              schedule.status === 'accepted' ? 'bg-green-300' : ''
            } ${schedule.status === 'pending' ? 'bg-yellow-300' : ''}`}
          >
            {schedule.status}
          </div>
          <button
            className="text-gray-400 transition hover:text-gray-800 disabled:text-gray-200"
            type="button"
            disabled={
              schedule.status === 'rejected' ||
              schedule.status === 'edited' ||
              schedule.status === 'accepted' ||
              schedule.is_locked ||
              schedule.over_time === 0 ||
              !schedule.end_at ||
              schedule.start_at === schedule.end_at
            }
          >
            {schedule.status === 'pending' ? (
              <CheckIcon className="w-6 h-6" />
            ) : (
              <PencilIcon className="w-6 h-6" />
            )}
          </button>
        </div>
        <div className="flex flex-wrap items-center h-16 col-span-6 gap-2 pl-2 text-sm font-medium text-left md:col-span-1 lg:pl-6">
          <button
            type="button"
            className="text-red-400 transition hover:text-red-600 disabled:text-red-200"
            title="Reject"
            disabled={
              rejectScheduleAttendance.isLoading ||
              !!isUserOnline ||
              schedule.status === 'rejected' ||
              schedule.status === 'edited' ||
              !schedule.end_at ||
              schedule.is_locked ||
              schedule.start_at === schedule.end_at
            }
            onClick={() => handleRejectScheduleAttendance()}
          >
            <NoSymbolIcon className="w-6 h-6" />
          </button>
          <button
            type="button"
            title="Delete"
            className="text-red-400 transition hover:text-red-600 disabled:text-red-200"
            disabled={
              deleteWorktimeIsLoading ||
              !!isUserOnline ||
              schedule.clocks.length >= 1 ||
              schedule.status === 'rejected' ||
              schedule.status === 'edited' ||
              !schedule.end_at ||
              schedule.is_locked ||
              schedule.start_at === schedule.end_at
            }
            onClick={() => handleDeleteWt()}
          >
            <TrashIcon className="w-6 h-6" />
          </button>
          <button
            type="button"
            className="text-gray-400 transition hover:text-gray-800 disabled:text-gray-200"
            title="Edit"
            disabled={
              schedule.status === 'rejected' ||
              schedule.status === 'edited' ||
              !schedule.end_at ||
              schedule.start_at === schedule.end_at ||
              !!isUserOnline ||
              schedule.is_locked
            }
            onClick={() => handleEditWorktime(schedule)}
          >
            <PencilIcon className="w-6 h-6" />
          </button>
          <button
            type="button"
            title="Create Clock"
            disabled={
              schedule?.over_time === 1 ||
              schedule.status === 'rejected' ||
              schedule.status === 'edited' ||
              !schedule.end_at ||
              schedule.start_at === schedule.end_at ||
              !!isUserOnline ||
              schedule.is_locked
            }
            className="text-gray-400 transition hover:text-gray-800 disabled:text-gray-200"
            onClick={() => handleCreateWorktime(schedule)}
          >
            <PlusIcon className="w-6 h-6" />
          </button>
          <button
            onClick={handleShowClockIns}
            title="Expand"
            className="text-gray-400 transition hover:text-gray-800 disabled:text-gray-200"
            disabled={!schedule.clocks.length}
          >
            <ChevronDownIcon
              className={`w-6 h-6 transition duration-200 ease-in-out ${
                openNestedClocks ? 'rotate-180 transform' : ''
              }`}
            />
          </button>
        </div>
        {schedule.status === 'rejected' ? (
          <div className="flex items-center h-16 col-span-6 pl-2 space-x-2 text-xs font-medium text-left lg:pl-6">
            <span className="text-red-500">Reject reason:</span>
            <span>{capitalizeFirstLetter(schedule?.status_rejected_comment ?? 'No Reason')}</span>
          </div>
        ) : null}
        {schedule.status === 'edited' ? (
          <div className="flex items-center h-16 col-span-6 pl-2 space-x-2 text-xs font-medium text-left lg:pl-6">
            <span className="text-yellow-500">Edit reason:</span>
            <span>{capitalizeFirstLetter(schedule?.status_edited_comment ?? 'No Reason')}</span>
          </div>
        ) : null}
        <Collapse
          in={openNestedClocks && schedule?.clocks.length >= 1}
          className="flex flex-col w-full col-span-6 col-start-2"
        >
          <div className="grid w-full grid-cols-5 font-medium text-gray-500 border-blue-100 border-[1px] rounded-tr-xl rounded-tl-xl bg-blue-100 h-14">
            <div className="flex items-center col-span-1 pl-2 text-sm font-medium text-left lg:pl-6">
              Clock-in
            </div>
            <div className="flex items-center col-span-1 pl-2 text-sm font-medium text-left lg:pl-6">
              Clock-out
            </div>
            <div className="flex items-center col-span-1 pl-2 text-sm font-medium text-left lg:pl-6">
              Breaks
            </div>
            <div className="flex items-center col-span-1 pl-2 text-sm font-medium text-left lg:pl-6">
              Status
            </div>
            <div className="flex items-center col-span-1 pl-2 text-sm font-medium text-left lg:pl-6">
              Actions
            </div>
          </div>
          {schedule?.clocks?.map((clock) => {
            return (
              <ClockRow key={clock.id} isOvertime={Boolean(schedule.over_time)} clock={clock} />
            );
          })}
        </Collapse>
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
      />
      <ConfirmationWithReasonModal
        show={showConfirmationWithReason}
        title="Are you sure about rejecting?"
        closeModal={handleCloseConfirmationWithReason}
      />
      <ChangeStatusModal
        title="Change Status"
        show={showChangeStatus}
        closeModal={handleCloseChangeStatus}
      />
    </>
  );
};

export default ScheduleRow;
