import { Department } from '../interfaces/department-interface';
import { Job, JobRequest, UpdateJobRequest } from '../interfaces/job-interface';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import http from './axios-instance';

const jobsQueryKey = 'Jobs';
const getJobs = async (): Promise<Job[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/job/get`;
  const res = await http.get(url, { headers });
  return res.data;
};

const getJobsWithDepartmentId = async (id: Department['id']): Promise<Job[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { department_id: id };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/job/get`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const createJob = async (data: JobRequest): Promise<Job> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/job/create`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const updateJob = async (params: UpdateJobRequest): Promise<Job> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/job/edit`;
  const res = await http.patch(url, null, { headers, params });
  return res.data;
};

const deleteJob = async (id: any): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { job_id: id };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/job/delete`;
  const res = await http.delete(url, { headers, params });
  return res.data;
};

const jobsService = {
  jobsQueryKey,
  getJobs,
  getJobsWithDepartmentId,
  createJob,
  updateJob,
  deleteJob,
};

export default jobsService;
