import { useCallback, useEffect, useState } from 'react';
import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline';
import { useEmployees } from '../../hooks/use-employees';
import Spinner from '../../components/spinner';
import { Link } from 'react-router-dom';
import { formatDateToTimeZone } from '../../utils/format-date';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import { FirebaseMessageType, useFirebaseSlice } from '../../store/firebase-slice';
import { useQueryClient } from '@tanstack/react-query';
import EmptyState from '../../components/empty-state';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { matchSorter } from 'match-sorter';
import { useClockOutStaff } from '../../hooks/use-clock-out-staff';
import AlarmOffIcon from '~icons/mdi/alarm-off';
import { customToastError } from '../../utils/custom-toast-error';
import { AxiosError } from 'axios';
import staffsService from '../../services/staffs-service';
import { toast } from 'react-hot-toast';
import { Tooltip } from '@mui/material';
import Confirmation from '../../components/libs/confirmation';
import { isAfter, isBefore, isEqual, setHours, setMinutes } from 'date-fns';

const AttendanceList = () => {
  const subDomain = useActiveWorkspaceSlice((state) => state.workspace?.sub_domain);
  const queryClient = useQueryClient();
  const firebaseMessage = useFirebaseSlice((state) => state.message);
  const [term, setTerm] = useState('');
  const [filter, setFilter] = useState<'ALL' | 'LIVE'>('ALL');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [empId, setEmpId] = useState<number | null>(null);
  const { data: employees = [], isLoading: employeesIsLoading } = useEmployees();
  const clockOutStaff = useClockOutStaff({
    onSettled: () => {
      queryClient.invalidateQueries([staffsService.staffsQueryKey]);
      queryClient.invalidateQueries([staffsService.archivedStaffsQueryKey]);
    },
    onSuccess: (d) => {
      toast.success(d.message);
      setEmpId(null);
    },
    onError(error: AxiosError<any>) {
      customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
      setEmpId(null);
    },
  });
  const activeEmployeesClocks = employees.filter((e) => e.employment_status !== 'archived');
  const employeesData = activeEmployeesClocks.filter((emp) => {
    if (filter === 'ALL') {
      return true;
    }
    if (filter === 'LIVE') {
      return emp.live_clocks.length && !emp.live_clocks.at(-1)?.clock_out;
    }
  });
  const filteredEmployees = matchSorter(employeesData, term, {
    keys: ['first_name', 'last_name', 'email', 'mobile'],
  });
  // const sortedEmployees = filteredEmployees.sort((a, b) => {
  //   return a.first_name.localeCompare(b.first_name);
  // });

  useEffect(() => {
    const type = firebaseMessage?.type as FirebaseMessageType | undefined;
    // console.log({ type });
    if (
      type === 'clockIn' ||
      type === 'clockOut' ||
      type === 'takeBreak' ||
      type === 'finishBreak'
    ) {
      queryClient.invalidateQueries([staffsService.staffsQueryKey]);
      queryClient.invalidateQueries([staffsService.archivedStaffsQueryKey]);
    }
  }, [firebaseMessage]);

  const handleAllEmployees = () => {
    setFilter('ALL');
  };

  const handleLiveEmployees = () => {
    setFilter('LIVE');
  };

  const handleTermChanges = useCallback((term: string) => {
    setTerm(term);
  }, []);

  const handleOpenConfirmation = (id: number) => {
    setEmpId(id);
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && empId) {
      clockOutStaff.mutate(empId);
    }
  };

  if (employeesIsLoading) {
    return (
      <div className="flex items-end justify-center w-full my-9">
        <span className="mr-4">Please wait</span>
        <Spinner size="small" />
      </div>
    );
  }

  if (employeesIsLoading === false && !employees?.length) {
    return (
      <EmptyState
        path="./employees-create"
        buttonName="Add Employee"
        showBtn={false}
        message="No employee is currently online"
      />
    );
  }

  return (
    <>
      <div className="w-full mx-auto space-y-4 md:space-y-8 pb-14">
        <div className="flex items-center justify-between pt-1">
          <div className="flex items-center space-x-4">
            <div className="relative text-gray-400 border border-gray-200 rounded focus-within:text-gray-500">
              <input
                id="mobile-search"
                type="search"
                placeholder="Search ..."
                onChange={(e) => handleTermChanges(e.target.value)}
                className="block w-full pl-10 placeholder-gray-500 border-none rounded-md focus:outline-none focus:ring-brand-primary h-11 "
              />
              <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-3">
                <MagnifyingGlassIcon className="w-5 h-5" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full p-4 bg-white border border-gray-100 rounded-md shadow-lg md:p-9">
          <div className="flex flex-col w-full pt-2 pb-6 mb-6 border-b md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col">
              <span>Attendance List</span>
              <p className="mt-2 text-sm font-normal text-gray-400">
                This list captures the absence and attendance data. Press … to view each and every
                detail.
              </p>
            </div>
            <div className="mt-8 md:mt-0">
              <button
                type="button"
                className={`relative w-16 py-2 text-sm font-medium text-gray-700 border-t border-b border-l border-gray-300 rounded-tl rounded-bl ${
                  filter === 'ALL' ? 'bg-brand-primary text-white' : 'bg-white'
                }`}
                onClick={handleAllEmployees}
              >
                All
              </button>
              <button
                type="button"
                className={`relative w-16 py-2 text-sm font-medium text-gray-700 border-t border-b border-r border-gray-300 rounded-tr rounded-br ${
                  filter === 'LIVE' ? 'bg-brand-primary text-white' : 'bg-white'
                }`}
                onClick={handleLiveEmployees}
              >
                Live
              </button>
            </div>
          </div>
          <div className="grid w-full grid-cols-12 text-sm font-medium rounded bg-gray-50 text-gray-90 h-14">
            <div className="items-center hidden col-span-2 pl-8 pr-2 md:flex">Status</div>
            <div className="flex items-center col-span-6 pl-8 pr-2 md:col-span-4">Name</div>
            <div className="items-center hidden col-span-2 pl-8 pr-2 md:flex">Job</div>
            <div className="items-center hidden col-span-2 pl-8 pr-2 md:flex">Latest Clock-in</div>
            <div className="flex items-center col-span-6 pl-8 pr-2 md:col-span-2">Overview</div>
          </div>
          <div className="flex flex-col w-full divide-y divide-gray-100">
            {filteredEmployees.map((emp) => {
              const isOff =
                !emp.live_clocks.length ||
                (emp.live_clocks.length && emp.live_clocks?.at(-1)?.clock_out);
              const isLive =
                emp.live_clocks.length &&
                !emp.live_clocks?.at(-1)?.clock_out &&
                (!emp.live_clocks.at(-1)?.breaks.length ||
                  emp.live_clocks?.at(-1)?.breaks.at(-1)?.end);
              const isBreak =
                emp.live_clocks.length &&
                !emp.live_clocks?.at(-1)?.clock_out &&
                emp.live_clocks.at(-1)?.breaks.length &&
                !emp.live_clocks?.at(-1)?.breaks.at(-1)?.end;
              return (
                <div
                  className="grid w-full h-auto grid-cols-12 py-2 text-sm font-semibold md:py-0 md:h-16"
                  key={emp.email}
                >
                  <div className="flex items-center w-full col-span-12 pl-8 pr-2 md:col-span-2">
                    <>
                      <span
                        className={`relative inline-flex w-3 h-3 rounded-full ${
                          isOff ? 'bg-red-500' : ''
                        } ${isLive ? 'bg-sky-500' : ''} ${isBreak ? 'bg-yellow-500' : ''}`}
                      ></span>
                      {isOff ? (
                        <span
                          className={`relative inline-flex ml-2 font-semibold tracking-wide text-red-500`}
                        >
                          OFFLINE
                        </span>
                      ) : null}
                      {isLive ? (
                        <span
                          className={`relative inline-flex ml-2 font-semibold tracking-wide text-sky-500`}
                        >
                          ONLINE
                        </span>
                      ) : null}
                      {isBreak ? (
                        <span
                          className={`relative inline-flex ml-2 font-semibold tracking-wide text-yellow-500`}
                        >
                          Break
                        </span>
                      ) : null}{' '}
                    </>
                  </div>
                  <div className="flex flex-col justify-center w-full col-span-6 pl-8 pr-2 md:col-span-4">
                    <span className="font-medium text-gray-900 first-letter:capitalize">
                      {capitalizeFirstLetter(`${emp.first_name} ${emp.last_name}`)}
                    </span>
                    <span className="font-normal text-gray-500 break-all line-clamp-1">
                      {emp.email}
                    </span>
                  </div>
                  <div className="flex-col justify-center hidden w-full col-span-2 pl-8 pr-2 md:flex">
                    <span className="font-medium text-gray-900 capitalize first-letter:capitalize">
                      {emp.job.title}
                    </span>
                    <span className="font-normal text-gray-500 break-all line-clamp-1">
                      {emp.mobile}
                    </span>
                  </div>
                  <div className="flex-col justify-center hidden w-full col-span-2 pl-8 pr-2 md:flex">
                    {emp.live_clocks.length && !emp.live_clocks.at(-1)?.clock_out ? (
                      <>
                        <span className="font-medium text-gray-900 first-letter:capitalize">
                          {formatDateToTimeZone(emp.live_clocks.at(-1)!.clock_in, 'HH:mm')}
                        </span>
                        <span className="font-normal text-gray-500 line-clamp-1">
                          {formatDateToTimeZone(emp.live_clocks.at(-1)!.clock_in, 'dd-MM-y')}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="font-medium text-gray-900 first-letter:capitalize">
                          {emp?.last_clock_in
                            ? formatDateToTimeZone(emp.last_clock_in, 'HH:mm')
                            : '-'}
                        </span>
                        <span className="font-normal text-gray-500 line-clamp-1">
                          {emp?.last_clock_in
                            ? formatDateToTimeZone(emp.last_clock_in, 'dd-MM-y')
                            : '-'}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="flex items-center col-span-6 pl-8 pr-2 space-x-4 md:col-span-2">
                    <Tooltip title="Clock out">
                      <span>
                        <button
                          type="button"
                          className="text-gray-400 transition hover:text-gray-800 disabled:text-gray-200 disabled:hover:text-gray-200"
                          disabled={clockOutStaff.isLoading || isOff}
                          onClick={() => handleOpenConfirmation(emp.id)}
                        >
                          <AlarmOffIcon className="w-8 h-8" />
                        </button>
                      </span>
                    </Tooltip>
                    <Tooltip title="Details">
                      <Link
                        to={`/${subDomain}/employee-work-space/attendance-details/${emp.id}`}
                        className="flex items-center justify-center mx-4"
                      >
                        <EllipsisHorizontalCircleIcon className="w-8 h-8 text-gray-400 transition cursor-pointer hover:text-gray-800" />
                      </Link>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
      />
    </>
  );
};

export default AttendanceList;
