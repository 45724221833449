import { XMarkIcon } from '@heroicons/react/24/outline';
import Dialog from '@mui/material/Dialog';
import { useLocations } from '../../../hooks/use-locations';
import { useState } from 'react';

interface NewLocationModalProps {
  id: number | null;
  show: boolean;
  closeModal: (bool: boolean, newId?: number) => void;
}

const NewLocationModal = ({ id, show, closeModal }: NewLocationModalProps) => {
  const [locationId, setLocationId] = useState<number>(-1);
  const { data: locationsData = [] } = useLocations();

  const handleClose = (bool: boolean) => {
    if (bool && locationId < 0) {
      return;
    } else if (bool) {
      closeModal(bool, locationId);
    } else {
      closeModal(bool);
    }
    setLocationId(-1);
  };

  return (
    <Dialog onClose={() => handleClose(false)} open={show} fullWidth={false} maxWidth={'sm'}>
      <div className="flex flex-col max-w-full min-w-[400px] p-6">
        <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900">
          <div className="flex flex-col">
            <span className="leading-9">Please select a new location</span>
          </div>
          <button
            type="button"
            onClick={() => handleClose(false)}
            className="ml-6 transition border border-transparent rounded shadow-sm self-baseline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="mt-8">
          <select
            id="location"
            value={locationId}
            onChange={(e) => {
              setLocationId(+e.target.value);
            }}
            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option disabled value="-1">
              Location
            </option>
            {locationsData?.map((loc) => {
              if (loc.id !== id) {
                return (
                  <option className="capitalize" key={loc.id} value={loc.id}>
                    {loc.title}
                  </option>
                );
              } else {
                return null;
              }
            })}
          </select>
        </div>
        <div className="flex items-center justify-between w-full mx-6 pt-9 md:mx-0">
          <button
            type="button"
            onClick={() => handleClose(true)}
            className="h-10 ml-auto text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Confirm
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default NewLocationModal;
