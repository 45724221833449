import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import clockService from '../services/clock-service';
import reportsService from '../services/reports-service';
import { customToastError } from '../utils/custom-toast-error';
import {
  RejectClockAttendanceRequest,
  RejectClockAttendanceResponse,
} from '../interfaces/reject-clock-attendance';

export function useRejectClockAttendance(
  configOptions: UseMutationOptions<
    RejectClockAttendanceResponse,
    any,
    RejectClockAttendanceRequest,
    any
  > = {},
) {
  const queryClient = useQueryClient();
  return useMutation(
    (body: RejectClockAttendanceRequest) => clockService.rejectClockAttendance(body),
    {
      onError: (data: AxiosError) => {
        const err = data.response?.data as any;
        customToastError('Error', err?.message ?? 'Sorry there was a problem');
      },
      onSettled: () => {
        queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
      },
      ...configOptions,
    },
  );
}
