import Dialog from '@mui/material/Dialog';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useCustomNotifications } from '../../../hooks/use-custom-notifications';
import { useState } from 'react';
import { formatDateToTimeZone } from '../../../utils/format-date';
import { useEmployees } from '../../../hooks/use-employees';
import { useArchivedEmployees } from '../../../hooks/use-archived-employees';
import { Employee } from '../../../interfaces/employee-interface';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';

interface NotificationsLogModalProps {
  show: boolean;
  closeModal: () => void;
}

const NotificationsLogModal = ({ show, closeModal }: NotificationsLogModalProps) => {
  const [page, setPage] = useState(1);
  const customNotifications = useCustomNotifications(page);
  const employees = useEmployees();
  const archivedEmployees = useArchivedEmployees();

  const handleCloseModal = () => {
    closeModal();
  };

  const handleNextPage = () => {
    if (customNotifications.data && page < customNotifications.data.last_page) {
      setPage((oldPage) => oldPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (customNotifications.data && page > 1) {
      setPage((oldPage) => oldPage - 1);
    }
  };

  return (
    <Dialog onClose={handleCloseModal} open={show} fullWidth={true} maxWidth={'md'}>
      <div className="flex flex-col w-full p-6">
        <div className="flex items-center justify-between pb-6 mb-6 text-lg font-medium leading-6 text-gray-900 border-b">
          <div className="flex flex-col font-normal">
            <span>Notifications Log</span>
          </div>
          <button
            type="button"
            onClick={handleCloseModal}
            className="transition border border-transparent rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="flex flex-col">
          <div className="min-w-full divide-y divide-gray-300">
            <div className="grid items-center w-full h-12 grid-cols-4 text-gray-900 bg-gray-50">
              <div className="flex items-center justify-center text-sm font-semibold">Date</div>
              <div className="flex items-center justify-center text-sm font-semibold">Staff</div>
              <div className="flex items-center justify-center text-sm font-semibold">Message</div>
              <div className="flex items-center justify-center text-sm font-semibold">
                Seen Counts
              </div>
            </div>
          </div>
          <div className="flex flex-col overflow-y-auto bg-white divide-y divide-gray-200 h-96">
            {customNotifications?.data?.data.map((notification) => {
              let staff: Employee | undefined = undefined;
              staff = employees.data?.find((e) => e.id === notification.staff_id);
              staff = staff
                ? staff
                : archivedEmployees.data?.find((e) => e.id === notification.staff_id);
              return (
                <div key={notification.id} className="grid w-full h-16 grid-cols-4 shrink-0">
                  <div className="flex flex-col items-center justify-center text-sm font-medium text-gray-900 ">
                    <div className="font-semibold text-left text-gray-900">
                      {notification.created_at
                        ? formatDateToTimeZone(notification.created_at, 'dd-MM-y HH:mm')
                        : '-'}
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center text-sm font-medium text-gray-900 ">
                    <div className="font-semibold text-left text-gray-900 line-clamp-1">
                      {staff
                        ? capitalizeFirstLetter(`${staff.first_name} ${staff.last_name}`)
                        : '-'}
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center w-full px-2 text-sm font-medium">
                    <span
                      className="font-medium text-center text-gray-900 first-letter:capitalize line-clamp-2"
                      style={{
                        wordBreak: 'break-word',
                      }}
                    >
                      {notification?.body}
                    </span>
                  </div>
                  <div className="flex flex-col items-center justify-center text-sm font-medium text-gray-900 ">
                    <div className="font-semibold text-left text-gray-900">
                      {notification.seen ?? 0}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <nav
          className="flex items-center justify-between w-full pt-6 bg-white border-t border-gray-200"
          aria-label="Pagination"
        >
          <div className="hidden sm:block">
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{customNotifications.data?.from ?? 0}</span> to{' '}
              <span className="font-medium">{customNotifications.data?.to ?? 0}</span> of{' '}
              <span className="font-medium">{customNotifications.data?.total ?? 0}</span> results
            </p>
          </div>
          <div className="flex justify-between flex-1 sm:justify-end">
            <button
              className="relative py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md w-36 hover:bg-gray-50"
              onClick={handlePrevPage}
            >
              Previous Page
            </button>
            <button
              className="relative py-2 ml-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md w-36 hover:bg-gray-50"
              onClick={handleNextPage}
            >
              Next Page
            </button>
          </div>
        </nav>
      </div>
    </Dialog>
  );
};

export default NotificationsLogModal;
