import { useEffect, useRef, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTitle } from 'react-use';
import { JobRequest, Job as IJob } from '../../interfaces/job-interface';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import jobsService from '../../services/jobs-service';
import toast from 'react-hot-toast';
import JobTable from './components/job-table';
import Spinner from '../../components/spinner';
import FormError from '../../components/form-error';
import { useDepartments } from '../../hooks/use-departments';
import { useJobs } from '../../hooks/use-jobs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEmployeeFormSlice } from '../../store/employee-form-slice';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';

const schema = yup
  .object({
    title: yup.string().required().min(2),
    department_id: yup.number().positive('Please select a Department').required(),
  })
  .required();

const formDefaultValues: JobRequest = {
  department_id: -1,
  title: '',
};

const JobTitle = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Job Title`);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const employeePath = searchParams.get('employeePath');
  const { data: departments } = useDepartments();
  const { data: jobs, isLoading: jobsLoading } = useJobs();
  const [updateMode, setUpdateMode] = useState<IJob | null>(null);
  const employeeForm = useEmployeeFormSlice((state) => state.employeeForm);
  const updateEmployeeForm = useEmployeeFormSlice((state) => state.updateEmployeeForm);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<JobRequest>({
    defaultValues: formDefaultValues,
    resolver: yupResolver<any>(schema),
  });

  useEffect(() => {
    if (employeeForm) {
      setValue('department_id', employeeForm.department);
    }
  }, [employeeForm]);

  const {
    mutate: createJob,
    isLoading: createJobIsLoading,
    error: createJobError,
    reset: resetCreateJobMutation,
  } = useMutation(jobsService.createJob, {
    onSuccess: (data) => {
      toast.success(`New Job Created.`);
      queryClient.invalidateQueries([jobsService.jobsQueryKey]);
      queryClient.setQueryData([jobsService.jobsQueryKey], (old: IJob[] | undefined) => {
        return old ? [...old, data] : undefined;
      });
      if (employeePath) {
        updateEmployeeForm({
          ...employeeForm,
          job_id: data.id,
        });
        navigate(employeePath);
      } else {
        containerRef.current?.scrollIntoView(false);
        reset(formDefaultValues);
      }
    },
  });

  const {
    mutate: updateJob,
    isLoading: updateJobIsLoading,
    error: updateJobError,
    reset: resetUpdateJobMutation,
  } = useMutation(jobsService.updateJob, {
    onSuccess: (data) => {
      toast.success(`Job Updated.`);
      handleResetForm();
      queryClient.invalidateQueries([jobsService.jobsQueryKey]);
    },
  });

  const onSubmit: SubmitHandler<JobRequest> = (data) => {
    if (createJobIsLoading || updateJobIsLoading) {
      return;
    }
    if (updateMode) {
      updateJob({ ...data, job_id: updateMode.id });
    } else {
      createJob(data);
    }
  };

  const handleEditJob = (id: IJob['id']) => {
    resetCreateJobMutation();
    resetUpdateJobMutation();
    const j = jobs?.find((d) => d.id === id);
    if (j) {
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      reset({ title: j.title, department_id: j.department_id });
      setUpdateMode(j);
    }
  };

  const handleResetForm = () => {
    setUpdateMode(null);
    reset(formDefaultValues);
  };

  return (
    <div ref={containerRef} className="w-full mx-auto space-y-4 pb-14">
      <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9">
        <div className="flex w-full pb-6 font-medium border-b">Job Title Details</div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-62">
          <div className="space-y-2 divide-y divide-gray-200">
            <div className="py-2">
              <div className="grid grid-cols-1 m-6 md:m-0 md:mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="department" className="block text-sm font-medium text-gray-700">
                    Department
                  </label>
                  <div className="mt-2">
                    <select
                      id="department"
                      {...register('department_id')}
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option disabled value={-1}>
                        Select a department
                      </option>
                      {departments?.map((dep) => (
                        <option key={dep.id} value={dep.id}>
                          {capitalizeFirstLetter(dep.title)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                    {errors.department_id?.message}
                  </p>
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                    Job Title e.g &quot;Manager&quot;
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="title"
                      {...register('title')}
                      autoComplete="given-name"
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                    {errors.title?.message}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between w-full">
            <FormError error={[createJobError, updateJobError]} />
            <div className="flex items-center space-x-4">
              {updateMode && (
                <button
                  type="button"
                  disabled={updateJobIsLoading}
                  className="flex justify-center w-40 py-3 text-sm font-medium text-white bg-red-500 border border-transparent rounded shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleResetForm}
                >
                  Cancel
                </button>
              )}
              <button
                type="submit"
                className="flex justify-center w-40 py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded shadow-sm ml-9 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {!createJobIsLoading && !updateJobIsLoading ? (
                  updateMode ? (
                    'Update'
                  ) : (
                    'Save'
                  )
                ) : (
                  <Spinner size="small" />
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="flex items-center justify-center mt-6">
        {jobsLoading && !jobs && <Spinner size="large" />}
      </div>
      {jobs && jobs?.length > 0 && <JobTable jobs={jobs} handleEditJob={handleEditJob} />}
    </div>
  );
};

export default JobTitle;
