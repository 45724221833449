import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTitle } from 'react-use';
import { ResetPasswordRequest } from '../../interfaces/reset-password-interface';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import authService from '../../services/auth-service';
import FormError from '../../components/form-error';
import UnSplashImage from '../../components/libs/unsplash-image';
import { customToastError } from '../../utils/custom-toast-error';

const schema = yup
  .object({
    otp: yup.string().min(6).max(6).required(),
  })
  .required();

const ResetPassword = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Reset Password`);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Pick<ResetPasswordRequest, 'otp'>>({
    resolver: yupResolver<any>(schema),
  });

  const {
    mutate,
    isLoading,
    error: resetPasswordError,
  } = useMutation(authService.resetPassword, {
    onSuccess: (data) => {
      // toast.success(`Welcome ${data.user.first_name}, Let's go`);
      // TODO: redirect with token
      navigate(`/confirm-password?token=${data.access_token}`);
    },
  });

  const onSubmit: SubmitHandler<Pick<ResetPasswordRequest, 'otp'>> = async (data) => {
    if (isLoading) {
      return;
    }
    if (email) {
      mutate({ ...data, email });
    } else {
      customToastError('Error', `Email doesn't exist`);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="fixed top-0 bottom-0 right-0 left-0 z-[1]">
        <UnSplashImage />
      </div>
      <div className="w-full max-w-sm mx-auto bg-white rounded-md shadow-lg lg:max-w-xl p-9 z-50">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-3xl font-bold ">Code has been sent</h1>
          <p className="mt-10 text-sm text-center text-gray-600">Please enter your code</p>
        </div>
        <div className="mt-8">
          <div className="mt-6">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
                  Code
                </label>
                <div className="mt-1">
                  <input
                    id="otp"
                    type="string"
                    {...register('otp')}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <p className="pt-2 h-8 text-red-500 capitalize">{errors.otp?.message}</p>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex justify-center w-full px-4 py-4 mt-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Confirm
                </button>
              </div>
              <FormError error={resetPasswordError} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
