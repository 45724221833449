import { XMarkIcon } from '@heroicons/react/24/outline';
import Dialog from '@mui/material/Dialog';
import { ReactNode } from 'react';

interface ConfirmationProps {
  show: boolean;
  closeModal: () => void;
  confirm: (bool: boolean) => void;
  title?: string;
  description?: string | ReactNode;
}

const Confirmation = ({
  show,
  closeModal,
  confirm,
  title = 'Are you sure about this?',
  description = '',
}: ConfirmationProps) => {
  const handleClose = (bool: boolean) => {
    closeModal();
    confirm(bool);
  };

  return (
    <Dialog onClose={() => handleClose(false)} open={show} fullWidth={false} maxWidth={'sm'}>
      <div className="flex flex-col max-w-[600px] p-6">
        <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900">
          <div className="flex flex-col">
            <span className="leading-9 capitalize">{title}</span>
            {description && typeof description === 'string' ? (
              <span className="mt-1 text-base text-gray-500 capitalize">{description}</span>
            ) : (
              ''
            )}
            {description && typeof description !== 'string' ? description : ''}
          </div>
          <button
            type="button"
            onClick={() => handleClose(false)}
            className="ml-6 transition border border-transparent rounded shadow-sm self-baseline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="flex items-center justify-between w-auto pt-9 md:mx-0">
          <button
            type="button"
            onClick={() => handleClose(true)}
            className="h-10 ml-auto text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Confirm
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default Confirmation;
