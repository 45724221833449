import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTitle } from 'react-use';
import PrevPage from '../../components/ui/prev-page';
import { ProfileRequest } from '../../interfaces/profile-interface';
import { useAuthSlice } from '../../store/auth-slice';
import { useMutation } from '@tanstack/react-query';
import profileService from '../../services/profile-service';
import toast from 'react-hot-toast';
import FormError from '../../components/form-error';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { FILE_SIZE, SUPPORTED_FORMATS } from '../../constants/image';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import UnSplashImage from '../../components/libs/unsplash-image';
import { customToastError } from '../../utils/custom-toast-error';
import Confirmation from '../../components/libs/confirmation';
import { XMarkIcon } from '@heroicons/react/24/outline';

const schema = yup
  .object({
    first_name: yup.string().min(3).required(),
    last_name: yup.string().min(3).required(),
    email: yup.string().email().required('This field is required'),
    phone: yup.string(),
    image: yup
      .mixed()
      .test('fileFormat', 'Unsupported Format', (files: any) => {
        if (!files?.length) return true;
        let allow = true;
        for (let i = 0; i < files.length; i++) {
          const file = files.item(i) as File;
          if (allow) {
            allow = SUPPORTED_FORMATS.includes(file.type);
          }
        }
        return allow;
      })
      .test('fileSize', 'The file is too large', (files: any) => {
        if (!files?.length) return true;
        return files[0].size <= FILE_SIZE;
      }),
  })
  .required();

const Profile = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Profile`);
  const [isProfileImageDeleted, setIsProfileImageDeleted] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();
  const updateAuthSlice = useAuthSlice((state) => state.updateProfile);
  const subDomain = useActiveWorkspaceSlice((state) => state.workspace?.sub_domain);
  const firstName = useAuthSlice((state) => state.firstName);
  const lastName = useAuthSlice((state) => state.lastName);
  const email = useAuthSlice((state) => state.email);
  const attachment = useAuthSlice((state) => state.attachment);
  const phone = useAuthSlice((state) => state.phone);
  const logout = useAuthSlice((state) => state.reset);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ProfileRequest>({
    defaultValues: {
      first_name: firstName ?? undefined,
      last_name: lastName ?? undefined,
      email: email ?? undefined,
      phone: phone ?? undefined,
    },
    resolver: yupResolver<any>(schema),
  });
  const profileImage = watch('image');

  const {
    mutate: updateProfile,
    isLoading: updateProfileIsLoading,
    error: updateProfileError,
  } = useMutation(profileService.changeProfile, {
    onSuccess: (data: any) => {
      toast.success(`Profile Updated.`);
      if (data.status === 'success' && data.message) {
        logout();
      } else {
        updateAuthSlice(data);
        navigate(subDomain ? `/${subDomain}` : '/');
      }
    },
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
  });

  const {
    mutate: updateProfileImage,
    isLoading: updateProfileImageIsLoading,
    error: updateProfileImageError,
  } = useMutation(profileService.changeImage, {
    onSuccess: (data) => {
      toast.success(`Profile Image Updated.`);
      updateAuthSlice(data);
    },
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
  });

  const onSubmit: SubmitHandler<ProfileRequest> = (data) => {
    if (updateProfileIsLoading || updateProfileImageIsLoading) {
      return;
    }
    const { image, ...rest } = data;
    if (image && image?.length) {
      const formData = new FormData();
      formData.append('image', image?.[0]);
      updateProfileImage(formData);
    } else if (isProfileImageDeleted) {
      const formData = new FormData();
      formData.append('image', 'delete');
      updateProfileImage(formData);
    }
    updateProfile(rest);
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleDeleteProfileImage = (bool: boolean) => {
    if (bool) {
      setIsProfileImageDeleted(true);
    }
  };

  const handleRemoveImage = () => {
    setValue('image', null);
  };

  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="fixed top-0 bottom-0 right-0 left-0 z-[1]">
          <UnSplashImage />
        </div>
        <div className="z-50 w-full max-w-3xl my-10">
          <PrevPage to="/" />
          <div className="w-full max-w-3xl bg-white rounded-md shadow-lg p-9">
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-3xl font-bold ">Update Your Profile</h1>
              <p className="mt-10 text-sm text-center text-gray-600">
                You can edit your profile or change the account setting here.
              </p>
            </div>
            <div className="mt-8">
              <div className="mt-6">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="grid grid-cols-1 m-6 md:m-0 md:mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          First Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            id="first-name"
                            {...register('first_name')}
                            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                        <p className="h-8 pt-2 text-red-500 first-letter:capitalize">
                          {errors.first_name?.message}
                        </p>
                      </div>
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Last Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            id="last-name"
                            {...register('last_name')}
                            autoComplete="family-name"
                            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                        <p className="h-8 pt-2 text-red-500 first-letter:capitalize">
                          {errors.last_name?.message}
                        </p>
                      </div>
                      <div className="sm:col-span-6">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email Address
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            type="email"
                            {...register('email')}
                            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                        <p className="h-8 pt-2 text-red-500 first-letter:capitalize">
                          {errors.email?.message}
                        </p>
                      </div>
                      <div className="sm:col-span-6">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Phone Number (Optional)
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 flex items-center">
                            <label htmlFor="country" className="sr-only">
                              Country
                            </label>
                            <select
                              id="country"
                              name="country"
                              autoComplete="country"
                              defaultValue="UK"
                              className="h-full py-0 pl-3 text-gray-500 bg-transparent border-transparent rounded-md focus:ring-indigo-500 focus:border-indigo-500 pr-7 sm:text-sm"
                            >
                              <option>UK</option>
                            </select>
                          </div>
                          <input
                            type="text"
                            id="phone-number"
                            className="block w-full py-2 pl-24 pr-3 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="+447975777666"
                            {...register('phone')}
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="profile-image"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Upload Profile Photo
                        </label>
                        <div className="flex items-center mt-2 space-x-4">
                          <input
                            type="file"
                            id="profile-image"
                            {...register('image')}
                            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                          {profileImage?.length ? (
                            <button
                              type="button"
                              onClick={handleRemoveImage}
                              className="flex items-center justify-center h-8 bg-red-500 rounded w-9"
                            >
                              <XMarkIcon className="w-5 h-5 text-white" />
                            </button>
                          ) : null}
                        </div>
                        <p className="h-8 pt-2 text-red-500 first-letter:capitalize">
                          {(errors as any)?.image?.message}
                        </p>
                      </div>
                      {attachment && !isProfileImageDeleted && !profileImage?.length ? (
                        <div className="flex items-center space-x-8 sm:col-span-6">
                          <div className="flex items-center justify-between">
                            <img
                              className="object-cover w-16 h-16 rounded shrink-0 grow-0"
                              src={attachment}
                              alt={firstName ?? 'avatar'}
                            />
                          </div>
                          <button
                            type="button"
                            onClick={handleOpenConfirmation}
                            className="w-16 h-16 text-sm font-medium text-white bg-red-600 border border-transparent rounded shadow-sm hover:bg-red-700"
                          >
                            Delete
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="mx-6 md:mx-0">
                    <button
                      type="submit"
                      className="flex justify-center w-full px-4 py-4 mt-2 text-sm font-medium text-white transition bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Save
                    </button>
                  </div>
                  <div className="flex items-center justify-end">
                    <div className="text-sm">
                      <Link
                        to="/change-password"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Change password?
                      </Link>
                    </div>
                  </div>
                  <FormError error={[updateProfileError, updateProfileImageError]} />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleDeleteProfileImage}
      />
    </>
  );
};
export default Profile;
