import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import devicesService from '../../services/devices-service';
import { customToastError } from '../../utils/custom-toast-error';
import toast from 'react-hot-toast';

export function useEmailPinCodeCentralDevice(
  configOptions: UseMutationOptions<any, any, any, any> = {},
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => devicesService.emailPinCodesCentralDevice(data),
    onSuccess: (data) => {
      toast.success(data.message ?? 'Successfully Done');
      queryClient.invalidateQueries([devicesService.centralDevicesQueryKey]);
    },
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
      queryClient.invalidateQueries([devicesService.centralDevicesQueryKey]);
    },
    ...configOptions,
  });
}
