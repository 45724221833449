import { useState } from 'react';
import { Resource, WeekDays } from '../calendar-reports';
import { ChevronDownIcon, ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import EmployeeRow from './employee-row';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import IconDraggable from '~icons/carbon/draggable';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  useChangeEmployeesSortData,
  useEmployeesSortData,
} from '../../../store/departments-sort-slice';

interface DepartmentGroupProps {
  weekDays: WeekDays[];
  resources: Resource[];
  dragHandler: DraggableProvidedDragHandleProps | null | undefined;
}

const DepartmentGroup = ({ weekDays, resources, dragHandler }: DepartmentGroupProps) => {
  const [isGroupOpen, setIsGroupOpen] = useState(true);
  const [sortType, setSortType] = useState<'ASC' | 'DES'>('ASC');
  const employeesSortData = useEmployeesSortData();
  const currentDepEmpSortData = employeesSortData?.[resources[0].depId.toString()] ?? null;
  const changeEmployeesSortData = useChangeEmployeesSortData();
  const orderByRecourses =
    sortType === 'ASC'
      ? resources.sort((a, b) => a.name.localeCompare(b.name))
      : resources.sort((a, b) => b.name.localeCompare(a.name));
  const sortedRecourses = currentDepEmpSortData
    ? orderByRecourses.sort(
        (a, b) => currentDepEmpSortData.indexOf(a.id) - currentDepEmpSortData.indexOf(b.id),
      )
    : orderByRecourses;

  const handleOpenGroup = () => {
    setIsGroupOpen(!isGroupOpen);
  };

  const handleSorting = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const key = resources[0].depId.toString();
    changeEmployeesSortData({ [key]: [] });
    if (sortType === 'ASC') {
      setSortType('DES');
    } else {
      setSortType('ASC');
    }
  };

  return (
    <div className="flex flex-col group">
      <div className="flex items-center w-full h-10 px-4 text-gray-700 bg-blue-100 cursor-pointer">
        <div className="relative flex items-center justify-center px-1 text-center">
          <button type="button" className="cursor-move" {...dragHandler}>
            <IconDraggable className="w-6 h-6 text-gray-500" />
          </button>
        </div>
        <button
          type="button"
          onClick={handleOpenGroup}
          className="flex items-center justify-center ml-4 border-none rounded"
        >
          <ChevronDownIcon
            className={`h-5 w-5 transition ${!isGroupOpen ? 'rotate-180 transform' : ''}`}
          />
          <span className="ml-2">{capitalizeFirstLetter(resources[0].depTitle)}</span>
        </button>
        <button
          type="button"
          onClick={(e) => handleSorting(e)}
          className="flex items-center justify-center ml-auto border-none rounded"
        >
          {sortType === 'ASC' ? <ArrowDownIcon className={`h-5 w-5`} /> : null}
          {sortType === 'DES' ? <ArrowUpIcon className={`h-5 w-5`} /> : null}
          <span className="mt-px ml-1">Sort</span>
        </button>
      </div>
      {isGroupOpen ? (
        <Droppable
          droppableId={`department-droppable-${resources[0].depId.toString()}`}
          type="department"
        >
          {(provided, snapshot) => (
            <>
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex flex-col w-full"
              >
                {sortedRecourses.map((rs, idx) => {
                  return (
                    <Draggable key={rs.id.toString()} draggableId={rs.id.toString()} index={idx}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <EmployeeRow
                            weekDays={weekDays}
                            resource={rs}
                            dragHandler={provided.dragHandleProps}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
              {provided.placeholder}
            </>
          )}
        </Droppable>
      ) : null}
    </div>
  );
};

export default DepartmentGroup;
