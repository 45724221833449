import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import calendarService from '../services/calendar-service';
import leaveService from '../services/leave-service';
import { customToastError } from '../utils/custom-toast-error';

export function useDeleteLeaveRequest(configOptions: UseMutationOptions<any, any, any, any> = {}) {
  const queryClient = useQueryClient();
  return useMutation((id: number) => leaveService.deleteLeaveRequest(id), {
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
    onSettled: () => {
      queryClient.invalidateQueries([leaveService.leavesRequestsQueryKey]);
      queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
      queryClient.invalidateQueries([calendarService.leaveRequestsQueryKey]);
    },
    ...configOptions,
  });
}
