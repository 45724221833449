import { useCallback, useEffect, useRef, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { isToday } from 'date-fns';
import { formatDateToTimeZone, utcDate } from '../../../utils/format-date';
import { WeekDays } from '../calendar-reports';
import { useMediaQuery } from '@mui/material';

interface CalendarHeaderProps {
  weekDays: WeekDays[];
  handleTermChanges: (term: string) => void;
}

const CalendarHeader = ({ weekDays, handleTermChanges }: CalendarHeaderProps) => {
  const matches = useMediaQuery('(min-width:1024px)');
  const headerContainerRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const [fixClass, setFixClass] = useState(false);
  const headerRect = headerContainerRef.current?.getBoundingClientRect();
  // const mainRect = mainContainerRef.current?.getBoundingClientRect();
  const headerClasses = fixClass
    ? `fixed flex items-center bg-white h-16 border-b z-10`
    : `flex items-center w-full bg-white h-16 border-b z-10`;

  useEffect(() => {
    if (headerRef.current && matches) {
      mainContainerRef.current = document.getElementById('main-container') as HTMLDivElement;
      mainContainerRef.current.addEventListener('scroll', handleScrollEvent);
      return () => {
        mainContainerRef.current?.removeEventListener('scroll', handleScrollEvent);
      };
    }
  }, [headerRef.current, matches]);

  const handleScrollEvent = useCallback((e: Event) => {
    const mainContainer = e.target as HTMLDivElement;
    // const rect = headerRef.current!.getBoundingClientRect();
    if (mainContainer.scrollTop >= 100) {
      setFixClass(true);
    } else {
      setFixClass(false);
    }
  }, []);

  return (
    <div ref={headerContainerRef} className="relative h-16">
      <div
        ref={headerRef}
        className={headerClasses}
        style={{
          top: '100px',
          width: headerRect?.width ? headerRect.width : '100%',
        }}
      >
        <div className="flex items-center justify-start h-full px-4 w-52 shrink-0">
          <div className="relative text-gray-400 focus-within:text-gray-500">
            <input
              id="mobile-search"
              type="search"
              placeholder="Search ..."
              onChange={(e) => handleTermChanges(e.target.value)}
              className="block w-full pl-10 placeholder-gray-500 border-0 rounded-md focus:border-indigo-600 focus:ring-indigo-600"
            />
            <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-3">
              <MagnifyingGlassIcon className="w-5 h-5" aria-hidden="true" />
            </div>
          </div>
        </div>
        {weekDays.map(({ id, date, formattedDate }) => {
          const isThisDateToday = isToday(utcDate(date));
          return (
            <div
              key={id}
              className={`w-[calc(100%/7)] h-full flex flex-col items-center justify-center font-medium text-blue-400 border-l  ${
                isThisDateToday && 'bg-blue-50'
              }`}
            >
              <span className={`flex items-center justify-center h-8 px-8 w-12 rounded text-base`}>
                {formattedDate}
              </span>
              <span className="text-xs text-blue-400">{formatDateToTimeZone(date, 'dd LLL')}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarHeader;
