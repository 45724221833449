import { useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTitle } from 'react-use';
import Spinner from '../../components/spinner';
import { endOfDay, subDays } from 'date-fns';
import { formatDate, formatDateToTimeZone } from '../../utils/format-date';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import PrevPage from '../../components/ui/prev-page';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import { usePrintWorkTimeDetailsSlice } from '../../store/print-worktime-details-slice';
import { useStaffWorkTimeReport } from '../../hooks/use-staff-worktime-report';
import { EyeIcon } from '@heroicons/react/24/solid';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const StaffsWorkTimeDetailsReport = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Staffs Worktime Details`);
  const navigate = useNavigate();
  const query = useQuery();
  const { id } = useParams();
  const fromParam = query.get('from');
  const toParam = query.get('to');
  const [startDate, setStartDate] = useState<Date | null>(
    fromParam ? new Date(fromParam) : subDays(new Date(), 7),
  );
  const [endDate, setEndDate] = useState<Date | null>(
    toParam ? new Date(toParam) : endOfDay(new Date()),
  );
  const from = startDate ? formatDateToTimeZone(startDate, 'y-MM-dd') : null;
  const to = endDate ? formatDateToTimeZone(endDate, 'y-MM-dd') : null;
  const workSpaceSubDomain = useActiveWorkspaceSlice((state) => state.workspace?.sub_domain)!;
  const updatePrintWorkTimeDetailsData = usePrintWorkTimeDetailsSlice((state) => state.updateData);
  const staffWorkTimeReport = useStaffWorkTimeReport(workSpaceSubDomain, from, to);
  const staffWorkTimeDetailsReport = staffWorkTimeReport.data?.find(
    (s) => s.staff_id === +(id ?? -1)!,
  );
  const csvData = [
    [staffWorkTimeDetailsReport?.staff_name, staffWorkTimeDetailsReport?.staff_email],
    ['', '', '', '', '', '', '', '', ''],
    [
      'Date',
      'Worked',
      'Used Time Off',
      'Extra',
      'Used Breaks',
      'Total Break',
      'Unscheduled Working Time',
      'Clocks',
      'Time off',
    ],
    ...(staffWorkTimeDetailsReport?.days ?? []).map((report) => {
      const clocks = report.clocks.map((c) => [`${c.start}-${c.end}`]);
      const timeOff = (report?.leaveRequests ?? []).map((lr) => [
        `${lr.start}-${lr.end}-${lr.reason}`,
      ]);
      return [
        `'${formatDate(report.date_ms, 'dd-MM-y')}`,
        report.clocks_duration,
        report.total_leave_requests_duration,
        report.extra_schedules_duration,
        report.clocks_breaks_duration,
        report.schedules_breaks_duration,
        report.un_schedules_duration,
        clocks,
        timeOff,
      ];
    }),
    ['', '', '', '', '', '', '', ''],
    [
      'Total',
      staffWorkTimeDetailsReport?.schedules_duration,
      staffWorkTimeDetailsReport?.clocks_duration,
      staffWorkTimeDetailsReport?.total_leave_requests_duration,
      staffWorkTimeDetailsReport?.extra_schedules_duration,
      staffWorkTimeDetailsReport?.clocks_breaks_duration,
      staffWorkTimeDetailsReport?.schedules_breaks_duration,
      staffWorkTimeDetailsReport?.un_schedules_duration,
    ],
  ];

  const handlePrint = () => {
    updatePrintWorkTimeDetailsData(staffWorkTimeDetailsReport);
    navigate('/print-worktime-details');
  };

  const handleShowDetails = (id: number, date: number) => {
    navigate(`./${date}?from=${from}&to=${to}`);
  };

  return (
    <>
      <div className="w-full mx-auto space-y-0 pb-14 print:p-0 print:max-w-none print:w-full">
        <div className="flex justify-between mt-6 print:hidden md:mt-0">
          <PrevPage to="../" />
        </div>
        <div className="flex flex-col items-center justify-center w-full p-4 bg-white border border-gray-100 rounded-md shadow-lg md:p-9 print:shadow-none print:p-0 print:border-none">
          <div className="flex items-center justify-between w-full pb-6 mb-6 font-medium border-b print:pb-4 print:mb-0 print:border-b print:border-b-gray-200">
            <div className="flex flex-col w-full font-medium">
              <span className="capitalize">{staffWorkTimeDetailsReport?.staff_name}</span>
              <p className="mt-2 text-sm font-normal text-gray-400 print:mt-0">
                {staffWorkTimeDetailsReport?.staff_email}
              </p>
              <div className="flex flex-col w-full mt-6 space-y-8 font-medium md:space-y-0 md:space-x-4 md:flex-row md:items-center md:justify-between print:mt-0 print:hidden">
                <div className="flex items-center space-x-4">
                  <CSVLink
                    data={csvData}
                    filename={`${staffWorkTimeDetailsReport?.staff_name}-${from}-${to}.csv`}
                    className="flex items-center justify-center h-12 text-white transition duration-200 rounded w-28 bg-brand-primary hover:bg-brand-primary-dark"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                  <button
                    className="items-center justify-center hidden h-12 text-white transition duration-200 rounded md:flex w-28 bg-brand-primary hover:bg-brand-primary-dark"
                    type="button"
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                </div>
                <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-4 md:flex-row md:items-center">
                  <DatePicker
                    label="Start date"
                    value={startDate}
                    disableMaskedInput
                    inputFormat="dd MMM y"
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    label="End date"
                    value={endDate}
                    disableMaskedInput
                    inputFormat="dd MMM y"
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>
            </div>
          </div>
          {staffWorkTimeReport.isLoading ? (
            <div className="flex items-end justify-center w-full my-9">
              <span className="mr-4">Please wait</span>
              <Spinner size="small" />
            </div>
          ) : (
            <div className="flex flex-col w-full">
              <div className="grid w-full h-12 grid-cols-9 text-xs print:text-[8px] font-medium text-gray-700 bg-gray-100 rounded">
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-1">
                  <span className="line-clamp-2">Date</span>
                </div>
                {/* <div className="flex items-center justify-center h-full col-span-4 text-center md:flex md:col-span-1">
                  <span className="line-clamp-2">Scheduled</span>
                </div> */}
                <div className="flex items-center justify-center h-full col-span-4 text-center md:flex md:col-span-1">
                  <span className="line-clamp-2">Worked</span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2">Used Time Off</span>
                </div>
                {/* <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2">Owed</span>
                </div> */}
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2">Extra</span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2">Used Breaks</span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2">Total Break</span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-2 text-center md:flex">
                  <span className="line-clamp-2">Unscheduled Working Time</span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2">Actions</span>
                </div>
              </div>
              <div className="flex flex-col w-full divide-y-[1px] divide-gray-100">
                {staffWorkTimeDetailsReport?.days.map((report, i) => {
                  return (
                    <div
                      key={`${report.date}-${i}`}
                      className="grid w-full grid-cols-9 text-xs print:text-[8px] font-medium text-gray-700 rounded h-14 print:h-10"
                    >
                      <div className="flex flex-col items-center justify-center h-full col-span-3 text-center capitalize md:flex md:col-span-1 line-clamp-2">
                        {formatDate(report.date_ms, 'dd-MM-y')}
                        {report.is_weekend ? (
                          <span className="mt-1 text-xs text-red-400">Weekend</span>
                        ) : report.is_holiday ? (
                          <span className="mt-1 text-xs text-red-400">Public Holiday</span>
                        ) : null}
                      </div>
                      {/* <div className="flex items-center justify-center h-full col-span-4 text-center capitalize md:flex md:col-span-1 line-clamp-2">
                        {report.schedules_duration}
                      </div> */}
                      <div className="flex items-center justify-center h-full col-span-4 text-center capitalize md:flex md:col-span-1 line-clamp-2">
                        {report.clocks_duration}
                      </div>
                      <div className="items-center justify-center hidden h-full text-center capitalize md:flex md:col-span-1 line-clamp-2">
                        {report.total_leave_requests_duration}
                      </div>
                      {/* <div className="items-center justify-center hidden h-full text-center capitalize md:flex md:col-span-1 line-clamp-2">
                        {report.owed_schedules_duration}
                      </div> */}
                      <div className="items-center justify-center hidden h-full text-center capitalize md:flex md:col-span-1 line-clamp-2">
                        {report.extra_schedules_duration}
                      </div>
                      <div className="items-center justify-center hidden h-full text-center capitalize md:flex md:col-span-1 line-clamp-2">
                        {report.clocks_breaks_duration}
                      </div>
                      <div className="items-center justify-center hidden h-full text-center capitalize md:flex md:col-span-1 line-clamp-2">
                        {report.schedules_breaks_duration}
                      </div>
                      <div className="items-center justify-center hidden h-full text-center capitalize md:flex md:col-span-2 line-clamp-2">
                        {report.un_schedules_duration}
                      </div>
                      <div className="flex items-center justify-center h-full col-span-4 space-x-2 text-center md:col-span-1 md:flex line-clamp-2capitalize print:hidden">
                        <button
                          type="button"
                          className=""
                          disabled={false}
                          onClick={() =>
                            handleShowDetails(staffWorkTimeDetailsReport.staff_id, report.date_ms)
                          }
                        >
                          <EyeIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col w-full">
                <div className="flex items-center h-12 px-12 text-sm font-medium text-gray-500 rounded print:text-xs bg-gray-50 print:p-0 print:border-t print:border-t-gray-200">
                  Total
                </div>
                <div className="flex flex-col md:flex-row mt-4 md:space-x-4 text-xs print:text-[8px] print:mt-0">
                  <div className="flex flex-col w-full md:w-1/2 px-4 md:px-12 divide-y-[1px] divide-gray-100 print:p-0">
                    <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                      <span className="font-medium text-gray-500">Scheduled</span>
                      <span className="print:font-medium">
                        {staffWorkTimeDetailsReport?.schedules_duration}
                      </span>
                    </div>
                    <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                      <span className="font-medium text-gray-500">Worked</span>
                      <span className="print:font-medium">
                        {staffWorkTimeDetailsReport?.clocks_duration}
                      </span>
                    </div>
                    {/* <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                      <span className="font-medium text-gray-500">Owed</span>
                      <span className="print:font-medium">
                        {staffWorkTimeDetailsReport?.owed_schedules_duration}
                      </span>
                    </div> */}
                    <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                      <span className="font-medium text-gray-500">Extra</span>
                      <span className="print:font-medium">
                        {staffWorkTimeDetailsReport?.extra_schedules_duration}
                      </span>
                    </div>
                  </div>
                  <div className="hidden md:flex h-[224px] w-px bg-gray-100"></div>
                  <div className="flex flex-col w-full md:w-1/2 px-4 md:px-12 divide-y-[1px] divide-gray-100 print:p-0">
                    <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                      <span className="font-medium text-gray-500">Used Breaks</span>
                      <span className="print:font-medium">
                        {staffWorkTimeDetailsReport?.clocks_breaks_duration}
                      </span>
                    </div>
                    {/* <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                      <span className="font-medium text-gray-500">Total Break</span>
                      <span className="print:font-medium">
                        {staffWorkTimeDetailsReport?.schedules_breaks_duration}
                      </span>
                    </div> */}
                    <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                      <span className="font-medium text-gray-500"> Unscheduled Working Time</span>
                      <span className="print:font-medium">
                        {staffWorkTimeDetailsReport?.un_schedules_duration}
                      </span>
                    </div>
                    <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                      <span className="font-medium text-gray-500">Used Time Off</span>
                      <span className="print:font-medium">
                        {staffWorkTimeDetailsReport?.total_leave_requests_duration}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StaffsWorkTimeDetailsReport;
