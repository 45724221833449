import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

// interface SortData {
//   depId: number;
//   priority: number;
// }

interface State {
  departmentsSortData: number[] | null;
  employeesSortData: Record<string, number[]> | null;
}

interface Actions {
  changeDepartmentsSortData: (departmentsSortData: number[] | null) => void;
  changeEmployeesSortData: (employeesSortData: Record<string, number[]> | null) => void;
  reset: () => void;
}

const initialState: State = {
  departmentsSortData: null,
  employeesSortData: null,
};

const useDepartmentsSortDataSlice = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      changeDepartmentsSortData: (departmentsSortData: number[] | null) => {
        set({ departmentsSortData });
      },
      changeEmployeesSortData: (employeesSortData: Record<string, number[]> | null) => {
        if (employeesSortData) {
          set({ employeesSortData: { ...(get().employeesSortData ?? {}), ...employeesSortData } });
        } else {
          set({ employeesSortData });
        }
      },
      reset: () => {
        set({ ...initialState });
      },
    }),
    {
      name: 'departmentsSortData', // unique name
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const useDepartmentsSortData = () =>
  useDepartmentsSortDataSlice((state) => state.departmentsSortData);
export const useEmployeesSortData = () =>
  useDepartmentsSortDataSlice((state) => state.employeesSortData);

export const useChangeDepartmentsSortData = () =>
  useDepartmentsSortDataSlice((state) => state.changeDepartmentsSortData);
export const useChangeEmployeesSortData = () =>
  useDepartmentsSortDataSlice((state) => state.changeEmployeesSortData);
export const useResetDepartmentsSortData = () =>
  useDepartmentsSortDataSlice((state) => state.reset);
