import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import devicesService from '../../services/devices-service';
import { customToastError } from '../../utils/custom-toast-error';
import toast from 'react-hot-toast';
import { CreateDeviceRequest } from '../../interfaces/central-devices/create-device-request';
import { useNavigate } from 'react-router-dom';

export function useCreateCentralDevice(
  configOptions: UseMutationOptions<any, any, CreateDeviceRequest, any> = {},
) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => devicesService.createCentralDevice(data),
    onSuccess: () => {
      toast.success('Successfully Done');
      navigate('../devices');
      queryClient.invalidateQueries([devicesService.centralDevicesQueryKey]);
    },
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
      queryClient.invalidateQueries([devicesService.centralDevicesQueryKey]);
    },
    ...configOptions,
  });
}
