import { useCallback, useEffect, useRef, useState } from 'react';
import { useTitle } from 'react-use';
import GoogleMapReact from 'google-map-react';
import { useGeolocation } from 'react-use';
import { CheckIcon, MapPinIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@mui/material';
import { getCircleRadiusPoint, getRadiusInMeter } from '../../utils/circle-convertor';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import BuildingIcon from '../../../assets/maps/building.svg';
import { useCreateLocation } from '../../hooks/use-create-location';
import MapTitleModal from './components/map-title-modal';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useLocations } from '../../hooks/use-locations';
import { useUpdateLocation } from '../../hooks/use-update-location';
import { customToastError } from '../../utils/custom-toast-error';
import { useGetWorkspaces } from '../../hooks/workspaces/use-get-workspaces';

const googleMapOptions: GoogleMapReact.MapOptions = {
  scrollwheel: true,
  // mapTypeControl: false,
  scaleControl: true,
  // draggable: false,
  mapTypeId: 'roadmap',
  zoomControl: true,
};

type Shape = 'POLYGON' | 'CIRCLE';

const NewLocation = () => {
  const { id } = useParams();
  useTitle(
    `${import.meta.env.VITE_APP_TITLE} | ${id && id !== 'headquarter' ? 'Update' : 'New'} Location`,
  );
  const navigate = useNavigate();
  const locations = useLocations();
  const locationData = locations.data?.find((l) => id && l.id === +id);
  const gps = useGeolocation({ enableHighAccuracy: true });
  const workspaceTitle = useActiveWorkspaceSlice((state) => state.workspace?.title);
  const activeWsId = useActiveWorkspaceSlice((state) => state.workspace?.id);
  const [showMapTitleModal, setShowMapTitleModal] = useState(false);
  const [defaultCenter] = useState<google.maps.LatLngLiteral>({ lat: 50.827778, lng: -0.152778 });
  const [ShapeMode, setShapeMode] = useState<Shape>('CIRCLE');
  const [polygonPaths, setPolygonPaths] = useState<google.maps.LatLngLiteral[]>([]);
  const [circleCenter, setCircleCenter] = useState<google.maps.LatLngLiteral>(defaultCenter);
  const [circleRadius, setCircleRadius] = useState<number>(500);
  const [clickable, setClickable] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const mapsRef = useRef<typeof google.maps | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const mapMarkerRef = useRef<google.maps.Marker | null>(null);
  const shopMarkerRef = useRef<google.maps.Marker | null>(null);
  const polyRef = useRef<google.maps.Polyline | null>(null);
  const circleRef = useRef<google.maps.Circle | null>(null);
  const updateActiveWorkspace = useActiveWorkspaceSlice((state) => state.updateActiveWorkspace);
  const workspaces = useGetWorkspaces();

  const createLocation = useCreateLocation(() => {
    if (id !== 'headquarter') {
      navigate('../locations');
    }
  });
  const updateLocation = useUpdateLocation(() => {
    if (id !== 'headquarter') {
      navigate('../locations');
    }
  });

  useEffect(() => {
    if (locationData) {
      if (locationData.points.length > 2) {
        setShapeMode('POLYGON');
      }
    }
  }, [locationData]);

  useEffect(() => {
    const ws = workspaces.data?.find((w) => w.id === activeWsId);
    if (id === 'headquarter' && ws?.default_location_id) {
      updateActiveWorkspace(ws);
      navigate('../../calendar');
    }
  }, [workspaces, id]);

  const handleOpenMapTitleModal = useCallback(() => {
    // if (id) {
    //   handleSaveMap();
    // } else {
    //   setShowMapTitleModal(true);
    // }
    setShowMapTitleModal(true);
  }, []);

  const handleCloseMapTitleModal = useCallback(() => {
    setShowMapTitleModal(false);
  }, []);

  const handleApiLoaded = (map: google.maps.Map, maps: typeof google.maps) => {
    mapsRef.current = maps;
    mapRef.current = map;
    if (id && locationData) {
      handleActiveShape();
    } else {
      createShopMarker();
      createMapMarker();
      if (ShapeMode === 'POLYGON') {
        createPolygon();
      } else {
        createCircle();
      }
    }
  };

  const createShopMarker = (position: google.maps.LatLngLiteral = defaultCenter) => {
    shopMarkerRef.current = new mapsRef.current!.Marker({
      position,
      map: mapRef.current!,
      title: workspaceTitle,
      draggable: false,
      icon: {
        url: BuildingIcon,
        scaledSize: new google.maps.Size(64, 64),
        // origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(32, 32), // anchor
      },
    });
  };

  const createMapMarker = (position: google.maps.LatLngLiteral = defaultCenter) => {
    mapMarkerRef.current = new mapsRef.current!.Marker({
      position,
      map: mapRef.current!,
      draggable: true,
    });
    mapsRef.current!.event.addListener(
      mapMarkerRef.current,
      'dragend',
      (e: google.maps.MapMouseEvent) => {
        const point = e.latLng!.toJSON();
        setCircleCenter(point);
      },
    );
  };

  const createPolygon = (paths: google.maps.LatLngLiteral[] = []): void => {
    polyRef.current = new mapsRef.current!.Polygon({
      paths,
      strokeColor: '#592d88',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#592d88',
      fillOpacity: 0.35,
      editable: true,
      draggable: true,
    });
    mapsRef.current!.event.addListener(polyRef.current, 'dragstart', () => {
      setClickable(false);
    });
    mapsRef.current!.event.addListener(polyRef.current, 'dragend', () => {
      const paths = handleTransformPaths(polyRef.current!.getPath());
      setPolygonPaths(paths);
      setTimeout(() => {
        setClickable(true);
      }, 200);
    });
    polyRef.current!.setMap(mapRef.current!);
  };

  const createCircle = (center = circleCenter, radius = circleRadius) => {
    circleRef.current = new mapsRef.current!.Circle({
      strokeColor: '#592d88',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#592d88',
      fillOpacity: 0.35,
      center,
      radius,
      editable: true,
      draggable: true,
    });
    mapsRef.current!.event.addListener(circleRef.current, 'radius_changed', () => {
      setCircleRadius(circleRef.current!.getRadius());
    });
    mapsRef.current!.event.addListener(circleRef.current, 'center_changed', () => {
      setCircleCenter(circleRef.current!.getCenter()!.toJSON());
    });
    circleRef.current!.setMap(mapRef.current!);
  };

  const handleMapCLick = ({ lat, lng }: GoogleMapReact.ClickEventValue) => {
    if (!clickable) {
      return;
    }
    const point = { lat, lng };
    if (ShapeMode === 'POLYGON') {
      polyRef.current!.setMap(mapRef.current!);
      setPolygonPaths((state) => {
        return [...state, point];
      });
      polyRef.current!.setOptions({ path: [...polygonPaths, point] });
    } else {
      mapMarkerRef.current!.setPosition(point);
      setCircleCenter(point);
      circleRef.current!.setMap(mapRef.current!);
      circleRef.current!.setOptions({ center: point });
    }
  };

  const handleShapeCircle = () => {
    mapMarkerRef.current?.setVisible(true);
    handleClearMap();
    setShapeMode('CIRCLE');
    createCircle();
  };

  const handleShapePolygon = () => {
    mapMarkerRef.current?.setVisible(false);
    handleClearMap();
    setShapeMode('POLYGON');
    createPolygon();
  };

  const handleClearMap = () => {
    if (circleRef.current) {
      mapsRef.current!.event.clearListeners(circleRef.current, 'radius_changed');
      mapsRef.current!.event.clearListeners(circleRef.current, 'center_changed');
    }
    if (polyRef.current) {
      mapsRef.current!.event.clearListeners(polyRef.current, 'dragstart');
      mapsRef.current!.event.clearListeners(polyRef.current, 'dragend');
    }
    if (polyRef.current) {
      setPolygonPaths([]);
      polyRef.current.setOptions({ path: [] });
      polyRef.current.setMap(null);
    }
    if (circleRef.current) {
      circleRef.current.setMap(null);
    }
  };

  const handleMyLocation = () => {
    const { latitude: lat, longitude: lng, error } = gps;
    if (error?.message) {
      customToastError('Error', "Sorry, We can't find your location.");
      return;
    }
    if (lat && lng) {
      mapRef.current!.setCenter({ lat, lng });
    }
  };

  const handleTransformPaths = (data: google.maps.MVCArray<google.maps.LatLng>) => {
    const arr = data?.getArray() ?? [];
    const paths = arr.map((path) => {
      return path.toJSON();
    });
    return paths;
  };

  const handleActiveShape = () => {
    const points = locationData!.points.map((ln) => ({ lat: ln.lat, lng: ln.lon }));
    if (points.length < 3) {
      mapRef.current?.setCenter(points[0]);
      const radius = getRadiusInMeter(points[0], points[1]);
      createCircle(points[0], radius);
      createMapMarker(points[0]);
    } else {
      const c = getPolygonCenter(points);
      createPolygon(points);
      mapRef.current?.setCenter(c);
      createShopMarker();
      createMapMarker(c.toJSON());
    }
  };

  const getPolygonCenter = (points: { lat: number; lng: number }[]) => {
    const bounds = new mapsRef.current!.LatLngBounds();
    const polygonCoords = points.map((p) => {
      return new google.maps.LatLng(p.lat, p.lng);
    });
    for (let i = 0; i < polygonCoords.length; i++) {
      bounds.extend(polygonCoords[i]);
    }
    return bounds.getCenter();
  };

  const handleSaveMap = (title = '') => {
    let points: google.maps.LatLngLiteral[] = [];
    if (ShapeMode === 'POLYGON') {
      points = handleTransformPaths(polyRef.current!.getPath());
      if (points.length < 3) {
        customToastError('Error', 'Please set some points');
        return;
      }
    } else {
      const radiusPoint = getCircleRadiusPoint(circleCenter, circleRadius);
      if (!circleRef.current?.getMap()) {
        customToastError('Error', 'Please draw a shape');
        return;
      }
      points = [circleCenter, radiusPoint];
    }
    if (id && id !== 'headquarter') {
      updateLocation.mutate({
        id,
        data: {
          title,
          points: points.map((p) => ({ lat: p.lat, lon: p.lng })),
        },
      });
    } else {
      createLocation.mutate({
        title,
        points: points.map((p) => ({ lat: p.lat, lon: p.lng })),
      });
    }
  };

  return (
    <>
      <div className="w-full mx-auto space-y-8 pb-14" ref={containerRef}>
        <div className="flex flex-col items-center justify-center w-full p-4 bg-white border border-gray-100 rounded-md shadow-lg md:p-9">
          <div className="flex flex-col w-full pb-6 mb-6 font-medium border-b md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col">
              <span>{id ? 'Update Location' : 'New Location'}</span>
              <p className="mt-2 text-sm font-normal text-gray-400">
                You can choose your locations
              </p>
            </div>
            <Link
              to="../locations"
              className="flex items-center justify-center w-full mt-8 text-sm font-medium text-white transition duration-200 rounded shadow md:mt-0 md:w-40 bg-brand-primary hover:bg-brand-primary-dark h-11"
            >
              Locations
            </Link>
          </div>
          <div className="flex flex-col w-full">
            <div
              className={`flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between`}
            >
              <div className="flex space-x-4">
                <Tooltip title="Current Location">
                  <button
                    onClick={handleMyLocation}
                    className="flex items-center justify-center w-[50px] h-10 text-white text-sm transition duration-200 rounded bg-brand-primary hover:bg-brand-primary-dark"
                  >
                    <MapPinIcon className="w-6 h-6" />
                  </button>
                </Tooltip>
                <Tooltip title="Clear">
                  <button
                    disabled={createLocation.isLoading}
                    onClick={handleClearMap}
                    className="flex items-center justify-center w-[50px] h-10 text-white text-sm transition duration-200 rounded bg-brand-primary hover:bg-brand-primary-dark"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>
                </Tooltip>
                <Tooltip title={id ? 'Update' : 'Save'}>
                  <button
                    disabled={createLocation.isLoading}
                    onClick={handleOpenMapTitleModal}
                    className="flex items-center justify-center w-[50px] h-10 text-white text-sm transition duration-200 rounded bg-brand-primary hover:bg-brand-primary-dark"
                  >
                    {createLocation.isLoading ? (
                      <CircularProgress
                        sx={{
                          color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                        }}
                        size={24}
                      />
                    ) : (
                      <CheckIcon className="w-6 h-6" />
                    )}
                  </button>
                </Tooltip>
              </div>
              <div className="flex items-center justify-between md:justify-start md:space-x-4">
                <button
                  onClick={handleShapeCircle}
                  className={`${
                    ShapeMode === 'CIRCLE' ? 'bg-brand-primary' : 'bg-gray-400'
                  } w-[100px] h-10 text-white rounded text-sm hover:bg-brand-primary hover:text-white transition`}
                >
                  Circle
                </button>
                <button
                  onClick={handleShapePolygon}
                  className={`${
                    ShapeMode === 'POLYGON' ? 'bg-brand-primary' : 'bg-gray-400'
                  } w-[100px] h-10 text-white rounded text-sm hover:bg-brand-primary hover:text-white transition`}
                >
                  Polygon
                </button>
              </div>
            </div>
            <div className="flex h-[500px] mt-6">
              <GoogleMapReact
                bootstrapURLKeys={{ key: import.meta.env.VITE_GOOGLE_MAP_KEY }}
                options={googleMapOptions}
                defaultCenter={defaultCenter}
                defaultZoom={15}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                onClick={(value) => handleMapCLick(value)}
              ></GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
      <MapTitleModal
        locationData={locationData}
        show={showMapTitleModal}
        closeModal={handleCloseMapTitleModal}
        handleMap={handleSaveMap}
      />
    </>
  );
};

export default NewLocation;
