import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import workspaceService from '../../../services/workspace-service';
import { useActiveWorkspaceSlice } from '../../../store/active-workspace-slice';
import { useAuthSlice } from '../../../store/auth-slice';
import { useDebounce } from 'use-debounce';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';

const UserDetails = () => {
  const queryClient = useQueryClient();
  const [avatarError, setAvatarError] = useState(false);
  const name = useAuthSlice((state) => `${state.firstName} ${state.lastName}`);
  const attachment = useAuthSlice((state) => state.attachment);
  const workspaceTitle = useActiveWorkspaceSlice((state) => state.workspace?.title);
  const workspaceId = useActiveWorkspaceSlice((state) => state.workspace?.id);
  const stickyNote = useActiveWorkspaceSlice((state) => state.workspace?.stickyNote);
  const updateActiveWorkspace = useActiveWorkspaceSlice((state) => state.updateActiveWorkspace);
  const [note, setNote] = useState<string>(
    useActiveWorkspaceSlice.getState().workspace?.stickyNote ?? '',
  );
  const [value] = useDebounce(note, 300);
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current || (value === '' && stickyNote === null)) {
      isFirstRun.current = false;
      return;
    }
    if (value !== stickyNote && !isFirstRun.current) {
      mutate({ note });
    }
  }, [value]);

  useEffect(() => {
    setNote(stickyNote ?? '');
  }, [workspaceId]);

  const { mutate } = useMutation(workspaceService.stickyNote, {
    onSuccess: (data) => {
      updateActiveWorkspace(data);
      queryClient.invalidateQueries([workspaceService.workspacesQueryKey]);
    },
  });

  return (
    <div className="flex flex-col p-6 bg-white border shadow rounded-2xl ">
      <div className="flex flex-col items-center justify-center">
        {attachment && !avatarError ? (
          <img
            className="rounded-full w-28 h-28"
            src={attachment}
            alt="user.name"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              // currentTarget.src = 'image_path_here';
              setAvatarError(true);
            }}
          />
        ) : (
          <span className="block overflow-hidden bg-gray-100 rounded-full h-28 w-28">
            <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        )}
        <span className="mt-4 text-base font-medium text-gray-600 capitalize">
          Welcome back {name}
        </span>
        <span className="mt-4 text-sm font-medium text-gray-800 capitalize">{workspaceTitle}</span>
      </div>
      <span className="mt-10 text-sm font-bold text-indigo-600">
        Create quick reminders for {capitalizeFirstLetter(workspaceTitle)}
      </span>
      <textarea
        className="w-full mt-4 border border-gray-200 min-h-[200px] rounded bg-yellow-50"
        placeholder="My quick notes here ..."
        value={note}
        onChange={(e) => setNote(e.target.value)}
      ></textarea>
    </div>
  );
};

export default UserDetails;
