import Dialog from '@mui/material/Dialog';
import { LeaveReq } from '../../../interfaces/calendar-leave-request-interface';
import { useLeaves } from '../../../hooks/use-leaves';
import toast from 'react-hot-toast';
import { XMarkIcon } from '@heroicons/react/24/outline';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { InputLabel } from '@mui/material';
import { LeaveReqDetails } from '../leaves-of-absences';
import { useEmployees } from '../../../hooks/use-employees';
import { useArchivedEmployees } from '../../../hooks/use-archived-employees';
import { customToastError } from '../../../utils/custom-toast-error';

interface ChangeStatusModalProps {
  show: boolean;
  closeModal: () => void;
  updateLeaveRequest: (status: LeaveReq['status']) => void;
  currentStatus: LeaveReq['status'] | undefined;
  reqDetails: LeaveReqDetails | null;
  setReqDetails: any;
}
const ChangeStatusModal = ({
  show,
  closeModal,
  updateLeaveRequest,
  currentStatus,
  reqDetails,
  setReqDetails,
}: ChangeStatusModalProps) => {
  const { data: leaveTypes } = useLeaves();
  const { data: employees = [] } = useEmployees();
  const { data: archivedEmployees = [] } = useArchivedEmployees();
  const employee = [...employees, ...archivedEmployees]?.find((d) => d.id === reqDetails?.staffId);
  const filteredTypes = leaveTypes?.filter((type) => {
    if (employee) {
      const find = employee.leaves.find((l: any) => l.id === type.id);
      return !!find;
    }
    return true;
  });

  const handleClose = () => {
    closeModal();
  };

  const handleChangeStatus = (status: LeaveReq['status']) => {
    if (status === 'approved' && !reqDetails?.leaveId) {
      customToastError('Error', `Please select a type`);
      return;
    }
    updateLeaveRequest(status);
    closeModal();
  };

  return (
    <Dialog onClose={handleClose} open={show} fullWidth={false} maxWidth={'md'}>
      <div className="flex flex-col w-[600px] p-9">
        <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900 capitalize">
          <span>Current Status: {currentStatus}</span>
          <button
            type="button"
            onClick={handleClose}
            className="ml-6 transition border border-transparent rounded shadow-sm self-baseline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="flex flex-col mt-4">
          <div className="flex mt-6">
            <FormControl fullWidth>
              <InputLabel id="demo-select-small">Type</InputLabel>
              <Select
                label="Type"
                value={reqDetails?.leaveId ? String(reqDetails.leaveId) : ''}
                onChange={(event: SelectChangeEvent) => {
                  setReqDetails((state: LeaveReqDetails) => ({
                    ...state,
                    leaveId: event.target.value,
                  }));
                }}
              >
                {filteredTypes?.map((lt) => {
                  return (
                    <MenuItem key={lt.id} value={lt.id}>
                      {lt.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="flex items-center justify-between w-full pt-9">
            <div
              className="rounded hover:bg-gray-300 transition text-gray-800 bg-gray-200 w-[30%] h-14 cursor-pointer flex items-center justify-center"
              onClick={() => handleChangeStatus('pending')}
            >
              Pending
            </div>
            <div
              className="rounded hover:bg-green-300 transition text-green-800 bg-green-200 w-[30%] h-14 cursor-pointer flex items-center justify-center"
              onClick={() => handleChangeStatus('approved')}
            >
              Approved
            </div>
            <div
              className="rounded hover:bg-red-300 transition text-red-800 bg-red-200 w-[30%] h-14 cursor-pointer flex items-center justify-center"
              onClick={() => handleChangeStatus('declined')}
            >
              Declined
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ChangeStatusModal;
