import { Link } from 'react-router-dom';

interface WidgetProps {
  icon: any;
  path: string;
  caption: string;
  stats: { name: string; color: string }[];
}

const Widget = ({ icon: Icon, path, caption, stats }: WidgetProps) => {
  return (
    <div className="flex flex-col p-6 bg-white border shadow rounded-2xl">
      <div className="flex">
        <div className="flex items-center justify-center transition duration-200 rounded bg-brand-primary hover:bg-brand-primary-dark w-14 h-14 shrink-0">
          <Icon className="w-8 h-8 text-white" />
        </div>
        <div className="flex flex-col ml-4">
          <span className="text-sm font-semibold text-gray-800">{caption}</span>
          <div className="flex flex-col mt-2">
            {stats.map((stat) => {
              return (
                <span key={stat.name} className={`h-6 text-sm font-medium ${stat.color}`}>
                  {stat.name}
                </span>
              );
            })}
          </div>
        </div>
      </div>
      <Link
        to={path}
        className="flex items-center px-4 py-2 mt-auto text-sm font-medium underline bg-gray-100 rounded cursor-pointer text-brand-primary"
      >
        View all
      </Link>
    </div>
  );
};

export default Widget;
