import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Layout from './components/layout';
import Header from './components/header';
import WorkspacesBar from './components/workspaces-bar';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import Loading from '../../components/libs/loading';
import { useNetworkState } from 'react-use';
import { customToastError } from '../../utils/custom-toast-error';
import { useGetWorkspaces } from '../../hooks/workspaces/use-get-workspaces';

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const activeSubDomain = useActiveWorkspaceSlice((state) => state.workspace?.sub_domain);
  const updateActiveWorkspace = useActiveWorkspaceSlice((state) => state.updateActiveWorkspace);
  const networkState = useNetworkState();
  const workspaces = useGetWorkspaces();

  useEffect(() => {
    if (workspaces.isLoading) {
      return;
    }
    if (!workspaces.data?.length) {
      navigate(`/welcome-page`);
      return;
    }
    if (slug && !activeSubDomain) {
      const ws = workspaces.data.find((w) => w.sub_domain === slug);
      if (ws) {
        updateActiveWorkspace(ws);
      } else {
        navigate(`/welcome-page`);
      }
      return;
    }
    if (activeSubDomain !== slug) {
      navigate(`/${activeSubDomain}`);
      return;
    }
    if (!slug && !activeSubDomain) {
      updateActiveWorkspace(workspaces.data[0]);
      navigate(`/${workspaces.data[0].sub_domain}`);
      return;
    }
  }, [slug, activeSubDomain, workspaces.isLoading, workspaces.data, updateActiveWorkspace]);

  useEffect(() => {
    let id: number | null = null;
    if (!networkState.online) {
      id = window.setInterval(() => {
        customToastError('Error', 'No Internet Connection');
      }, 6000);
    } else {
      id && window.clearInterval(id);
    }
    return () => {
      if (id) return window.clearInterval(id);
    };
  }, [networkState.online]);

  useEffect(() => {
    let timer: number;
    if (!!workspaces && loading) {
      timer = window.setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    return () => window.clearTimeout(timer);
  }, [workspaces, loading]);

  if (loading) {
    return <Loading />;
  } else {
    return <Layout outlet={<Outlet />} header={<Header />} WorkspacesBar={<WorkspacesBar />} />;
  }
};

export default Home;
