import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';
import { useTitle } from 'react-use';
import { useNavigate } from 'react-router-dom';
import PrevPage from '../../components/ui/prev-page';
import workspaceService from '../../services/workspace-service';
import toast from 'react-hot-toast';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import { useAuthSlice } from '../../store/auth-slice';
import Select from 'react-select';
import { timezones } from '../../utils/timezones';
import UnSplashImage from '../../components/libs/unsplash-image';
import { customToastError } from '../../utils/custom-toast-error';

interface NewWorkspaceNameForm {
  title: string;
  timezone: string;
}

const schema = yup
  .object({
    title: yup
      .string()
      .min(3, ({ min }) => `must be at least ${min} characters`)
      .required('This field is required'),
    timezone: yup.string().required('This field is required'),
  })
  .required();

const options = timezones.map((tz) => ({ label: tz, value: tz }));

const NewWorkspaceName = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | New Workspace Name`);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const resetActiveWorkspace = useActiveWorkspaceSlice((state) => state.reset);
  const merchantEmail = useAuthSlice((state) => state.email);
  const updateActiveWorkspace = useActiveWorkspaceSlice((state) => state.updateActiveWorkspace);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<NewWorkspaceNameForm>({
    defaultValues: {
      title: '',
      timezone: 'Europe/London',
    },
    resolver: yupResolver<any>(schema),
  });

  const { mutate: cwMutate, isLoading: cwIsLoading } = useMutation(
    workspaceService.createWorkspace,
    {
      onSuccess: (data) => {
        toast.success(`New Workspace Created.`);
        queryClient.invalidateQueries([workspaceService.workspacesQueryKey]);
        resetActiveWorkspace();
        updateActiveWorkspace(data);
        navigate(`/${data.sub_domain}/settings/new-location/headquarter`);
        // if (!workspaces.length) {
        //   navigate(`/${data.sub_domain}/calendar`);
        // } else {
        //   navigate(`/${data.sub_domain}`);
        // }
      },
      onError: (err: any) => {
        customToastError(
          'Error',
          err.response.data.message ?? `Sorry we cant create workspace right now.`,
        );
      },
    },
  );

  const onSubmit: SubmitHandler<NewWorkspaceNameForm> = async (data) => {
    if (cwIsLoading) {
      return;
    }
    cwMutate({ title: data.title, email: merchantEmail!, timezone: data.timezone });
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="fixed top-0 bottom-0 right-0 left-0 z-[1]">
        <UnSplashImage />
      </div>
      <div className="max-w-md mx-auto w-full p-9 bg-white shadow-lg rounded-[12px] z-50">
        <PrevPage to="/" />
        <div className="flex flex-col items-center justify-center mt-9">
          <h1 className="text-3xl font-bold text-center">Create a Workspace</h1>
          <p className="mt-4 text-sm font-medium text-center text-gray-600">
            To create a workspace for your business, enter its name.
          </p>
        </div>
        <div className="mt-8">
          <div className="mt-6">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-1">
              <div>
                <label
                  htmlFor="title"
                  title="Workspace Name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Business Name
                </label>
                <div className="mt-1">
                  <input
                    id="title"
                    {...register('title')}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <p className="pt-1 text-sm text-red-500 capitalize h-7 ">{errors.title?.message}</p>
              </div>
              <div>
                <label
                  htmlFor="timezone"
                  title="TimeZone"
                  className="text-sm font-medium text-gray-700"
                >
                  <span>
                    Timezone
                    <span className="ml-1 text-sm text-gray-500">
                      (For each business, you can set the time zone once. Ensure it is set
                      correctly.)
                    </span>
                  </span>
                </label>
                <div className="mt-1">
                  <Controller
                    name="timezone"
                    control={control}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        ref={ref}
                        value={options.find((c) => c.value === value)}
                        name={name}
                        hideSelectedOptions={false}
                        onChange={(selectedOption: any) => {
                          onChange(selectedOption.value);
                        }}
                        options={options}
                      />
                    )}
                  />
                </div>
                <p className="pt-1 text-sm text-red-500 capitalize h-7 ">
                  {errors.timezone?.message}
                </p>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Done
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewWorkspaceName;
