import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import locationsService from '../services/locations-service';
import { customToastError } from '../utils/custom-toast-error';
import workspaceService from '../services/workspace-service';

export function useUpdateLocation(fn: () => void) {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }: any) => locationsService.updateLocation(+id, data), {
    onSuccess: (data) => {
      toast.success('Location Updated Successfully');
      // queryClient.setQueryData([locationsService.locationsQueryKey], (old: any[] | undefined) => {
      //   return old?.length ? [...old, data] : [data];
      // });
      fn();
    },
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
    onSettled: () => {
      queryClient.invalidateQueries([locationsService.locationsQueryKey]);
      queryClient.invalidateQueries([workspaceService.workspacesQueryKey]);
    },
  });
}
