import Dialog from '@mui/material/Dialog';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const ReasonsList = [
  'High demand',
  'Insufficient notice',
  'It coincides with a critical project deadline',
  "It would significantly affect our team's workload",
  "There's no available backup coverage",
  'There are already too many employees on leave',
];

interface ReasonModalProps {
  show: boolean;
  closeModal: () => void;
  addReason: (reason: string) => void;
}

interface IForm {
  reason: string;
}

const schema = yup
  .object({
    reason: yup.string().max(250, 'Reason is too long'),
  })
  .required();

const ReasonModal = ({ show, closeModal, addReason }: ReasonModalProps) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IForm>({
    resolver: yupResolver<any>(schema),
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    addReason(data.reason);
    reset({ reason: '' });
    closeModal();
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <Dialog onClose={handleClose} open={show} fullWidth={false} maxWidth={'md'}>
      <div className="flex flex-col w-[420px] p-6">
        <div className="text-lg font-medium leading-6 text-gray-900">Please provide a reason</div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="grid grid-cols-1 m-6 md:m-0 md:mt-6 gap-y-2 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="reason" className="block text-sm font-medium text-gray-700">
                  Reason
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="reason"
                    {...register('reason')}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <p className="h-8 pt-2 text-red-500 first-letter:capitalize">
                  {errors.reason?.message}
                </p>
              </div>
              <div className="flex flex-col divide-y-2 sm:col-span-6 divide-gray-50">
                {ReasonsList.map((r) => {
                  return (
                    <button
                      key={r}
                      onClick={() => onSubmit({ reason: r })}
                      className="flex items-center w-full h-10 px-2 text-sm text-left text-gray-700 transition rounded hover:bg-gray-100"
                      type="button"
                    >
                      {r}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between w-full border-t pt-9">
            <button
              onClick={handleClose}
              type="button"
              className="flex justify-center w-40 py-3 text-sm font-medium text-left text-white bg-red-600 border border-transparent rounded shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Close
            </button>
            <button
              type="submit"
              className="flex justify-center w-40 py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default ReasonModal;
