import { useState } from 'react';
import { differenceInMinutes, isSameDay } from 'date-fns';
import { useCalendarSlice } from '../../../store/calendar-slice';
import { dateUtc, formatDateToTimeZone } from '../../../utils/format-date';
import PencilIcon from '~icons/carbon/edit';
import TrashIcon from '~icons/carbon/trash-can';
import calendarService, { WorktimeDeleteType } from '../../../services/calendar-service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Calendar } from '../../../interfaces/calendar-interface';
import toast from 'react-hot-toast';
import { convertMToHMWithLabel } from '../../../utils/format-minutes';
import Confirmation from '../../../components/libs/confirmation';
import { AxiosError } from 'axios';
import { customToastError } from '../../../utils/custom-toast-error';
import { NoSymbolIcon } from '@heroicons/react/24/solid';
import { useRejectScheduleAttendance } from '../../../hooks/use-reject-schedule-attendance';
import ConfirmationWithReasonModal, {
  ConfirmationWithReasonModalData,
} from '../../attendance-details-date/components/confirmation-with-reason-modal';

const schedulesTotalMins = (schedules: Calendar[] | undefined) => {
  let mins = 0;
  if (schedules) {
    schedules.forEach((sch) => {
      mins = mins + differenceInMinutes(sch.end_at, sch.start_at);
    });
  }
  return mins;
};

const WorktimeList = () => {
  const queryClient = useQueryClient();
  const activeResource = useCalendarSlice((state) => state.activeResource);
  const selectedDate = useCalendarSlice((state) => state.selectedDate);
  const toggleScheduleModal = useCalendarSlice((state) => state.updateShowScheduleModal);
  const updateScheduleModalTab = useCalendarSlice((state) => state.updateScheduleModalTab);
  const updateWorktimeForUpdate = useCalendarSlice((state) => state.updateWorktimeForUpdate);
  const updateActiveResource = useCalendarSlice((state) => state.updateActiveResource);
  const [wt, setWt] = useState<Calendar | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationWithReason, setShowConfirmationWithReason] = useState(false);
  const schedules =
    activeResource?.schedules.filter((ev) => {
      if (selectedDate) {
        return (
          isSameDay(dateUtc(ev.start_at), dateUtc(selectedDate)) &&
          (ev.status === 'accepted' || ev.status === 'pending')
        );
      }
      return false;
    }) ?? [];
  const totalMins = schedulesTotalMins(schedules);
  const rejectScheduleAttendance = useRejectScheduleAttendance({
    onSuccess: (data) => {
      setWt(null);
      updateActiveResource({
        ...activeResource!,
        schedules: activeResource!.schedules.map((sch) => {
          if (data.id === sch.id) {
            return data;
          }
          return sch;
        }),
      });
      toast.success(`Worktime rejected successfully`);
      queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
    },
    onError(error: AxiosError<any>) {
      setWt(null);
      customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
    },
  });

  const { mutate: deleteWorktime, isLoading: deleteWorktimeIsLoading } = useMutation(
    ({ id, type }: { id: number; type: WorktimeDeleteType }) =>
      calendarService.deleteWorktime(id, type),
    {
      onSuccess: (_, variables) => {
        setWt(null);
        updateActiveResource({
          ...activeResource!,
          schedules: activeResource!.schedules.filter((s) => s.id !== variables.id),
        });
        toast.success(`Worktime deleted successfully`);
        queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
      },
      onError(error: AxiosError<any>) {
        setWt(null);
        customToastError(
          'Error',
          error?.response?.data?.message ?? "Sorry cant't delete right now",
        );
      },
    },
  );

  const handleOpenConfirmationWithReason = () => {
    setShowConfirmationWithReason(true);
  };

  const handleCloseConfirmationWithReason = (data: ConfirmationWithReasonModalData) => {
    setShowConfirmationWithReason(false);
    if (data.confirm) {
      rejectScheduleAttendance.mutate({
        body: {
          working_object_id: wt!.id,
          status_rejected_comment: data.text,
        },
        source: 'CALENDAR',
      });
    }
  };

  const handleEdit = (wt: Calendar) => {
    updateWorktimeForUpdate(wt);
    updateScheduleModalTab('Add Worktime');
  };

  const handleCloseModal = () => {
    toggleScheduleModal(false);
  };

  const handleDeleteWt = (wt: Calendar) => {
    setWt(wt);
    handleOpenConfirmation();
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && wt && !deleteWorktimeIsLoading) {
      deleteWorktime({ id: wt.id, type: 'CALENDAR' });
      return;
    }
  };

  const handleRejectSchedule = (wt: Calendar) => {
    setWt(wt);
    handleOpenConfirmationWithReason();
  };

  return (
    <>
      <div className="flex flex-col flex-1 w-full h-full">
        <div className="overflow-hidden overflow-y-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg h-[calc(100%-64px)]">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr className="grid grid-cols-5">
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  Start
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  End
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
                {/* <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  Total Breaks
                </th> */}
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  Job
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {schedules?.map((wt) => {
                const isUserOnline = wt.clocks.find((c) => !c.clock_out);
                return (
                  <tr key={wt.id} className="grid grid-cols-5 h-14">
                    <td className="px-2 text-sm text-gray-500">
                      <div className="flex flex-col items-center justify-center h-full">
                        <div className="font-semibold text-center text-gray-900">
                          {formatDateToTimeZone(wt.start_at, 'HH:mm')}
                        </div>
                        <div className="text-center text-gray-500">
                          {formatDateToTimeZone(wt.start_at, 'dd-MM-y')}
                        </div>
                      </div>
                    </td>
                    <td className="px-2 text-sm text-gray-500">
                      <div className="flex flex-col items-center justify-center h-full">
                        <div className="font-semibold text-center text-gray-900">
                          {formatDateToTimeZone(wt.end_at, 'HH:mm')}
                        </div>
                        <div className="text-center text-gray-500">
                          {formatDateToTimeZone(wt.end_at, 'dd-MM-y')}
                        </div>
                      </div>
                    </td>
                    <td className="px-2 text-sm text-gray-500">
                      <div className="flex items-center justify-center h-full text-center text-gray-900 capitalize">
                        {wt.status}
                      </div>
                    </td>
                    {/* <td className="px-2 text-sm text-gray-500">
                      <div className="flex items-center justify-center h-full text-center text-gray-900">
                        {wt.breaks?.length ?? 0}
                      </div>
                    </td> */}
                    <td className="px-2 text-sm text-gray-500">
                      <div className="flex items-center justify-center h-full text-center text-gray-900">
                        <span className="line-clamp-2">
                          {wt.job?.title ?? activeResource?.jobTitle}
                        </span>
                      </div>
                    </td>
                    <td className="relative px-2 text-sm font-medium text-center">
                      <div className="flex items-center justify-center h-full space-x-2">
                        <button
                          type="button"
                          className="text-gray-400 transition hover:text-gray-800 disabled:text-gray-300"
                          disabled={
                            wt.status === 'edited' ||
                            wt.status === 'rejected' ||
                            !!isUserOnline ||
                            !wt.end_at ||
                            wt.is_locked ||
                            wt.start_at === wt.end_at ||
                            wt.clocks.length >= 1
                          }
                          onClick={() => handleRejectSchedule(wt)}
                        >
                          <NoSymbolIcon className="w-6 h-6" />
                        </button>
                        <button
                          type="button"
                          className="text-gray-400 transition disabled:text-gray-300 hover:text-gray-800"
                          disabled={
                            deleteWorktimeIsLoading ||
                            wt.clocks.length >= 1 ||
                            wt.status === 'edited' ||
                            wt.is_locked ||
                            wt.status === 'rejected'
                          }
                          onClick={() => handleDeleteWt(wt)}
                        >
                          <TrashIcon className="w-6 h-6" />
                        </button>
                        <button
                          type="button"
                          className="text-gray-400 transition disabled:text-gray-300 hover:text-gray-800"
                          disabled={
                            deleteWorktimeIsLoading ||
                            wt.clocks.length >= 1 ||
                            wt.is_locked ||
                            wt.status === 'edited' ||
                            wt.status === 'rejected'
                          }
                          onClick={() => handleEdit(wt)}
                        >
                          <PencilIcon className="w-6 h-6" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between pt-6 text-base text-blue-500">
          <button
            type="button"
            onClick={handleCloseModal}
            className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Close
          </button>
          <div className="flex items-center">
            <span className="text-sm">Total:</span>
            <span className="ml-2">{convertMToHMWithLabel(totalMins)}</span>
          </div>
        </div>
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
        title={wt?.clocks.length ? 'Are you sure about this?' : undefined}
      />
      <ConfirmationWithReasonModal
        show={showConfirmationWithReason}
        title="Are you sure about rejecting?"
        closeModal={handleCloseConfirmationWithReason}
      />
    </>
  );
};

export default WorktimeList;
