import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import workspaceService from '../../services/workspace-service';
import { Workspace } from '../../interfaces/workspace-interface';

export function useGetWorkspaces(configOptions: UseQueryOptions<any, any, Workspace[], any> = {}) {
  return useQuery({
    queryKey: [workspaceService.workspacesQueryKey],
    queryFn: () => workspaceService.getWorkspaces(),
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 3,
    ...configOptions,
  });
}
