import { ChangeShiftData, ChangeShiftResponse } from '../interfaces/change-shift-interface';
import { ShiftRequest } from '../interfaces/shift-interface';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import http from './axios-instance';

export interface AvailableStaffs {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  mobile?: any;
  job_id: number;
  employment_status: string;
  official_start_date: number;
  mandatory_in_location: number;
  show_leaves_details: number;
  created_at: string;
  updated_at: string;
  attachment?: string;
}

const shiftsRequestQueryKey = 'ShiftsRequest';
const getShiftsRequest = async (params: {
  page?: number;
  offset?: number;
}): Promise<ShiftRequest> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/shift_request/get`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const availableStaffsQueryKey = 'AvailableStaffs';
const getAvailableStaffs = async (params: {
  work_object_id: number;
}): Promise<AvailableStaffs[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/shift_request/get_available_staffs`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const changeShiftRequest = async (data: ChangeShiftData): Promise<ChangeShiftResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/shift_request/change`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const shiftsService = {
  shiftsRequestQueryKey,
  getShiftsRequest,
  changeShiftRequest,
  availableStaffsQueryKey,
  getAvailableStaffs,
};

export default shiftsService;
