interface LatLng {
  lat: number;
  lng: number;
}

export function getCircleRadiusPoint({ lat, lng }: LatLng, meter: number) {
  const earth = 6378.137;
  const pi = Math.PI;
  const m = 1 / (((2 * pi) / 360) * earth) / 1000;
  const newLatitude = lat + meter * m;
  return { lat: newLatitude, lng };
}

export function getRadiusInMeter(point1: LatLng, point2: LatLng) {
  const R = 6378.137;
  const dLat = (point2.lat * Math.PI) / 180 - (point1.lat * Math.PI) / 180;
  const dLon = (point2.lng * Math.PI) / 180 - (point1.lng * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((point1.lat * Math.PI) / 180) *
      Math.cos((point2.lat * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d * 1000;
}
