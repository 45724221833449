import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm, SubmitHandler, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTitle } from 'react-use';
import { Employee, EmployeeRequest } from '../../interfaces/employee-interface';
import staffsService from '../../services/staffs-service';
import toast from 'react-hot-toast';
import FormError from '../../components/form-error';
import Spinner from '../../components/spinner';
import { useJobs } from '../../hooks/use-jobs';
import { useDepartments } from '../../hooks/use-departments';
import Select from 'react-select';
import { useLeaves } from '../../hooks/use-leaves';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import {
  PlusIcon,
  EyeIcon,
  EyeSlashIcon,
  XMarkIcon,
  DocumentDuplicateIcon,
  TrashIcon,
  BellSnoozeIcon,
} from '@heroicons/react/24/outline';
import IconInfo from '~icons/mdi/information-outline';
import Tooltip from '@mui/material/Tooltip';
import { useEmployeeFormSlice } from '../../store/employee-form-slice';
import {
  endOfDay,
  format,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  millisecondsToMinutes,
  setHours,
  setMinutes,
  startOfDay,
  addMilliseconds,
  differenceInMilliseconds,
  areIntervalsOverlapping,
  isEqual,
  hoursToMilliseconds,
  millisecondsToHours,
  millisecondsInMinute,
} from 'date-fns';
import { utcDate } from '../../utils/format-date';
import { Button, TextField, useMediaQuery } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { daySorter, daysOfWeek, sortDates, generateFormDate } from '../../utils/employee-utils';
import EmployeeBreakModal, { EmployeeBreak } from './components/employee-break-modal';
import { customToastError } from '../../utils/custom-toast-error';
import Confirmation from '../../components/libs/confirmation';
import { AxiosError } from 'axios';
import { useLocations } from '../../hooks/use-locations';
import { newFormSchema, updateFormSchema } from './form-schema';
import ExtraJobs from './components/extra-job';
import AccessLevel from './components/access-level';
import { useEmployee } from '../../hooks/use-employee';
import { millisecondsToCustomHours } from '../../utils/min-to-custom-hours';

const formDefaultValues: Partial<EmployeeRequest> = {
  first_name: undefined,
  last_name: undefined,
  email: undefined,
  password: undefined,
  mobile: undefined,
  department: undefined,
  job_id: undefined,
  image: null,
  is_time_off_rollover: false,
  employment_status: 'active',
  official_start_date: undefined,
  mandatory_in_location: false,
  show_leaves_details: true,
  mandatory_clock_out_location: false,
  day_hour: '',
  hour_per_month: '',
  yearly_time_off: 0,
  monthly_time_off: 0,
  leaves: [],
  location_ids: [],
  job_ids: [],
  work_days: [],
  is_app_login_able: true,
  is_central_app_login_able: true,
};

const EmployeesCreate = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Modify Employees`);
  const matches = useMediaQuery('(min-width:1024px)');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname, search } = useLocation();
  const employeeId = searchParams.get('employeeId');
  const redirect = searchParams.get('redirect');
  const [workTimesErrors, setWorkTimesErrors] = useState<string[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isEmployeeBreakModalOpen, setIsEmployeeBreakModalOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showWeekendConfirmation, setShowWeekendConfirmation] = useState(false);
  const [clipboardWeekend, setClipboardWeekend] = useState<{ index: number; day: string } | null>(
    null,
  );
  const [isProfileImageDeleted, setIsProfileImageDeleted] = useState(false);
  const [workTimeRowIndex, setWorkTimeRowIndex] = useState<number | null>(null);
  const employeeForm = useEmployeeFormSlice((state) => state.employeeForm);
  const updateEmployeeForm = useEmployeeFormSlice((state) => state.updateEmployeeForm);
  const subDomain = useActiveWorkspaceSlice((state) => state.workspace?.sub_domain);
  const weekends = useActiveWorkspaceSlice((state) => state.workspace?.weekends);
  const { data: emp, isLoading: empIsLoading } = useEmployee(employeeId);
  const { data: departments = [] } = useDepartments();
  const { data: jobs = [] } = useJobs();
  const { data: leaves = [] } = useLeaves();
  const { data: locationsData = [] } = useLocations();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<EmployeeRequest>({
    defaultValues: formDefaultValues,
    resolver: employeeId ? yupResolver<any>(updateFormSchema) : yupResolver<any>(newFormSchema),
  });
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'work_days',
  });
  const {
    department,
    image,
    mandatory_in_location,
    mandatory_clock_out_location,
    job_ids,
    leaves: selectedLeaves,
    yearly_time_off,
    monthly_time_off,
  } = watch();
  const filteredJobs = jobs?.filter((j) => j.department_id === +department) ?? [];

  const leaveOptions =
    leaves.map((lv) => {
      return {
        value: lv.id,
        label: lv.title,
      };
    }) ?? [];

  const locationOptions =
    locationsData.map((l) => {
      return {
        value: l.id,
        label: l.title,
      };
    }) ?? [];

  useEffect(() => {
    if (emp) {
      const days: { key: string; value: Record<string, any> }[] = [];
      const workTimeObject: any =
        emp?.work_days && typeof emp?.work_days !== 'string' ? emp?.work_days : {};
      for (const key in workTimeObject) {
        if (Object.prototype.hasOwnProperty.call(workTimeObject, key)) {
          const element = workTimeObject[key];
          (element ?? [])?.forEach(
            (el: { start: string; end: string; row: number; breaks: any[] }) => {
              if (days[el.row]) {
                days[el.row].value[key] = true;
                days[el.row].value.breaks = (el.breaks ?? []).map((b: any) => {
                  const time = b.start ? b.start.split(':') : null;
                  return {
                    h: time ? time[0] : 0,
                    m: time ? time[1] : 0,
                    type: b.type,
                    total: b.total,
                  };
                });
              } else {
                const sH = el.start.split(':')[0];
                const sM = el.start.split(':')[1];
                const eH = el.end.split(':')[0];
                const eM = el.end.split(':')[1];
                const breaks: EmployeeBreak[] = (el.breaks ?? []).map((b: any) => {
                  const time = b.start ? b.start.split(':') : null;
                  return {
                    h: time ? time[0] : 0,
                    m: time ? time[1] : 0,
                    type: b.type,
                    total: b.total,
                    paid: b.paid,
                  };
                });
                days[el.row] = {
                  key,
                  value: {
                    monday: key === 'monday' ? true : false,
                    tuesday: key === 'tuesday' ? true : false,
                    wednesday: key === 'wednesday' ? true : false,
                    thursday: key === 'thursday' ? true : false,
                    friday: key === 'friday' ? true : false,
                    saturday: key === 'saturday' ? true : false,
                    sunday: key === 'sunday' ? true : false,
                    breaks,
                    start: setHours(setMinutes(startOfDay(new Date()), +sM), +sH),
                    end: setHours(setMinutes(endOfDay(new Date()), +eM), +eH),
                  },
                };
              }
            },
          );
        }
      }
      const workDays = days.sort((a: any, b: any) => {
        const day1 = a.key.toLowerCase() as any;
        const day2 = b.key.toLowerCase() as any;
        return (daySorter as any)[day1] - (daySorter as any)[day2];
      });
      if (emp) {
        const { job, childs, locations, ...rest } = emp!;
        const day = getDate(emp.official_start_date);
        const year = getYear(emp.official_start_date);
        const month = getMonth(emp.official_start_date) + 1;
        const day_hour = emp.day_hour > 0 ? millisecondsToCustomHours(emp.day_hour) : 0;
        const hour_per_month =
          emp.hour_per_month > 0 ? millisecondsToCustomHours(emp.hour_per_month) : 0;
        reset({
          ...rest,
          job_id: job.id,
          job_ids: emp.job_ids ?? [],
          department: emp.job.department_id,
          official_start_date: `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(
            2,
            '0',
          )}`,
          work_days: workDays.map((d: any) => d.value),
          leaves:
            emp.leaves.map((lv) => {
              return {
                value: lv.id,
                label: lv.title,
              };
            }) ?? [],
          location_ids:
            emp.locations
              .filter((value, index, self) => self.findIndex((v) => v.id === value.id) === index)
              .map((l) => {
                return {
                  value: l.id,
                  label: l.title,
                };
              }) ?? [],
          day_hour,
          hour_per_month,
          yearly_time_off: rest.yearly_time_off ? millisecondsToHours(rest.yearly_time_off) : 0,
          monthly_time_off: rest.monthly_time_off ? millisecondsToHours(rest.monthly_time_off) : 0,
          is_app_login_able: rest.is_app_login_able ?? true,
          is_central_app_login_able: rest.is_central_app_login_able ?? true,
        });
      }
    }
  }, [emp]);

  useEffect(() => {
    if (employeeForm && !employeeId) {
      reset(employeeForm);
    }
  }, [employeeForm]);

  // useEffect(() => {
  //   if (filteredJobs?.at(0)?.id) {
  //     setValue('job_id', filteredJobs.at(0)!.id);
  //   }
  // }, [department]);

  const {
    mutate: createEmployee,
    isLoading: createEmployeeIsLoading,
    error: createEmployeeError,
  } = useMutation(staffsService.createStaff, {
    onSuccess: (data) => {
      updateEmployeeForm(null);
      if (!locationsData.length && data.mandatory_in_location) {
        toast.error(`Location is not registered.`);
      }
      toast.success(`New Employee Created.`);
      queryClient.setQueryData([staffsService.staffsQueryKey], (old: Employee[] | undefined) => {
        return old?.length ? [...old, data] : [data];
      });
      queryClient.invalidateQueries([staffsService.staffsQueryKey]);
      queryClient.invalidateQueries([staffsService.archivedStaffsQueryKey]);
      navigate('../');
    },
    onError(error: AxiosError<any>) {
      customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
    },
  });

  const {
    mutate: updateEmployee,
    isLoading: updateEmployeeIsLoading,
    error: updateEmployeeError,
  } = useMutation(staffsService.updateStaff, {
    onSuccess: (data, variables: any) => {
      const formData = variables as FormData;
      if (!locationsData.length && data.mandatory_in_location) {
        toast.error(`Please register a location to use Location-Based Restrictions feature.`);
      }
      updateEmployeeForm(null);
      toast.success(
        emp?.email !== data.email || formData.get('password')
          ? 'We will send an email to inform your employee about updates.'
          : `Employee Updated.`,
      );
      queryClient.setQueryData([staffsService.staffsQueryKey], (old: Employee[] | undefined) => {
        return (old ?? [])?.map((emp) => {
          if (emp.id === data.id) {
            return data;
          }
          return emp;
        });
      });
      queryClient.setQueryData(
        [staffsService.archivedStaffsQueryKey],
        (old: Employee[] | undefined) => {
          return (old ?? [])?.map((emp) => {
            if (emp.id === data.id) {
              return data;
            }
            return emp;
          });
        },
      );
      queryClient.invalidateQueries([staffsService.staffsQueryKey]);
      queryClient.invalidateQueries([staffsService.archivedStaffsQueryKey]);
      queryClient.invalidateQueries([staffsService.staffQueryKey, data?.id.toString()]);
      navigate('../');
    },
    onError(error: AxiosError<any>) {
      customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
    },
  });

  const onSubmit: SubmitHandler<EmployeeRequest> = (data) => {
    if (createEmployeeIsLoading || updateEmployeeIsLoading) {
      return;
    }
    setWorkTimesErrors([]);
    const errors: string[] = [];
    let tempDays: any = {};
    const workDays: Record<string, any[]> = {};
    daysOfWeek.forEach((day) => {
      tempDays = { ...(tempDays ?? {}), [day]: [] };
    });
    (data.work_days ?? []).forEach((period: any, idx: number) => {
      let isDaySelected = false;
      let isBreakConflict = false;
      for (const key in tempDays) {
        const k = key as typeof daysOfWeek[number];
        const day = tempDays[k];
        if (period[k]) {
          let totalBreak = 0;
          (period?.breaks ?? []).forEach((b: any) => {
            totalBreak = totalBreak + b.total;
            if (b.type === 'fix') {
              const start = setHours(setMinutes(startOfDay(new Date()), b.m), b.h);
              const end = addMilliseconds(start, b.total);
              if (
                !isAfter(start, period.start) ||
                !isBefore(start, period.end) ||
                !isBefore(end, period.end)
              ) {
                isBreakConflict = true;
              }
            } else {
              const startAndDuration = addMilliseconds(period.start, b.total);
              if (!isBefore(startAndDuration, period.end)) {
                isBreakConflict = true;
              }
            }
          });
          const diff = differenceInMilliseconds(period.end, period.start);
          if (diff <= totalBreak) {
            isBreakConflict = true;
          }
          isDaySelected = true;
          tempDays = {
            ...tempDays,
            [k]: [
              ...day,
              {
                start: period.start,
                end: period.end,
                row: idx,
                breaks: (period.breaks ?? []).map((b: any) => {
                  if (b.type === 'fix') {
                    return {
                      type: b.type,
                      total: b.total,
                      paid: b.paid ?? false,
                      start: `${b.h.toString().padStart(2, '0')}:${b.m
                        .toString()
                        .padStart(2, '0')}`,
                    };
                  } else {
                    return {
                      type: b.type,
                      total: b.total,
                      paid: b.paid ?? false,
                      start: null,
                    };
                  }
                }),
              },
            ],
          };
        }
      }
      if (!isDaySelected) {
        errors.push('Please select at-least a day in each row.');
      }
      if (isBreakConflict) {
        errors.push('Break Conflict.');
      }
    });
    const daysArray = daysOfWeek.map((day) => {
      return { day, slots: sortDates(tempDays[day]) };
    });
    daysArray.forEach((day) => {
      let start: any;
      let end: any;
      if (day.slots.length) {
        workDays[day.day] = day.slots.map((val) => {
          const startText = `${getHours(val.start).toString().padStart(2, '0')}:${getMinutes(
            val.start,
          )
            .toString()
            .padStart(2, '0')}`;
          const endText = `${getHours(val.end).toString().padStart(2, '0')}:${getMinutes(val.end)
            .toString()
            .padStart(2, '0')}`;
          return { start: startText, end: endText, row: val.row, breaks: val.breaks };
        });
      }
      day.slots.forEach((date) => {
        if (start && end) {
          if (isAfter(date.start, end)) {
            start = date.start;
            end = date.end;
          } else {
            errors.push(`${day.day} has conflict on ${format(date.start, 'HH:mm')}`);
          }
        } else {
          start = date.start;
          end = date.end;
        }
      });
    });
    if (errors.length) {
      setWorkTimesErrors(errors);
      return;
    }
    const official_start_date = data?.official_start_date
      ? utcDate(data.official_start_date).getTime()
      : '';
    const modifiedData: any = {
      ...data,
      job_ids: data.job_ids.includes(data.job_id) ? data.job_ids : [...data.job_ids, data.job_id],
      mobile: data.mobile ? data.mobile : '',
      work_days: workDays,
      official_start_date,
      mandatory_in_location: data.mandatory_in_location ? 1 : 0,
      show_leaves_details: data.show_leaves_details ? 1 : 0,
      leaves: data.leaves.map((l: any) => l.value),
      location_ids: data.location_ids.map((l: any) => l.value),
      day_hour: hoursToMilliseconds(+data.day_hour),
      hour_per_month: hoursToMilliseconds(+data.hour_per_month),
      yearly_time_off: hoursToMilliseconds(data.yearly_time_off),
      monthly_time_off: hoursToMilliseconds(data.monthly_time_off),
    };
    if (emp) {
      const obj = {
        ...modifiedData,
        ...(data.image.length
          ? { image: data.image }
          : emp?.attachment && isProfileImageDeleted
          ? { attachment: null, image: null }
          : {}),
        staff_id: emp.id,
      };
      const formData = generateFormDate(obj);
      updateEmployee(formData);
    } else {
      const formData = generateFormDate(modifiedData);
      createEmployee(formData);
    }
  };

  const handleResetForm = () => {
    updateEmployeeForm(null);
    navigate('../');
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRemoveImage = () => {
    setValue('image', null);
  };

  const handlePersistForm = () => {
    updateEmployeeForm(watch());
  };

  const handleAppendWorkTime = () => {
    append({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      breaks: [],
      start: startOfDay(new Date()),
      end: endOfDay(new Date()),
    });
  };

  const handleDeleteWorkTime = (index: number) => {
    remove(index);
  };

  const handleCopyWorkTime = (index: number) => {
    const { work_days } = watch();
    const item = work_days.find((_: any, i: number) => i === index);
    append(item!);
  };

  const handleUpdateWorkTime = (index: number, day: typeof daysOfWeek[number]) => {
    const isWeekend = weekends?.[day];
    if (isWeekend) {
      setClipboardWeekend({ index, day });
      handleOpenWeekendConfirmation();
    } else {
      const { work_days } = watch();
      const item = work_days.find((_: any, i: number) => i === index);
      if (item) {
        update(index, { ...item, [day]: !item[day] });
      }
    }
  };

  const handleOpenEmployeeBreakModal = (index: number) => {
    const row: Record<string, boolean> = fields[index] as any;
    let allowAddBreak = false;
    daysOfWeek.forEach((day) => {
      if (row[day]) {
        allowAddBreak = true;
      }
    });
    if (allowAddBreak) {
      setWorkTimeRowIndex(index);
      setIsEmployeeBreakModalOpen(true);
    } else {
      customToastError('Error', 'Please select at-least a day in each row.');
    }
  };

  const handleCloseEmployeeBreakModal = (data: EmployeeBreak | null) => {
    if (data && workTimeRowIndex !== null) {
      const { work_days } = watch();
      const row = work_days.find((_: any, i: number) => i === workTimeRowIndex);
      let totalBreak = data.total;
      let hasOverlap = false;
      (row?.breaks ?? []).forEach((b: any) => {
        totalBreak = totalBreak + b.total;
        if (b.type === 'fix' && data.type === 'fix' && !hasOverlap) {
          const s = setHours(setMinutes(startOfDay(new Date()), +b.m), +b.h);
          const e = addMilliseconds(s, b.total);
          const newBreakS = setHours(setMinutes(startOfDay(new Date()), data.m), +data.h);
          const newBreakE = addMilliseconds(s, +data.total);
          hasOverlap = areIntervalsOverlapping(
            { start: s, end: e },
            { start: newBreakS, end: newBreakE },
          );
        }
      });
      const diff = differenceInMilliseconds(row.end, row.start);
      if (hasOverlap) {
        customToastError('Error', 'Breaks overlap');
        setWorkTimeRowIndex(null);
        setIsEmployeeBreakModalOpen(false);
        return;
      }
      if (diff <= totalBreak) {
        customToastError('Error', 'Breaks exceed working time');
        setWorkTimeRowIndex(null);
        setIsEmployeeBreakModalOpen(false);
        return;
      }
      if (data.type === 'fix') {
        const start = setHours(setMinutes(startOfDay(new Date()), data.m), data.h);
        const end = addMilliseconds(start, data.total);
        if (
          (isAfter(start, row.start) || isEqual(start, row.start)) &&
          isBefore(start, row.end) &&
          (isBefore(end, row.end) || isEqual(end, row.end))
        ) {
          update(workTimeRowIndex, { ...row, breaks: [...(row?.breaks ?? []), data] });
        } else {
          customToastError('Error', 'Time Conflict');
        }
      } else {
        const isThereAnyFloatBreak = (row.breaks ?? []).find((b: any) => b.type === 'float');
        if (isThereAnyFloatBreak) {
          customToastError('Error', "You can't add multiple float breaks.");
        } else {
          const startAndDuration = addMilliseconds(row.start, data.total);
          if (isBefore(startAndDuration, row.end)) {
            update(workTimeRowIndex, { ...row, breaks: [...(row?.breaks ?? []), data] });
          } else {
            customToastError('Error', 'Time Conflict');
          }
        }
      }
    }
    setWorkTimeRowIndex(null);
    setIsEmployeeBreakModalOpen(false);
  };

  const handleDeleteBreak = (index: number, idx: number) => {
    const { work_days } = watch();
    const row = work_days.find((_: any, i: number) => i === index);
    update(index, { ...row, breaks: (row?.breaks ?? []).filter((b: any, i: number) => i !== idx) });
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleOpenWeekendConfirmation = () => {
    setShowWeekendConfirmation(true);
  };

  const handleCloseWeekendConfirmation = () => {
    setShowWeekendConfirmation(false);
  };

  const handleAddWeekend = (bool: boolean) => {
    if (bool && clipboardWeekend) {
      const { work_days } = watch();
      const item = work_days.find((_: any, i: number) => i === clipboardWeekend.index);
      if (item) {
        update(clipboardWeekend?.index, {
          ...item,
          [clipboardWeekend.day]: !item[clipboardWeekend.day],
        });
      }
    }
    setClipboardWeekend(null);
  };

  const handleDeleteProfileImage = (bool: boolean) => {
    if (bool) {
      setIsProfileImageDeleted(true);
    }
  };

  const handleExtraJobsChange = (jobIds: number[]) => {
    setValue('job_ids', jobIds);
  };

  const handleStaffIdsChange = (newStaffIds: number[]) => {
    setValue('staff_ids', newStaffIds);
  };

  if (employeeId && empIsLoading) {
    return (
      <div className="flex items-end justify-center w-full my-9">
        <span className="mr-4">Please wait</span>
        <Spinner size="small" />
      </div>
    );
  }

  return (
    <>
      <div className="w-full mx-auto space-y-8 pb-14">
        <div className="flex flex-col space-y-4 md:space-y-4 md:items-center md:justify-between !mt-4 md:!mt-0">
          {redirect ? (
            <Link
              to={`/${subDomain}/${redirect}`}
              type="button"
              className="flex items-center justify-center w-full text-sm font-medium text-white transition duration-200 rounded shadow md:w-40 bg-brand-primary hover:bg-brand-primary-dark h-11"
            >
              Back
            </Link>
          ) : null}
          <Link
            to="../"
            type="button"
            className="flex items-center justify-center w-full ml-auto text-sm font-medium text-white transition duration-200 rounded shadow md:w-40 bg-brand-primary hover:bg-brand-primary-dark h-11"
          >
            Employees
          </Link>
        </div>
        <div className="flex flex-col items-center justify-center w-full p-4 bg-white border border-gray-100 rounded-md shadow-lg md:p-9">
          <div className="flex flex-col w-full font-medium border-b pb-9">
            {employeeId ? <span>Edit Employee</span> : <span>New Employee</span>}
            <p className="mt-2 text-sm font-normal text-gray-400">
              You can add one or more employees to your Workspace. Just provide us with basic
              information. We will send them an email with login data and a link to download the
              app.
              <br />
              You can also download our tool and enjoy it on your phone.
              <span className="inline-block ml-2 space-x-2">
                <a
                  href="#"
                  className="font-medium text-blue-500 underline transition underline-offset-2 hover:text-blue-700"
                >
                  Android
                </a>
                <a
                  href="#"
                  className="font-medium text-blue-500 underline transition underline-offset-2 hover:text-blue-700"
                >
                  iOS
                </a>
              </span>
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6" autoComplete="off">
            <div className="space-y-8 divide-y divide-gray-200">
              <div className="py-8">
                <div className="grid grid-cols-1 md:m-0 md:mt-6 gap-y-2 gap-x-4 sm:grid-cols-6">
                  {/* FirstName */}
                  <div className="sm:col-span-3">
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                      First Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="first-name"
                        {...register('first_name')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.first_name?.message}
                    </p>
                  </div>
                  {/* Department */}
                  <div className="sm:col-span-3">
                    <div className="flex items-center">
                      <label
                        htmlFor="department"
                        className="flex items-center flex-1 text-sm font-medium text-gray-700"
                      >
                        Department
                        <Tooltip title="To add New Departments, press +" placement="top">
                          <span>
                            <IconInfo className="w-6 h-6 ml-2 text-gray-500" />
                          </span>
                        </Tooltip>
                      </label>
                      <Link
                        to={`../../../settings/department?employeePath=${pathname}${search}`}
                        onClick={handlePersistForm}
                        className="flex items-center justify-center w-6 h-6 ml-auto bg-blue-400 rounded-full"
                      >
                        <PlusIcon className="w-4 h-4 text-white" />
                      </Link>
                    </div>
                    <div className="mt-2">
                      <select
                        id="department"
                        defaultValue="-1"
                        {...register('department')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option disabled value="-1">
                          Select a department
                        </option>
                        {departments?.map((dep) => (
                          <option key={dep.id} value={dep.id}>
                            {dep.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.department?.message}
                    </p>
                  </div>
                  {/* LastName */}
                  <div className="sm:col-span-3">
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                      Last Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="last-name"
                        {...register('last_name')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.last_name?.message}
                    </p>
                  </div>
                  {/* Job Title */}
                  <div className="sm:col-span-3">
                    <div className="flex">
                      <label
                        htmlFor="job"
                        className="flex items-center text-sm font-medium text-gray-700"
                      >
                        Job Title
                        <Tooltip title="To add New Job Titles, press +" placement="top">
                          <span>
                            <IconInfo className="w-6 h-6 ml-2 text-gray-500" />
                          </span>
                        </Tooltip>
                      </label>
                      <Link
                        to={`../../../settings/job-title?employeePath=${pathname}${search}`}
                        onClick={handlePersistForm}
                        className="flex items-center justify-center w-6 h-6 ml-auto bg-blue-400 rounded-full"
                      >
                        <PlusIcon className="w-4 h-4 text-white" />
                      </Link>
                    </div>
                    <div className="mt-2">
                      <select
                        id="job"
                        defaultValue="-1"
                        {...register('job_id')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option disabled value="-1">
                          Select a job
                        </option>
                        {filteredJobs?.map((j) => (
                          <option key={j.id} value={j.id}>
                            {j.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.job_id?.message}
                    </p>
                  </div>
                  {/* Email */}
                  <div className="sm:col-span-3">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email Address
                    </label>
                    <div className="mt-2">
                      <input
                        type="email"
                        id="email"
                        {...register('email')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.email?.message}
                    </p>
                  </div>
                  {/* Employment Status */}
                  <div className="sm:col-span-3">
                    <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                      Employment Status
                    </label>
                    <div className="mt-2">
                      <select
                        id="status"
                        defaultValue=""
                        {...register('employment_status')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option disabled value="">
                          Select a Status
                        </option>
                        <option value="active">Active</option>
                        <option value="archived">Archived</option>
                      </select>
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.employment_status?.message}
                    </p>
                  </div>
                  {/* Password */}
                  <div className="sm:col-span-3">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password {employeeId && '(You can skip this one)'}
                    </label>
                    <div className="relative mt-2">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        {...register('password')}
                        autoComplete="new-password"
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      <button
                        type="button"
                        className="absolute top-[6px] right-4"
                        onClick={handleShowPassword}
                      >
                        {showPassword ? (
                          <EyeIcon className="w-6 h-6 text-gray-500 transition hover:text-gray-800" />
                        ) : (
                          <EyeSlashIcon className="w-6 h-6 text-gray-500 transition hover:text-gray-800" />
                        )}
                      </button>
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.password?.message}
                    </p>
                  </div>
                  {/* Official date */}
                  <div className="sm:col-span-3">
                    <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">
                      Official Start Date
                    </label>
                    <div className="mt-2">
                      <input
                        type="date"
                        id="start-date"
                        {...register('official_start_date')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {(errors.official_start_date as any)?.message}
                    </p>
                  </div>
                  {/* Mobile */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="mobile-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Mobile Number
                    </label>
                    <div className="mt-2">
                      <input
                        type="tel"
                        id="mobile-number"
                        {...register('mobile')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.mobile?.message}
                    </p>
                  </div>
                  {/* Time off types */}
                  <div className="sm:col-span-3">
                    <div className="flex items-center">
                      <label
                        htmlFor="staffs"
                        className="flex items-center text-sm font-medium text-gray-700"
                      >
                        Time off Types
                        <Tooltip title="To add New Leave Types, press +" placement="top">
                          <span>
                            <IconInfo className="w-6 h-6 ml-2 text-gray-500" />
                          </span>
                        </Tooltip>
                      </label>
                      <Link
                        to={`../../../settings/time-off?employeePath=${pathname}${search}`}
                        onClick={handlePersistForm}
                        className="flex items-center justify-center w-6 h-6 ml-auto bg-blue-400 rounded-full shrink-0"
                      >
                        <PlusIcon className="w-4 h-4 text-white" />
                      </Link>
                    </div>
                    <div className="mt-2">
                      <Controller
                        name="leaves"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            isDisabled={yearly_time_off >= 1 || monthly_time_off >= 1}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            options={leaveOptions}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                  {/* Monthly Time Off */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="monthly_time_off"
                      className="flex items-center text-sm font-medium text-gray-700"
                    >
                      Monthly Time Off (Hours)
                      <Tooltip
                        title="It allows you to manually add specific time off for one month, disabling Leave Types field."
                        placement="top"
                      >
                        <span>
                          <IconInfo className="w-6 h-6 ml-2 text-gray-500" />
                        </span>
                      </Tooltip>
                    </label>
                    <div className="mt-2">
                      <input
                        disabled={selectedLeaves?.length}
                        type="number"
                        id="monthly_time_off"
                        {...register('monthly_time_off')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-200 rounded-md shadow-sm appearance-none disabled:text-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {(errors as any).monthly_time_off?.message}
                    </p>
                  </div>
                  {/* Yearly Time Off */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="yearly_time_off"
                      className="flex items-center text-sm font-medium text-gray-700"
                    >
                      Yearly Time Off (Hours)
                      <Tooltip
                        title="It allows you to manually add specific time off for one year, disabling Leave Types field."
                        placement="top"
                      >
                        <span>
                          <IconInfo className="w-6 h-6 ml-2 text-gray-500" />
                        </span>
                      </Tooltip>
                    </label>
                    <div className="mt-2">
                      <input
                        disabled={selectedLeaves?.length}
                        type="number"
                        id="yearly_time_off"
                        {...register('yearly_time_off')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-200 rounded-md shadow-sm appearance-none disabled:text-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {(errors as any).yearly_time_off?.message}
                    </p>
                  </div>
                  {/* image */}
                  <div
                    className={`${
                      emp?.attachment && !isProfileImageDeleted ? 'sm:col-span-3' : 'sm:col-span-6'
                    }`}
                  >
                    <label
                      htmlFor="profile-image"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Upload Profile Photo (Optional)
                    </label>
                    <div className="flex items-center justify-between mt-2">
                      <input
                        type="file"
                        id="profile-image"
                        {...register('image')}
                        className={`flex flex-1 mr-2 px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                          image?.length ? 'mr-2' : ''
                        }`}
                      />
                      {image?.length ? (
                        <button
                          type="button"
                          onClick={handleRemoveImage}
                          className="flex items-center justify-center h-8 bg-red-500 rounded w-9"
                        >
                          <XMarkIcon className="w-5 h-5 text-white" />
                        </button>
                      ) : null}
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {(errors as any).image?.message}
                    </p>
                  </div>
                  {/* Current image */}
                  {emp?.attachment && !isProfileImageDeleted ? (
                    <div className={`flex items-center space-x-4 sm:col-span-3`}>
                      <div className="flex items-center justify-between">
                        <img
                          className="object-cover w-16 h-16 rounded shrink-0 grow-0"
                          src={emp.attachment}
                          alt={emp.first_name}
                        />
                      </div>
                      <button
                        type="button"
                        onClick={handleOpenConfirmation}
                        className="w-16 h-16 text-sm font-medium text-white bg-red-600 border border-transparent rounded shadow-sm hover:bg-red-700"
                      >
                        Delete
                      </button>
                    </div>
                  ) : null}
                  {/* Working Hours Per Day */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="day_hour"
                      className="flex items-center text-sm font-medium text-gray-700"
                    >
                      Working Hours Per Day (etc, 6, 8.5, 12.75)
                      <Tooltip
                        title="This represents a full day's duration for the staff in our day-based reports. "
                        placement="top"
                      >
                        <span>
                          <IconInfo className="w-6 h-6 ml-2 text-gray-500" />
                        </span>
                      </Tooltip>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="day_hour"
                        {...register('day_hour')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {(errors as any).day_hour?.message}
                    </p>
                  </div>
                  {/* Working Hours Per Month */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="hour-per-month"
                      className="flex items-center text-sm font-medium text-gray-700"
                    >
                      Working Hours Per Month (etc, 6, 8.5, 12.75)
                      <Tooltip
                        title="This represents a full day's duration for the staff in our day-based reports. "
                        placement="top"
                      >
                        <span>
                          <IconInfo className="w-6 h-6 ml-2 text-gray-500" />
                        </span>
                      </Tooltip>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="hour-per-month"
                        {...register('hour_per_month')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {(errors as any).hour_per_month?.message}
                    </p>
                  </div>
                  {/* Time off rollover boolean */}
                  <div className="sm:col-span-6">
                    <div className="flex flex-col items-center">
                      <div className="flex items-center w-full mt-2">
                        <input
                          type="checkbox"
                          id="is_time_off_rollover"
                          {...register('is_time_off_rollover')}
                          className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                        />
                        <div className="flex flex-col ml-4">
                          <label
                            htmlFor="is_time_off_rollover"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Time Off Rollover
                          </label>
                          <p id="candidates-description" className="text-sm text-gray-500">
                            This feature allows employees to carry over any unused time off hours
                            from one year to the next.
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.mandatory_in_location?.message}
                    </p>
                  </div>
                  {/* Time off boolean */}
                  <div className="sm:col-span-6">
                    <div className="flex flex-col items-center">
                      <div className="flex items-center w-full mt-2">
                        <input
                          type="checkbox"
                          id="leave-details"
                          {...register('show_leaves_details')}
                          className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                        />
                        <div className="flex flex-col ml-4">
                          <label
                            htmlFor="leave-details"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Show Time Off Details
                          </label>
                          <p id="candidates-description" className="text-sm text-gray-500">
                            This allows staff to view leave details through the app
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.mandatory_in_location?.message}
                    </p>
                  </div>
                  {/* Mandatory clock in location boolean */}
                  <div className="sm:col-span-6">
                    <div className="flex flex-col items-center">
                      <div className="flex items-center w-full mt-2">
                        <input
                          type="checkbox"
                          id="mandatory"
                          {...register('mandatory_in_location')}
                          className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                        />
                        <div className="flex flex-col ml-4">
                          <label
                            htmlFor="mandatory"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Location-Based Clock-in
                          </label>
                          <p id="candidates-description" className="text-sm text-gray-500">
                            If this is checked, employees must be in a certain location to clock in.
                            It will be active only after the zone is set.
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.mandatory_in_location?.message}
                    </p>
                  </div>
                  {/* Mandatory clock out location boolean */}
                  <div className="sm:col-span-6">
                    <div className="flex flex-col items-center">
                      <div className="flex items-center w-full mt-2">
                        <input
                          type="checkbox"
                          id="mandatory-clock-out"
                          {...register('mandatory_clock_out_location')}
                          className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                        />
                        <div className="flex flex-col ml-4">
                          <label
                            htmlFor="mandatory-clock-out"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Location-Based Clock-out
                          </label>
                          <p id="candidates-description" className="text-sm text-gray-500">
                            If this is checked, employees must be in a certain location to clock
                            out. It will be active only after the zone is set.
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.mandatory_in_location?.message}
                    </p>
                  </div>
                  {/* App Login boolean */}
                  <div className="sm:col-span-6">
                    <div className="flex flex-col items-center">
                      <div className="flex items-center w-full mt-2">
                        <input
                          type="checkbox"
                          id="is_app_login_able"
                          {...register('is_app_login_able')}
                          className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                        />
                        <div className="flex flex-col ml-4">
                          <label
                            htmlFor="is_app_login_able"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Mobile App Login
                          </label>
                          <p id="candidates-description" className="text-sm text-gray-500">
                            Staff members can use the app on their mobile devices.
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.mandatory_in_location?.message}
                    </p>
                  </div>
                  {/* Terminal Login boolean */}
                  <div className="sm:col-span-6">
                    <div className="flex flex-col items-center">
                      <div className="flex items-center w-full mt-2">
                        <input
                          type="checkbox"
                          id="is_central_app_login_able"
                          {...register('is_central_app_login_able')}
                          className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                        />
                        <div className="flex flex-col ml-4">
                          <label
                            htmlFor="is_central_app_login_able"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Central Terminal Login
                          </label>
                          <p id="candidates-description" className="text-sm text-gray-500">
                            Staff members need to use a single device located in the office.
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                      {errors.mandatory_in_location?.message}
                    </p>
                  </div>
                  {/* Staff Locations */}
                  {mandatory_in_location || mandatory_clock_out_location ? (
                    <div className="mb-6 sm:col-span-6">
                      <div className="flex items-center">
                        <label
                          htmlFor="staff-locations"
                          className="flex items-center text-sm font-medium text-gray-700"
                        >
                          Locations
                        </label>
                      </div>
                      <div className="mt-2">
                        <Controller
                          name="location_ids"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              isMulti={true}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              options={locationOptions}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* Access level */}
                  <div className="flex flex-col space-y-4 sm:col-span-2">
                    <AccessLevel
                      employeeId={employeeId}
                      handleStaffIdsChange={handleStaffIdsChange}
                    />
                  </div>
                  {/* Extra Jobs */}
                  <div className="flex flex-col space-y-4 sm:col-span-2">
                    <ExtraJobs jobIds={job_ids} handleExtraJobsChange={handleExtraJobsChange} />
                  </div>
                  {/* Schedule time */}
                  <div className="flex flex-col space-y-4 sm:col-span-2">
                    <label htmlFor="staffs" className="block text-sm font-medium text-gray-700">
                      Schedule Time
                    </label>
                    <div className="">
                      <button
                        type="button"
                        onClick={handleAppendWorkTime}
                        className="w-40 h-10 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded shadow-sm hover:bg-indigo-700"
                      >
                        Add New Time
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Schedule rows */}
            {(fields ?? []).map((item: any, index) => (
              <div key={item.id} className="flex flex-col w-full">
                <div className="flex flex-col w-full mb-4 space-y-4 lg:mb-0 lg:flex-row lg:space-y-0 lg:justify-between">
                  <div className="flex mb-6 space-x-2 lg:mb-0">
                    {daysOfWeek.map((day) => {
                      const isWeekend = weekends?.[day];
                      const name = day.substring(0, 3);
                      const variant = item[day] ? 'contained' : 'outlined';
                      return (
                        <Button
                          key={day}
                          onClick={() => handleUpdateWorkTime(index, day)}
                          type="button"
                          variant={variant}
                          color={isWeekend ? 'error' : 'primary'}
                          sx={{
                            minWidth: matches ? '48px' : '32px',
                            width: matches ? '48px' : '32px',
                            height: matches ? '48px' : '32px',
                            padding: '0',
                            fontSize: matches ? '12px' : '10px',
                          }}
                        >
                          {name}
                        </Button>
                      );
                    })}
                  </div>
                  <div className="flex w-64 pt-1 space-x-2">
                    <Controller
                      name={`work_days.${index}.start`}
                      control={control}
                      render={({ field }) => {
                        const err = (errors as any)?.work_days?.[index]?.start;
                        return (
                          <TimePicker
                            {...field}
                            label="Start"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth={true}
                                error={!!err}
                                size="small"
                                helperText={(err as any)?.message ?? ' '}
                              />
                            )}
                          />
                        );
                      }}
                    />
                    <Controller
                      name={`work_days.${index}.end`}
                      control={control}
                      render={({ field }) => {
                        const err = (errors as any)?.work_days?.[index]?.end;
                        return (
                          <TimePicker
                            {...field}
                            label="Closed"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth={true}
                                error={!!err}
                                size="small"
                                helperText={(err as any)?.message ?? ' '}
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex items-center space-x-2 self-baseline">
                    <Button
                      onClick={() => handleOpenEmployeeBreakModal(index)}
                      variant="contained"
                      type="button"
                      color="primary"
                      sx={{
                        minWidth: '46px',
                        width: '46px',
                        height: '46px',
                        padding: 0,
                      }}
                    >
                      <BellSnoozeIcon className="w-6 h-6" />
                    </Button>
                    <Button
                      onClick={() => handleCopyWorkTime(index)}
                      variant="contained"
                      type="button"
                      color="primary"
                      sx={{
                        minWidth: '46px',
                        width: '46px',
                        height: '46px',
                        padding: 0,
                      }}
                    >
                      <DocumentDuplicateIcon className="w-6 h-6" />
                    </Button>
                    <Button
                      onClick={() => handleDeleteWorkTime(index)}
                      variant="contained"
                      type="button"
                      color="error"
                      sx={{
                        minWidth: '46px',
                        width: '46px',
                        height: '46px',
                        padding: 0,
                      }}
                    >
                      <TrashIcon className="w-6 h-6" />
                    </Button>
                  </div>
                </div>
                {(item.breaks ?? []).map((b: any, idx: number) => {
                  const min = millisecondsToMinutes(b.total ?? 0);
                  return (
                    <div
                      key={`${index}-${idx}-${b.type}`}
                      className="flex items-center w-full h-12 pl-4 mb-2 space-x-1 text-xs bg-gray-100 rounded lg:space-x-4 lg:text-base last:mb-0"
                    >
                      <span className="hidden lg:inline-block">Break Details:</span>
                      <span className="hidden lg:inline-block"></span>
                      <span className="capitalize">Type: {b.type}</span>
                      {b.type === 'fix' ? <span className="hidden lg:inline-block">-</span> : null}
                      {b.type === 'fix' ? (
                        <span>
                          Start at: {b.h.toString().padStart(2, 0)}:{b.m.toString().padStart(2, 0)}
                        </span>
                      ) : null}
                      <span className="hidden lg:inline-block">-</span>
                      <span>Duration: {min <= 1 ? `${min} Min` : `${min} Mins`}</span>
                      <div className="!ml-auto">
                        <Button
                          onClick={() => handleDeleteBreak(index, idx)}
                          variant="contained"
                          type="button"
                          color="error"
                          sx={{
                            minWidth: '42px',
                            width: '42px',
                            height: '42px',
                            padding: 0,
                          }}
                        >
                          <TrashIcon className="w-6 h-6" />
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
            <div className="flex flex-col w-full space-y-4 border-t md:items-center md:justify-between md:space-y-0 md:space-x-4 pt-9">
              <div className="flex flex-col">
                {createEmployeeError &&
                typeof (createEmployeeError as any)?.response?.data?.message === 'string' ? (
                  <FormError
                    error={(createEmployeeError as any)?.response?.data?.message}
                    takeSpace={true}
                    textAlign="left"
                  />
                ) : null}
                {createEmployeeError &&
                (createEmployeeError as any)?.response?.data?.errors &&
                typeof (createEmployeeError as any)?.response?.data?.message !== 'string'
                  ? Object.entries((createEmployeeError as any)?.response?.data?.errors).map(
                      ([key, val], i) => {
                        return (
                          <FormError
                            key={i}
                            error={(val as Array<string>)[0]}
                            takeSpace={true}
                            textAlign="left"
                          />
                        );
                      },
                    )
                  : null}
                {updateEmployeeError &&
                typeof (updateEmployeeError as any)?.response?.data?.message === 'string' ? (
                  <FormError
                    error={(updateEmployeeError as any)?.response?.data?.message}
                    takeSpace={true}
                    textAlign="left"
                  />
                ) : null}
                {updateEmployeeError &&
                (updateEmployeeError as any)?.response?.data?.errors &&
                typeof (updateEmployeeError as any)?.response?.data?.message !== 'string'
                  ? Object.entries((updateEmployeeError as any)?.response?.data?.errors).map(
                      ([key, val], i) => {
                        return (
                          <FormError
                            key={i}
                            error={(val as Array<string>)[0]}
                            takeSpace={true}
                            textAlign="left"
                          />
                        );
                      },
                    )
                  : null}
                {workTimesErrors ? <FormError error={workTimesErrors} textAlign="left" /> : null}
              </div>
              <div className="flex items-center justify-between w-full md:justify-end self-baseline">
                <button
                  type="button"
                  disabled={createEmployeeIsLoading || updateEmployeeIsLoading}
                  className="flex justify-center py-3 text-sm font-medium text-white bg-red-500 border border-transparent rounded shadow-sm w-36 md:w-40 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleResetForm}
                >
                  Cancel
                </button>
                {emp ? (
                  <button
                    type="submit"
                    disabled={updateEmployeeIsLoading}
                    className="flex justify-center py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded shadow-sm w-36 md:w-40 ml-9 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {updateEmployeeIsLoading ? <Spinner size="small" /> : 'Update'}
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={createEmployeeIsLoading}
                    className="flex justify-center py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded shadow-sm w-36 md:w-40 ml-9 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {createEmployeeIsLoading ? <Spinner size="small" /> : 'Save'}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <EmployeeBreakModal open={isEmployeeBreakModalOpen} close={handleCloseEmployeeBreakModal} />
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleDeleteProfileImage}
      />
      <Confirmation
        show={showWeekendConfirmation}
        closeModal={handleCloseWeekendConfirmation}
        confirm={handleAddWeekend}
      />
    </>
  );
};

export default EmployeesCreate;
