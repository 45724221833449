import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LockIcon from '~icons/mdi/lock-outline';
import PageNextIcon from '~icons/mdi/page-next-outline';
import LockOpenIcon from '~icons/mdi/lock-open-variant-outline';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  differenceInMinutes,
  endOfDay,
  isAfter,
  millisecondsToMinutes,
  startOfDay,
  subDays,
} from 'date-fns';
import Spinner from '../../components/spinner';
import PrevPage from '../../components/ui/prev-page';
import { ClockInReportsData, StaffClockInReport } from '../../interfaces/clockin-reports-interface';
import { utcDate } from '../../utils/format-date';
import { useEmployees } from '../../hooks/use-employees';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';
import UnscheduleClockModal from './components/unschedule-clock-modal';
import { useStaffClockInReports } from '../../hooks/use-staff-clock-in-reports';
import { Tooltip } from '@mui/material';
import { convertMToHMWithLabel, timeWithLabel } from '../../utils/format-minutes';

const getAllReportData = (data: ClockInReportsData | undefined) => {
  const acceptedTotal = { workObjects: 0, clocks: 0 };
  const pendingTotal = { workObjects: 0, clocks: 0 };
  const rejectedTotal = { workObjects: 0, clocks: 0 };
  const breaksTotal = { workObjects: 0, clocks: 0 };
  if (!data) return { acceptedTotal, pendingTotal, rejectedTotal, breaksTotal };
  for (const key in data) {
    const element = data[key];
    acceptedTotal.workObjects += element.report.acceptedTotal.workObjects;
    acceptedTotal.clocks += element.report.acceptedTotal.clocks;
    pendingTotal.workObjects += element.report.pendingTotal.workObjects;
    pendingTotal.clocks += element.report.pendingTotal.clocks;
    rejectedTotal.workObjects += element.report.rejectedTotal.workObjects;
    rejectedTotal.clocks += element.report.rejectedTotal.clocks;
    breaksTotal.workObjects += element.report.breaksTotal.workObjectBreaks;
    breaksTotal.clocks += element.report.breaksTotal.clockBreaks;
  }
  return { acceptedTotal, pendingTotal, rejectedTotal, breaksTotal };
};

const AttendanceDetails = () => {
  const { attendanceId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const startParam = searchParams.get('start');
  const endParam = searchParams.get('end');
  const [isUnscheduleClockModalOpen, setIsUnscheduleClockModalOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date>(
    startParam ? new Date(+startParam) : subDays(new Date(), 30),
  );
  const [endDate, setEndDate] = useState<Date>(
    endParam ? new Date(+endParam) : endOfDay(new Date()),
  );
  const { data: employees = [] } = useEmployees();
  const emp = employees.find((e) => e.id === +(attendanceId ?? -1));
  const from = startDate ? utcDate(startDate).getTime() : null;
  const to = endDate ? utcDate(endDate).getTime() : null;
  const staffClockInReports = useStaffClockInReports(attendanceId, from, to);
  const schedules = getAllReportData(staffClockInReports.data);

  const handleClockIn = () => {
    setIsUnscheduleClockModalOpen(true);
  };

  const closeUnScheduledClockInsModal = () => {
    setIsUnscheduleClockModalOpen(false);
  };

  const handleSchedulesInMoreDetails = (date: string) => {
    navigate(`./${from}/${to}/${date}`);
  };

  if (!emp) {
    return (
      <div className="flex items-end justify-center w-full my-9">
        <span className="mr-4">Please wait</span>
        <Spinner size="small" />
      </div>
    );
  }

  return (
    <div className="w-full mx-auto space-y-2 pb-14">
      <div className="flex justify-between !mt-8 md:!mt-0">
        <PrevPage to="../attendance" />
        <button
          type="button"
          className="w-40 text-sm font-medium text-white transition duration-200 rounded shadow bg-brand-primary hover:bg-brand-primary-dark h-11"
          onClick={handleClockIn}
        >
          Add Clock-in
        </button>
      </div>
      <div className="grid grid-cols-12 gap-2 md:gap-6">
        <div className="flex flex-col col-span-12 p-6 text-xs bg-white border border-gray-100 divide-y divide-gray-100 rounded-md shadow-lg md:col-span-6 3xl:col-span-3">
          <h6 className="flex items-center justify-between h-10 font-medium text-gray-500 uppercase rounded">
            <span>Accepted</span>
            <span className="w-2 h-2 bg-green-500 rounded-full"></span>
          </h6>
          <div className="flex items-center justify-between h-8">
            <span className="">Total</span>
            <span className="font-medium">
              {convertMToHMWithLabel(
                millisecondsToMinutes(schedules.acceptedTotal?.workObjects ?? 0),
              )}
            </span>
          </div>
          <div className="flex items-center justify-between h-8">
            <span className="">Worked</span>
            <span className="font-medium">
              {convertMToHMWithLabel(millisecondsToMinutes(schedules.acceptedTotal?.clocks ?? 0))}
            </span>
          </div>
        </div>
        <div className="flex flex-col col-span-12 p-6 text-xs bg-white border border-gray-100 divide-y divide-gray-100 rounded-md shadow-lg md:col-span-6 3xl:col-span-3">
          <h6 className="flex items-center justify-between h-10 font-medium text-gray-500 uppercase rounded">
            <span>Pending</span>
            <span className="w-2 h-2 bg-yellow-300 rounded-full"></span>
          </h6>
          <div className="flex items-center justify-between h-8">
            <span className="">Total</span>
            <span className="font-medium">
              {convertMToHMWithLabel(
                millisecondsToMinutes(schedules.pendingTotal?.workObjects ?? 0),
              )}
            </span>
          </div>
          <div className="flex items-center justify-between h-8">
            <span className="">Worked</span>
            <span className="font-medium">
              {convertMToHMWithLabel(millisecondsToMinutes(schedules.pendingTotal?.clocks ?? 0))}
            </span>
          </div>
        </div>
        <div className="flex flex-col col-span-12 p-6 text-xs bg-white border border-gray-100 divide-y divide-gray-100 rounded-md shadow-lg md:col-span-6 3xl:col-span-3">
          <h6 className="flex items-center justify-between h-10 font-medium text-gray-500 uppercase rounded">
            <span>Rejected</span>
            <span className="w-2 h-2 bg-red-500 rounded-full"></span>
          </h6>
          <div className="flex items-center justify-between h-8">
            <span className="">Total</span>
            <span className="font-medium">
              {convertMToHMWithLabel(
                millisecondsToMinutes(schedules.rejectedTotal?.workObjects ?? 0),
              )}
            </span>
          </div>
          <div className="flex items-center justify-between h-8">
            <span className="">Worked</span>
            <span className="font-medium">
              {convertMToHMWithLabel(millisecondsToMinutes(schedules.rejectedTotal?.clocks ?? 0))}
            </span>
          </div>
        </div>
        <div className="flex flex-col col-span-12 p-6 text-xs bg-white border border-gray-100 divide-y divide-gray-100 rounded-md shadow-lg md:col-span-6 3xl:col-span-3">
          <h6 className="flex items-center justify-between h-10 font-medium text-gray-500 uppercase rounded">
            <span>Breaks</span>
            <span className="w-2 h-2 bg-yellow-500 rounded-full"></span>
          </h6>
          <div className="flex items-center justify-between h-8">
            <span className="">Total</span>
            <span className="font-medium">
              {convertMToHMWithLabel(
                millisecondsToMinutes(schedules.breaksTotal?.workObjects ?? 0),
              )}
            </span>
          </div>
          <div className="flex items-center justify-between h-8">
            <span className="">Used</span>
            <span className="font-medium">
              {convertMToHMWithLabel(millisecondsToMinutes(schedules.breaksTotal?.clocks ?? 0))}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9">
        <div className="flex items-center justify-between w-full pt-2 pb-4 border-b">
          <div className="flex flex-col w-full">
            <div>
              <span>Schedule Details of</span>
              <span className="ml-2 font-semibold capitalize">
                {emp ? capitalizeFirstLetter(`${emp.first_name} ${emp.last_name}`) : '-'}
              </span>
            </div>
            <p className="mt-2 text-sm font-normal text-gray-400">
              Need to know more details? We keep clear records of dates, total working times, and
              breaks. Press … to view more.
            </p>
            <div className="flex flex-col w-full mt-6 space-y-4 md:space-y-0 md:space-x-4 md:flex-row md:items-center md:justify-end shrink-0">
              <DatePicker
                label="Start date"
                disableMaskedInput
                inputFormat="dd MMM y"
                value={startDate}
                // onChange={(newValue) => {
                //   if (newValue) {
                //     setStartDate(startOfDay(newValue));
                //   }
                // }}
                onChange={(newValue) => {
                  if (newValue) {
                    searchParams.delete('start');
                    searchParams.append('start', new Date(newValue).getTime().toString());
                    setSearchParams(searchParams);
                    setStartDate(newValue);
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End date"
                disableMaskedInput
                inputFormat="dd MMM y"
                value={endDate}
                // onChange={(newValue) => {
                //   if (newValue) {
                //     setEndDate(endOfDay(newValue));
                //   }
                // }}
                onChange={(newValue) => {
                  if (newValue && startDate && isAfter(newValue, startDate)) {
                    const endD = endOfDay(newValue);
                    searchParams.delete('end');
                    searchParams.append('end', endD.getTime().toString());
                    setSearchParams(searchParams);
                    setEndDate(endD);
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </div>
        </div>
        <div className="w-full mt-6">
          {staffClockInReports.isLoading ? (
            <div className="flex items-end justify-center w-full my-9">
              <span className="mr-4">Please wait</span>
              <Spinner size="small" />
            </div>
          ) : (
            <div className="flex flex-col w-full">
              <div className="grid w-full grid-cols-6 text-xs font-medium text-gray-700 rounded md:text-sm bg-gray-50 text-gray-90 h-14">
                <div className="flex items-center col-span-4 pl-2 pr-2 md:pl-8 md:col-span-1">
                  Date
                </div>
                <div className="items-center hidden col-span-1 pl-2 pr-2 md:pl-8 md:flex">
                  Total Worktime Count
                </div>
                <div className="items-center hidden col-span-1 pl-2 pr-2 md:pl-8 md:flex">
                  Schedule Count
                </div>
                <div className="items-center hidden col-span-1 pl-2 pr-2 md:pl-8 md:flex">
                  Overtime Count
                </div>
                <div className="items-center hidden col-span-1 pl-2 pr-2 md:pl-8 md:flex">
                  Total Worktime
                </div>
                <div className="flex items-center col-span-2 pl-2 pr-2 md:pl-8 md:col-span-1">
                  Attendance Details
                </div>
              </div>
              <div className="flex flex-col w-full divide-y divide-gray-100">
                {Object.keys(staffClockInReports.data ?? {}).map((oneKey, i) => {
                  const clocksArray = staffClockInReports.data![oneKey];
                  let totalWt = 0;
                  let totalBreaks: StaffClockInReport['breaks'] = [];
                  let totalBreakMins = 0;
                  let totalBreakLength = 0;
                  let hasOvertime = 0;
                  clocksArray.workObjects.forEach((c) => {
                    totalWt =
                      totalWt +
                      (!c.built_by_staff ? differenceInMinutes(c?.end_at, c?.start_at) : 0);
                    totalBreakLength = totalBreakLength + c.breaks.length;
                    totalBreaks = [...totalBreaks, ...c.breaks];
                    c.breaks?.forEach((b) => {
                      totalBreakMins = totalBreakMins + millisecondsToMinutes(+b.totall);
                    });
                    if (c.over_time) {
                      hasOvertime = hasOvertime + 1;
                    }
                  });
                  const totalWorktimeHours = totalWt > 0 ? timeWithLabel(totalWt) : 0;
                  // const tBH = convertMinsToHrsMins(totalBreakMins);
                  return (
                    <div
                      className={`grid w-full h-16 grid-cols-6 text-xs md:text-sm font-semibold ${
                        clocksArray?.workObjects?.[0]?.is_locked ? 'text-gray-300' : 'text-gray-700'
                      }`}
                      key={`${oneKey}-${i}`}
                    >
                      <div className="flex items-center w-full col-span-4 pl-2 pr-2 space-x-2 md:pl-8 md:space-x-4 md:col-span-1">
                        {clocksArray?.workObjects?.[0].is_locked ? (
                          <Tooltip title="Request is locked">
                            <span>
                              <LockIcon
                                className={`w-6 h-6 ${
                                  clocksArray?.workObjects?.[0]?.is_locked
                                    ? 'text-gray-300'
                                    : 'text-gray-400'
                                }`}
                              />
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Request can be modify">
                            <span>
                              <LockOpenIcon
                                className={`w-6 h-6 ${
                                  clocksArray?.workObjects?.[0]?.is_locked
                                    ? 'text-gray-300'
                                    : 'text-gray-400'
                                }`}
                              />
                            </span>
                          </Tooltip>
                        )}
                        <span className="font-medium mt-[2px] first-letter:capitalize">
                          {oneKey}
                        </span>
                      </div>
                      <div className="flex-col justify-center hidden w-full col-span-1 pl-2 pr-2 md:pl-8 md:flex">
                        <span className="font-medium first-letter:capitalize">
                          {clocksArray.workObjects.length}
                        </span>
                      </div>
                      <div className="flex-col justify-center hidden w-full col-span-1 pl-2 pr-2 md:pl-8 md:flex">
                        <span className="font-medium first-letter:capitalize">
                          {clocksArray.workObjects.length - hasOvertime}
                        </span>
                      </div>
                      <div className="flex-col justify-center hidden w-full col-span-1 pl-2 pr-2 md:pl-8 md:flex">
                        <span className="font-medium first-letter:capitalize">{hasOvertime}</span>
                      </div>
                      <div className="flex-col justify-center hidden w-full col-span-1 pl-2 pr-2 md:pl-8 md:flex">
                        <span className="font-medium first-letter:capitalize">
                          {totalWorktimeHours}
                        </span>
                      </div>
                      <div className="flex items-center col-span-2 pl-2 pr-2 space-x-4 md:pl-8 md:col-span-1">
                        <Tooltip title="Details">
                          <button
                            type="button"
                            className="text-gray-400 transition hover:text-gray-800 disabled:text-gray-200 disabled:hover:text-gray-200"
                            onClick={() => handleSchedulesInMoreDetails(oneKey)}
                          >
                            <PageNextIcon className="w-8 h-8" />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <UnscheduleClockModal
        show={isUnscheduleClockModalOpen}
        staffId={+attendanceId!}
        jobId={emp?.job_id ?? -1}
        closeModal={closeUnScheduledClockInsModal}
      />
    </div>
  );
};

export default AttendanceDetails;
