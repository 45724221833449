import Dialog from '@mui/material/Dialog';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Location } from '../../../interfaces/location-interface';

interface IForm {
  title: string;
}

interface MapTitleModalProps {
  show: boolean;
  locationData: Location | undefined;
  closeModal: () => void;
  handleMap: (title: string) => void;
}

const schema = yup
  .object({
    title: yup.string().max(250, 'title is too long').required('Please provide a Title'),
  })
  .required();

const MapTitleModal = ({ show, closeModal, handleMap, locationData }: MapTitleModalProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: { title: locationData?.title ?? '' },
    resolver: yupResolver<any>(schema),
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    handleMap(data.title);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    reset({ title: locationData?.title ?? '' });
    closeModal();
  };

  return (
    <Dialog onClose={handleCloseModal} open={show} fullWidth={false} maxWidth={'sm'}>
      <div className="flex flex-col p-6 w-96">
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-2">
          <div className="grid grid-cols-1 m-6 md:m-0 md:mt-4 gap-y-2 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="body" className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <div className="mt-2">
                <input
                  id="body"
                  {...register('title')}
                  className="block w-full h-12 px-3 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                {errors.title?.message}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between w-full pt-4 h-[72px] mx-6 mt-4 border-t md:mx-0">
            <button
              type="button"
              onClick={handleCloseModal}
              className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Close
            </button>
            <button
              type="submit"
              className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default MapTitleModal;
