import { Employee, EmployeeRequest, UpdateEmployeeRequest } from '../interfaces/employee-interface';
import { Job } from '../interfaces/job-interface';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import http from './axios-instance';

const staffsQueryKey = 'Staffs';
const getStaffs = async (): Promise<Employee[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/staff/get`;
  const res = await http.get(url, { headers });
  return res.data;
};

const staffQueryKey = 'Staff';
const getStaff = async (id: number | string): Promise<Employee> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/staff/get/${id}`;
  const res = await http.get(url, { headers });
  return res.data;
};

const archivedStaffsQueryKey = 'ArhcivedStaffs';
const getArchivedStaffs = async (): Promise<Employee[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/staff/get_archived`;
  const res = await http.get(url, { headers });
  return res.data;
};

const getStaffsWithJobId = async (id: Job['id']): Promise<Employee[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { job_id: id };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/job/get`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const getMustUpdateStaffQueryKey = 'MustUpdateStaff';
const getMustUpdateStaff = async (workSpaceSubDomain: string): Promise<any> => {
  const headers = { workSpaceSubDomain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/staff/must_update`;
  const res = await http.get(url, { headers });
  return res.data;
};

const createStaff = async (data: EmployeeRequest): Promise<Employee> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/staff/create`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const updateStaff = async (data: UpdateEmployeeRequest): Promise<Employee> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/staff/edit`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const uploadLeaveReqFile = async (data: {
  staff_id: number;
  file_csv: File;
}): Promise<Employee> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/staff/upload_leave_requests`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const deleteStaff = async (id: Employee['id']): Promise<{ status: string; message: string }> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { staff_id: id };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/staff/delete`;
  const res = await http.delete(url, { headers, params });
  return res.data;
};

const changeStaffStatus = async (
  id: Employee['id'],
  status: 'active' | 'archived',
): Promise<Employee> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const body = { staff_id: id, employment_status: status };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/staff/change_status`;
  const res = await http.post(url, body, { headers });
  return res.data;
};

const sendNotification = async (data: {
  staff_ids: number[];
  title: string;
  body: string;
}): Promise<{ status: string; message: string }> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${
    import.meta.env.VITE_BASE_URL
  }/api/v1/merchant/notification/send_multi_notification`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const staffsService = {
  staffsQueryKey,
  getStaffs,
  getStaffsWithJobId,
  createStaff,
  updateStaff,
  deleteStaff,
  sendNotification,
  archivedStaffsQueryKey,
  getArchivedStaffs,
  changeStaffStatus,
  uploadLeaveReqFile,
  getMustUpdateStaffQueryKey,
  getMustUpdateStaff,
  staffQueryKey,
  getStaff,
};

export default staffsService;
