import { useState } from 'react';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Workspace } from '../../../interfaces/workspace-interface';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import workspaceService from '../../../services/workspace-service';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import FormError from '../../../components/form-error';
import CircularProgress from '@mui/material/CircularProgress';
import { customToastError } from '../../../utils/custom-toast-error';
import { useLocations } from '../../../hooks/use-locations';
import { useActiveWorkspaceSlice } from '../../../store/active-workspace-slice';
import { daysOfWeek } from '../../../utils/employee-utils';
import { Button } from '@mui/material';
import HolidayModal from './holiday-modal';
import { formatDateToTimeZone } from '../../../utils/format-date';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { isSameDay } from 'date-fns';
import Confirmation from '../../../components/libs/confirmation';

export const months = [
  { id: 1, name: 'january' },
  { id: 2, name: 'february' },
  { id: 3, name: 'march' },
  { id: 4, name: 'april' },
  { id: 5, name: 'may' },
  { id: 6, name: 'june' },
  { id: 7, name: 'july' },
  { id: 8, name: 'august' },
  { id: 9, name: 'september' },
  { id: 10, name: 'october' },
  { id: 11, name: 'november' },
  { id: 12, name: 'december' },
];

const schema = yup
  .object({
    email: yup.string().email().required('this field is required'),
    // title: yup
    //   .string()
    //   .min(3, ({ min }) => `must be at least ${min} characters`)
    //   .matches(/^[A-Za-z0-9 ._\- ]+$/, 'Please change your subdomain')
    //   .required('This field is required'),
    sub_domain: yup
      .string()
      .test('checkSubDomain', 'Sorry, Please choose another Business Name', (value) => {
        return new Promise((resolve, reject) => {
          workspaceService
            .checkSubDomain({ sub_domain: value })
            .then((data) => {
              resolve(true);
            })
            .catch((err) => {
              resolve(false);
            });
        });
      }),
    is_app_login_able: yup.boolean().test('req', 'Please select at-least one', (v, c) => {
      const bool = c.parent.is_central_app_login_able;
      if (bool || (!bool && v)) return true;
      return false;
    }),
    is_central_app_login_able: yup.boolean(),
  })
  .required();

interface FormData {
  email: string;
  sub_domain: string;
  default_location_id: number | undefined | null;
  holidays: { date: number; description: string }[];
  labor_year_commencement: number | null;
  weekends: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  is_app_login_able: boolean;
  is_central_app_login_able: boolean;
}

interface CompanyFormProps {
  workspace: Workspace;
  handleEditMode: () => void;
}

const CompanyForm = ({ workspace, handleEditMode }: CompanyFormProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isHolidayModalOpen, setIsHolidayModalOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { data: locationsData = [] } = useLocations();
  const updateActiveWorkspace = useActiveWorkspaceSlice((state) => state.updateActiveWorkspace);
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: workspace.email,
      default_location_id: workspace.default_location_id,
      sub_domain: workspace.sub_domain,
      holidays: workspace.holidays
        ? workspace.holidays.map((h: any) => ({ date: h.date, description: h.description }))
        : [],
      labor_year_commencement: workspace.labor_year_commencement ?? null,
      weekends: workspace.weekends
        ? workspace.weekends
        : {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
          },
      is_app_login_able: workspace?.is_app_login_able ?? true,
      is_central_app_login_able: workspace?.is_central_app_login_able ?? true,
    },
    resolver: yupResolver<any>(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'holidays',
  });
  const { weekends, holidays } = watch();

  const {
    mutate,
    isLoading,
    error,
    data: editWorkspaceData,
  } = useMutation(workspaceService.updateWorkspace, {
    onSettled: () => {
      // queryClient.invalidateQueries([workspaceService.workspacesQueryKey]);
      queryClient.invalidateQueries();
    },
    onSuccess: (data: any) => {
      if (data?.status === 'error') {
        handleOpenConfirmation();
      } else {
        toast.success(`Workspace updated Successfully`);
        handleEditMode();
        updateActiveWorkspace(data);
        navigate(`/${data.sub_domain}/settings/company`);
      }
    },
    onError: (data: AxiosError<any>) => {
      customToastError('Error', data.response?.data.message ?? 'Sorry there is a problem');
    },
  });

  const handleOpenHolidayModal = () => {
    setIsHolidayModalOpen(true);
  };

  const handleCloseHolidayModal = (data: { date: number; description: string } | null = null) => {
    if (data) {
      let isHolidayAlreadyExist = false;
      holidays.forEach((h) => {
        if (isSameDay(h.date, data.date)) {
          isHolidayAlreadyExist = true;
        }
      });
      if (!isHolidayAlreadyExist) {
        append(data);
      } else {
        customToastError('Holiday already exists.');
      }
    }
    setIsHolidayModalOpen(false);
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    clearErrors('sub_domain');
    mutate({ ...data, title: data.sub_domain });
  };

  const handleUpdateWeekEnds = (day: typeof daysOfWeek[number]) => {
    setValue(`weekends.${day}`, !weekends[day]);
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmForce = (bool: boolean) => {
    if (bool) {
      const data = watch();
      mutate({ ...data, title: data.sub_domain, force: true });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
        <div className="space-y-2 divide-y divide-gray-200">
          <div className="grid grid-cols-1 m-6 md:m-0 md:mt-6 gap-y-2 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  id="email"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  {...register('email')}
                />
              </div>
              <p className="h-8 pt-2 text-red-500 capitalize">{errors.email?.message}</p>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="sub" className="block text-sm font-medium text-gray-700">
                Business Name
              </label>
              <div className="mt-1">
                <input
                  id="sub"
                  type="text"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  {...register('sub_domain')}
                />
              </div>
              <p className="h-8 pt-2 text-red-500 capitalize">{errors.sub_domain?.message}</p>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="sub" className="block text-sm font-medium text-gray-700">
                Location
              </label>
              <div className="mt-1">
                <select
                  id="location"
                  defaultValue="-1"
                  {...register('default_location_id')}
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option disabled value="-1">
                    Select a location
                  </option>
                  {locationsData?.map((loc) => (
                    <option key={loc.id} value={loc.id}>
                      {loc.title}
                    </option>
                  ))}
                </select>
              </div>
              <p className="h-8 pt-2 text-red-500 capitalize">
                {errors.default_location_id?.message}
              </p>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="sub" className="block text-sm font-medium text-gray-700">
                Labor Year Commencement
              </label>
              <div className="mt-1">
                <select
                  id="location"
                  defaultValue="-1"
                  {...register('labor_year_commencement')}
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option disabled value="-1">
                    Select a location
                  </option>
                  {months?.map((m) => {
                    const name = capitalizeFirstLetter(m.name);
                    return (
                      <option key={m.id} value={m.id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <p className="h-8 pt-2 text-red-500 capitalize">
                {errors.default_location_id?.message}
              </p>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="weekend" className="block text-sm font-medium text-gray-700">
                Weekend
              </label>
              <div className="mt-1">
                <div className="flex space-x-2">
                  {daysOfWeek.map((day) => {
                    const name = day.substring(0, 3);
                    const variant = weekends[day] ? 'contained' : 'outlined';
                    return (
                      <Button
                        key={day}
                        onClick={() => handleUpdateWeekEnds(day)}
                        type="button"
                        variant={variant}
                        color="primary"
                        sx={{
                          minWidth: '48px',
                          width: '48px',
                          height: '48px',
                          padding: '0',
                          fontSize: '12px',
                        }}
                      >
                        {name}
                      </Button>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="mt-6 sm:col-span-6">
              <label htmlFor="holidays" className="block text-sm font-medium text-gray-700">
                Public Holiday
              </label>
              <div className="flex flex-col mt-1">
                <button
                  type="button"
                  onClick={handleOpenHolidayModal}
                  className="flex justify-center w-40 py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Add Holiday
                </button>
                <div className="grid grid-cols-12 gap-4 mt-12">
                  {fields.map((field, index) => (
                    <div
                      className="flex flex-col h-32 col-span-3 p-4 space-y-4 text-sm border border-gray-200 rounded bg-gray-50"
                      key={field.id}
                    >
                      <div className="flex justify-between">
                        <span className="text-sm font-medium text-gray-600">
                          {formatDateToTimeZone(new Date(field.date), 'dd MMM y')}
                        </span>
                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="flex items-center justify-center h-8 text-xs font-normal text-white bg-red-600 border border-transparent rounded w-11 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <XMarkIcon className="w-5 h-5" aria-hidden="true" />
                        </button>
                      </div>
                      <span className="line-clamp-2">
                        {capitalizeFirstLetter(field.description)}
                      </span>
                    </div>
                  ))}
                  <div></div>
                </div>
              </div>
            </div>
            <div className="mt-6 sm:col-span-6">
              <div className="flex items-center w-full">
                <input
                  type="checkbox"
                  id="is_app_login_able"
                  {...register('is_app_login_able')}
                  className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                />
                <div className="flex flex-col ml-4">
                  <label
                    htmlFor="is_app_login_able"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mobile App Login
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-6 sm:col-span-6">
              <div className="flex items-center w-full">
                <input
                  type="checkbox"
                  id="is_central_app_login_able"
                  {...register('is_central_app_login_able')}
                  className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                />
                <div className="flex flex-col ml-4">
                  <label
                    htmlFor="is_central_app_login_able"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Central Terminal Login
                  </label>
                </div>
              </div>
              <p className="h-8 pt-2 text-red-500 capitalize">
                {errors.is_app_login_able?.message}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:flex-row md:items-center md:justify-between">
          <FormError error={error} />
          <div className="flex items-center space-x-4">
            <button
              type="button"
              disabled={isLoading}
              className="flex justify-center w-40 py-3 ml-4 text-sm font-medium text-white bg-red-600 border border-transparent rounded shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={handleEditMode}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="flex justify-center w-40 py-3 ml-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {isLoading ? (
                <CircularProgress
                  sx={{
                    color: (theme) =>
                      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                  }}
                  size={24}
                />
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </form>
      <HolidayModal open={isHolidayModalOpen} close={handleCloseHolidayModal} />
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmForce}
        description={editWorkspaceData?.message ?? ''}
      />
    </>
  );
};

export default CompanyForm;
