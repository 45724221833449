import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { getHours, getMinutes, isBefore, setHours, setMinutes, setSeconds } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import calendarService, { WorktimeDeleteType } from '../../../services/calendar-service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { dateUtc, utcDate } from '../../../utils/format-date';
import reportsService from '../../../services/reports-service';
import { useAttendanceClockSlice } from '../../../store/attendance-clock-slice';
import { AxiosError } from 'axios';
import { customToastError } from '../../../utils/custom-toast-error';
import { UpdateWorktimeRequest } from '../../../interfaces/worktime-interface';

interface IForm {
  start_at: string | Date | null;
  end_at: string | Date | null;
  text: string;
}

const schema = yup
  .object({
    text: yup.string().required('Please fill the reason'),
    start_at: yup
      .date()
      .transform((value) => value ?? undefined)
      .required('Please fill the Start Time'),
    end_at: yup
      .date()
      .transform((value) => value ?? undefined)
      .required('Please fill the End Time'),
  })
  .required();

const EditWorkTimeModal = () => {
  const queryClient = useQueryClient();
  const worktime = useAttendanceClockSlice((state) => state.worktime);
  const isWorktimeOpen = useAttendanceClockSlice((state) => state.isWorktimeOpen);
  const resetWorkTime = useAttendanceClockSlice((state) => state.updateWorktime);
  const closeModal = useAttendanceClockSlice((state) => state.updateIsWorktimeOpen);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IForm>({
    resolver: yupResolver<any>(schema),
  });

  useEffect(() => {
    if (worktime) {
      setValue('start_at', dateUtc(worktime.start_at));
      setValue('end_at', dateUtc(worktime.end_at));
    } else {
      setValue('start_at', null);
      setValue('end_at', null);
    }
  }, [worktime]);

  const { mutate: updateWorktime, isLoading: updateWorktimeIsLoading } = useMutation(
    ({ params, type }: { params: UpdateWorktimeRequest; type: WorktimeDeleteType }) =>
      calendarService.updateWorktime(params, type),
    {
      onSuccess: (data) => {
        handleCloseModal();
      },
      onError(error: AxiosError<any>) {
        customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
      },
      onSettled: () => {
        queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
      },
    },
  );

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (updateWorktimeIsLoading) {
      return;
    }
    const startH = setHours(worktime!.start_at, getHours(new Date(data.start_at!)));
    const startM = setMinutes(startH, getMinutes(new Date(data.start_at!)));
    const start_at = utcDate(setSeconds(startM, 0)).getTime();
    const endH = setHours(worktime!.end_at, getHours(new Date(data.end_at!)));
    const endM = setMinutes(endH, getMinutes(new Date(data.end_at!)));
    const end_at = utcDate(setSeconds(endM, 0)).getTime();
    if (isBefore(endM, startM)) {
      customToastError('Error', `Please Select a valid time`);
      return;
    }
    updateWorktime({
      params: {
        status_edited_comment: data.text,
        start_at,
        end_at,
        working_object_id: worktime!.id,
      },
      type: 'ATTENDANCE',
    });
  };

  const handleCloseModal = () => {
    closeModal(false);
    resetWorkTime(null);
    reset({
      text: '',
      start_at: null,
      end_at: null,
    });
  };

  return (
    <Dialog onClose={handleCloseModal} open={isWorktimeOpen} fullWidth={true} maxWidth={'sm'}>
      <div className="flex flex-col w-full p-6">
        <div className="pb-6 mb-6 text-lg font-medium leading-6 text-gray-900 border-b">
          Edit Worktime
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
          <div className="grid grid-cols-1 pt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <Controller
                name="start_at"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    label="Start Time"
                    ampm={false}
                    renderInput={(params) => <TextField fullWidth={true} {...params} />}
                  />
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <Controller
                name="end_at"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    label="End Time"
                    ampm={false}
                    renderInput={(params) => <TextField fullWidth={true} {...params} />}
                  />
                )}
              />
            </div>
            <div className="sm:col-span-6">
              <textarea
                {...register('text')}
                className="w-full h-40 p-4 border-gray-200 rounded"
                placeholder="reason..."
              ></textarea>
            </div>
          </div>
          <div className="flex items-center justify-between w-full pt-6 h-[72px] mx-6 mt-6 border-t md:mx-0">
            <button
              type="button"
              onClick={handleCloseModal}
              className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Close
            </button>
            <button
              type="submit"
              className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default EditWorkTimeModal;
