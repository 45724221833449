import Tooltip from '@mui/material/Tooltip';
import AlarmCheckIcon from '~icons/mdi/alarm-check';
import CaledarCheckOutlineIcon from '~icons/mdi/calendar-check-outline';
import { LeaveReq } from '../../../interfaces/calendar-leave-request-interface';

interface TimeOffIndicatorProps {
  hourLeaveAccepted: LeaveReq[];
  dayLeavePending: LeaveReq[];
  hourLeavePending: LeaveReq[];
  hourLeaveDeclined: LeaveReq[];
  dayLeaveDeclined: LeaveReq[];
}

const TimeOffIndicator = ({
  hourLeaveAccepted,
  dayLeavePending,
  hourLeavePending,
  hourLeaveDeclined,
  dayLeaveDeclined,
}: TimeOffIndicatorProps) => {
  switch (true) {
    case dayLeavePending.length > 0:
      return (
        <div className="flex flex-col items-center justify-center w-6 h-full space-x-1 bg-orange-400 rounded">
          <Tooltip title={'Pending Daily Time off Request'} placement="top-start">
            <span>
              <CaledarCheckOutlineIcon className="w-6 h-6 text-white" />
            </span>
          </Tooltip>
        </div>
      );
    case hourLeavePending.length > 0:
      return (
        <div className="flex flex-col items-center justify-center w-6 h-full space-x-1 bg-orange-400 rounded">
          <Tooltip title={'Pending Hourly Time off Request'} placement="top-start">
            <span>
              <AlarmCheckIcon className="w-6 h-6 text-white" />
            </span>
          </Tooltip>
        </div>
      );
    case hourLeaveAccepted.length > 0:
      return (
        <div className="flex flex-col items-center justify-center w-6 h-full space-x-1 bg-indigo-400 rounded">
          <Tooltip title={'Accepted Hourly Time off Request'} placement="top-start">
            <span>
              <AlarmCheckIcon className="w-6 h-6 text-white" />
            </span>
          </Tooltip>
        </div>
      );
    case hourLeaveDeclined.length > 0:
      return (
        <div className="flex flex-col items-center justify-center w-6 h-full space-x-1 bg-red-400 rounded">
          <Tooltip title={'Rejected Hourly Time off Request'} placement="top-start">
            <span>
              <AlarmCheckIcon className="w-6 h-6 text-white" />
            </span>
          </Tooltip>
        </div>
      );
    case dayLeaveDeclined.length > 0:
      return (
        <div className="flex flex-col items-center justify-center w-6 h-full space-x-1 bg-red-400 rounded">
          <Tooltip title={'Rejected Daily Time off Request'} placement="top-start">
            <span>
              <CaledarCheckOutlineIcon className="w-6 h-6 text-white" />
            </span>
          </Tooltip>
        </div>
      );
    default:
      return null;
  }
};

export default TimeOffIndicator;
