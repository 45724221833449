import Chart from 'react-apexcharts';

interface BarChartProps {
  chartData: any;
  categories: string[];
}

const BarChart = ({ chartData, categories }: BarChartProps) => {
  return (
    <Chart
      options={{
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          // animations: { enabled: false },
          foreColor: '#9ca3af',
          // fontFamily: theme.typography.fontFamily,
        },
        states: {
          hover: {
            filter: {
              type: 'lighten',
              value: 0.04,
            },
          },
          active: {
            filter: {
              type: 'darken',
              value: 0.88,
            },
          },
        },
        fill: {
          opacity: 1,
          gradient: {
            type: 'vertical',
            shadeIntensity: 0,
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 100],
          },
        },
        dataLabels: { enabled: false },
        stroke: {
          width: 3,
          curve: 'smooth',
          lineCap: 'round',
        },
        grid: {
          strokeDashArray: 3,
          borderColor: '#d1d5db',
        },
        xaxis: {
          axisBorder: { show: false },
          axisTicks: { show: false },
          categories,
        },
        yaxis: {
          labels: {
            formatter(val) {
              return `${val} %`;
            },
          },
        },
        // markers: {
        //   size: 0,
        //   strokeColors: 'red',
        // },
        tooltip: {
          x: {
            show: false,
          },
        },
        legend: {
          show: true,
          fontSize: String(13),
          position: 'top',
          horizontalAlign: 'right',
          markers: {
            // radius: 12,
          },
          fontWeight: 500,
          itemMargin: { horizontal: 12 },
          labels: {
            colors: '#334155',
          },
        },
      }}
      series={chartData}
      type="line"
      width="100%"
      height={250}
    />
  );
};

export default BarChart;
