import { useCallback, useEffect, useRef, useState } from 'react';
import { useTitle } from 'react-use';
import GoogleMapReact from 'google-map-react';
import { getRadiusInMeter } from '../../utils/circle-convertor';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
// import BuildingIcon from '../../../assets/maps/building.svg';
import { useLocations } from '../../hooks/use-locations';
import LocationsTable from './components/locations-table';
import { Location as ILocation } from '../../interfaces/location-interface';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';

const googleMapOptions: GoogleMapReact.MapOptions = {
  scrollwheel: true,
  // mapTypeControl: false,
  scaleControl: true,
  // draggable: false,
  mapTypeId: 'roadmap',
  zoomControl: true,
};

const Locations = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Locations`);
  const defaultLocationId = useActiveWorkspaceSlice(
    (state) => state.workspace?.default_location_id,
  );
  const [defaultCenter] = useState<google.maps.LatLngLiteral>({
    lat: 50.827778,
    lng: -0.152778,
  });
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [locId, setLocId] = useState<ILocation['id'] | null>(null);
  const [mapsRef, setMapsRef] = useState<typeof google.maps | null>(null);
  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
  const shapesRef = useRef<Record<string, google.maps.Polyline | google.maps.Circle>>({});
  const { data: locationsData = [] } = useLocations();
  const wsLocation = locationsData.find((l) => l.id === defaultLocationId);

  const handleActiveShape = useCallback(
    (id: number) => {
      const loc = locationsData.find((l) => l.id === id);
      const points = loc!.points.map((ln) => ({ lat: ln.lat, lng: ln.lon }));
      const shape = shapesRef.current?.[String(id)];
      setLocId(id);
      for (const key in shapesRef.current) {
        if (Object.prototype.hasOwnProperty.call(shapesRef.current, key)) {
          const element = shapesRef.current[key];
          element.setOptions({
            strokeColor: '#592d88',
            fillColor: '#592d88',
          });
        }
      }
      shape?.setOptions({
        strokeColor: 'red',
        fillColor: 'red',
      });
      if (points.length < 3) {
        mapRef?.setCenter(points[0]);
      } else {
        const c = getPolygonCenter(points);
        mapRef?.setCenter(c);
      }
    },
    [shapesRef.current, locationsData, mapRef],
  );

  useEffect(() => {
    if (wsLocation) {
      handleActiveShape(wsLocation.id);
    }
  }, [wsLocation, handleActiveShape]);

  useEffect(() => {
    if (locationsData.length && mapsRef && mapRef) {
      handleClearMap();
      locationsData.forEach((loc) => {
        handleCreateShape(loc);
      });
    }
  }, [locationsData, mapsRef, mapRef]);

  const handleApiLoaded = (map: google.maps.Map, maps: typeof google.maps) => {
    setMapsRef(maps);
    setMapRef(map);
  };

  const handleClearMap = () => {
    for (const key in shapesRef.current) {
      if (Object.prototype.hasOwnProperty.call(shapesRef.current, key)) {
        const element = shapesRef.current[key];
        element.setMap(null);
      }
    }
    shapesRef.current = {};
  };

  const createPolygon = (id: number, paths: google.maps.LatLngLiteral[] = []) => {
    const poly = new mapsRef!.Polygon({
      paths,
      strokeColor: '#592d88',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#592d88',
      fillOpacity: 0.35,
      editable: false,
      draggable: false,
    });
    poly.setMap(mapRef!);
    shapesRef.current = { ...shapesRef.current, [String(id)]: poly };
  };

  const createCircle = (id: number, center: google.maps.LatLngLiteral, radius: number) => {
    const circle = new mapsRef!.Circle({
      strokeColor: '#592d88',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#592d88',
      fillOpacity: 0.35,
      center,
      radius,
      editable: false,
      draggable: false,
    });
    circle.setMap(mapRef!);
    shapesRef.current = { ...shapesRef.current, [String(id)]: circle };
  };

  const handleCreateShape = (loc: ILocation) => {
    const points = loc!.points.map((ln) => ({ lat: ln.lat, lng: ln.lon }));
    if (loc?.points.length === 2) {
      const radius = getRadiusInMeter(points[0], points[1]);
      createCircle(loc.id, points[0], radius);
    } else {
      createPolygon(loc.id, points);
    }
  };

  const getPolygonCenter = (points: { lat: number; lng: number }[]) => {
    const bounds = new mapsRef!.LatLngBounds();
    const polygonCoords = points.map((p) => {
      return new google.maps.LatLng(p.lat, p.lng);
    });
    for (let i = 0; i < polygonCoords.length; i++) {
      bounds.extend(polygonCoords[i]);
    }
    return bounds.getCenter();
  };

  return (
    <>
      <div className="w-full mx-auto space-y-8 pb-14" ref={containerRef}>
        <div className="flex flex-col items-center justify-center w-full p-4 bg-white border border-gray-100 rounded-md shadow-lg md:p-9">
          <div className="flex items-center justify-between w-full pb-6 mb-6 font-medium border-b">
            <div className="flex flex-col">
              <span>Locations</span>
              <p className="mt-2 text-sm font-normal text-gray-400">Locations List</p>
            </div>
            <Link
              to="../new-location"
              className="flex items-center justify-center w-40 text-sm font-medium text-white transition duration-200 rounded shadow bg-brand-primary hover:bg-brand-primary-dark h-11"
            >
              <PlusIcon className="w-6 h-6 mr-2" />
              New Location
            </Link>
          </div>
          <div className="grid w-full grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-2">
            <LocationsTable
              locations={locationsData}
              handleActiveShape={handleActiveShape}
              locId={locId}
            />
            <div className="flex h-[400px] w-full border-[8px] p-1 rounded-xl shadow border-gray-50">
              <GoogleMapReact
                bootstrapURLKeys={{ key: import.meta.env.VITE_GOOGLE_MAP_KEY }}
                options={googleMapOptions}
                defaultCenter={defaultCenter}
                defaultZoom={14}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              ></GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Locations;
