import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Calendar } from '../../../interfaces/calendar-interface';
import calendarService from '../../../services/calendar-service';
import { useCalendarSlice } from '../../../store/calendar-slice';
import PencilIcon from '~icons/carbon/edit';
import TrashIcon from '~icons/carbon/trash-can';
import { Break } from '../../../interfaces/break-interface';
import { dateUtc, formatDateToTimeZone } from '../../../utils/format-date';
import { isSameDay, millisecondsToMinutes } from 'date-fns';
import { convertMToHMWithLabel } from '../../../utils/format-minutes';
import Confirmation from '../../../components/libs/confirmation';
import { customToastError } from '../../../utils/custom-toast-error';
import { AxiosError } from 'axios';

export const breaksTotalMin = (breaks: Break[]) => {
  let totalMin = 0;
  breaks.forEach((br) => {
    totalMin = totalMin + millisecondsToMinutes(+br.totall);
  });
  return isNaN(totalMin) || totalMin < 0 ? 0 : totalMin;
};

interface BreakWidthSchedule extends Break {
  schedule: Calendar;
}

const createBreaks = (schedules: Calendar[] | undefined) => {
  const breaks: BreakWidthSchedule[] = [];
  if (schedules) {
    schedules.forEach((sch) => {
      sch.breaks.forEach((br) => {
        breaks.push({ ...br, schedule: sch });
      });
    });
  }
  return breaks;
};

const BreakList = () => {
  const queryClient = useQueryClient();
  const activeResource = useCalendarSlice((state) => state.activeResource);
  const selectedDate = useCalendarSlice((state) => state.selectedDate);
  const toggleScheduleModal = useCalendarSlice((state) => state.updateShowScheduleModal);
  const updateScheduleModalTab = useCalendarSlice((state) => state.updateScheduleModalTab);
  const updateBreakForUpdate = useCalendarSlice((state) => state.updateBreakForUpdate);
  const updateActiveResource = useCalendarSlice((state) => state.updateActiveResource);
  const [brId, setBrId] = useState<number | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const schedules =
    activeResource?.schedules.filter((ev) => {
      if (selectedDate) {
        return isSameDay(dateUtc(ev.start_at), dateUtc(selectedDate));
      }
      return false;
    }) ?? [];
  const breaks = createBreaks(schedules);
  const totalMins = breaksTotalMin(breaks);
  // const canModify = selectedDate ? allowToModify(selectedDate) : false;

  const { mutate: deleteBreak, isLoading: deleteBreakIsLoading } = useMutation(
    calendarService.deleteBreak,
    {
      onSuccess: (_, id) => {
        updateActiveResource({
          ...activeResource!,
          schedules: activeResource!.schedules.map((s) => {
            return { ...s, breaks: s.breaks.filter((b) => b.id !== id) };
          }),
        });
        toast.success(`Break time deleted successfuly`);
        queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
      },
      onError(error: AxiosError<any>) {
        setBrId(null);
        customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
      },
    },
  );

  const handleEdit = (br: Break) => {
    updateBreakForUpdate(br);
    updateScheduleModalTab('Add Break');
  };

  const handleCloseModal = () => {
    toggleScheduleModal(false);
  };

  const handleDelete = (id: number) => {
    setBrId(id);
    handleOpenConfirmation();
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && brId && !deleteBreakIsLoading) {
      deleteBreak(brId);
      return;
    }
  };

  return (
    <>
      <div className="flex flex-col flex-1 w-full h-full">
        <div className="overflow-hidden overflow-y-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg h-[calc(100%-64px)]">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr className="grid grid-cols-4">
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  Start
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  Total
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {breaks?.map((br) => {
                const total = millisecondsToMinutes(br.totall);
                return (
                  <tr key={br.id} className="grid grid-cols-4 h-14">
                    <td className="px-2 text-sm text-gray-500">
                      <div className="flex flex-col items-center justify-center h-full">
                        <div className="font-semibold text-center text-gray-900">
                          {br.start_at ? formatDateToTimeZone(+br.start_at, 'HH:mm') : '-'}
                        </div>
                        <div className="text-center text-gray-500">
                          {br.start_at ? formatDateToTimeZone(+br.start_at, 'dd-MM-y') : '-'}
                        </div>
                      </div>
                    </td>
                    <td className="px-2 text-sm text-gray-500">
                      <div className="flex items-center justify-center h-full font-semibold text-center text-gray-900">
                        {total > 1 ? `${total} Minutes` : `${total} Minute`}
                      </div>
                    </td>
                    <td className="px-2 text-sm text-gray-500">
                      <div className="flex items-center justify-center h-full font-semibold text-center text-gray-900 capitalize">
                        {br.type}
                      </div>
                    </td>
                    <td className="relative px-2 text-sm font-medium text-center">
                      <div className="flex items-center justify-center h-full space-x-4">
                        <button
                          type="button"
                          className="text-gray-400 transition disabled:text-gray-300 hover:text-gray-800"
                          disabled={
                            deleteBreakIsLoading || br.is_locked || br.schedule?.clocks?.length >= 1
                          }
                          onClick={() => handleDelete(br.id)}
                        >
                          <TrashIcon className="w-6 h-6" />
                        </button>
                        <button
                          type="button"
                          className="text-gray-400 transition disabled:text-gray-300 hover:text-gray-800"
                          disabled={
                            deleteBreakIsLoading || br.is_locked || br.schedule?.clocks?.length >= 1
                          }
                          onClick={() => handleEdit(br)}
                        >
                          <PencilIcon className="w-6 h-6" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between pt-6 text-base text-blue-500">
          <button
            type="button"
            onClick={handleCloseModal}
            className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Close
          </button>
          <div className="flex items-center">
            <span className="text-sm">Total:</span>
            <span className="ml-2">{convertMToHMWithLabel(totalMins)}</span>
          </div>
        </div>
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
      />
    </>
  );
};

export default BreakList;
