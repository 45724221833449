import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useFirebaseSlice } from './store/firebase-slice';
// import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
// import toast from 'react-hot-toast';

const firebaseConfig = {
  apiKey: 'AIzaSyDqJqYXsuLWxpHmnHHx3URlPZRjeeOc0HU',
  authDomain: 'myselected-time.firebaseapp.com',
  projectId: 'myselected-time',
  storageBucket: 'myselected-time.appspot.com',
  messagingSenderId: '257764046166',
  appId: '1:257764046166:web:5f0f9192829d7e711112f7',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      'BI-LkPv0eAtq0cz8dh3mMFzL6Rm7Rpg2jsnpzCI-iBG54Q7_BtNEq4yHxO9WVeag9mEGsHQSr2J844lo0WtF_Vw',
  })
    .then((currentToken) => {
      if (currentToken) {
        const updateToken = useFirebaseSlice.getState().updateToken;
        updateToken(currentToken);
        // console.log('current token for client: ', currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.warn('No registration token available. Request permission to generate one.');
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      // TODO: tell user notif permission is critical to our app
      // console.warn('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

onMessage(messaging, (payload) => {
  // console.log(payload);
  if (payload?.data) {
    const updateMessage = useFirebaseSlice.getState().updateMessage;
    updateMessage(payload.data);
  }
  // if (data?.send === 'notification') {
  //   const updateNotif = useFirebaseSlice.getState().updateNotification;
  //   updateNotif(payload?.data);
  //   toast.custom((t) => (
  //     <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
  //       <div className="p-4">
  //         <div className="flex items-start">
  //           <div className="flex-shrink-0">
  //             <CheckCircleIcon className="w-6 h-6 text-green-400" aria-hidden="true" />
  //           </div>
  //           <div className="ml-3 w-0 flex-1 pt-0.5">
  //             <p className="text-sm font-medium text-gray-900">{data?.title}</p>
  //             <p className="mt-1 text-sm text-gray-500">{data?.body}</p>
  //           </div>
  //           <div className="flex flex-shrink-0 ml-4">
  //             <button
  //               type="button"
  //               className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
  //               onClick={() => toast.dismiss(t.id)}
  //             >
  //               <span className="sr-only">Close</span>
  //               <XMarkIcon className="w-5 h-5" aria-hidden="true" />
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   ));
  // }
});
