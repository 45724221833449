import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import devicesService from '../../services/devices-service';
import { Device } from '../../interfaces/central-devices/device';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';

export function useGetCentralDevices(configOptions: UseQueryOptions<any, any, Device[], any> = {}) {
  const activeWorkspace = useActiveWorkspaceSlice((state) => state.workspace);

  return useQuery({
    queryKey: [devicesService.centralDevicesQueryKey, activeWorkspace?.id],
    queryFn: () => devicesService.getCentralDevices(),
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 3,
    ...configOptions,
  });
}
