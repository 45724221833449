import { useQuery } from '@tanstack/react-query';
import calendarService from '../services/calendar-service';

export function useCalendarLeaveRequests(from: number | null, to: number | null) {
  return useQuery(
    [calendarService.leaveRequestsQueryKey, from, to],
    () => calendarService.getLeaveRequests({ from: from!, to: to! }),
    {
      enabled: Boolean(from) && Boolean(to),
      refetchOnWindowFocus: false,
    },
  );
}
