import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

interface PrevPageProps {
  to?: string;
}

const PrevPage = ({ to }: PrevPageProps) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(-1)}
      className="flex items-center justify-center w-24 mb-4 transition bg-white border-2 rounded-md shadow-md cursor-pointer text-brand-primary border-brand-primary h-11 hover:bg-brand-primary hover:text-white"
    >
      <ArrowLeftIcon className="w-6 h-6" />
      <span className="ml-2 font-medium">Back</span>
    </div>
  );
};

export default PrevPage;
