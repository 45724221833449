import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrintWorkTimeDetailsSlice } from '../../store/print-worktime-details-slice';
import { formatDate } from '../../utils/format-date';
import { UserDayReport } from '../staffs-worktime-simple/staffs-worktime-simple';

const PrintWorkTimeSimple = () => {
  const navigate = useNavigate();
  const data = usePrintWorkTimeDetailsSlice((state) => state.data);
  const daysReport = data?.days.flatMap((d) => {
    const userDayReport: UserDayReport[] = [];
    if (d.clocks.length) {
      d.clocks.forEach((c) => {
        userDayReport.push({ ...d, clock: c });
      });
    } else {
      userDayReport.push({ ...d, clock: null });
    }
    return userDayReport;
  });

  useEffect(() => {
    window.print();
  }, []);

  return (
    <>
      <div className="mx-auto space-y-0 max-w-7xl pb-9 print:p-4 print:font-sans print:max-w-none print:w-full">
        <div className="flex items-center justify-between my-4 print:hidden">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="w-32 py-2 text-sm text-gray-700 border border-gray-200 rounded bg-gray-50"
          >
            Back
          </button>
          <button
            type="button"
            onClick={() => window.print()}
            className="w-32 py-2 text-sm text-gray-700 border border-gray-200 rounded bg-gray-50"
          >
            Print
          </button>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between px-4 mb-2 text-[10px] text-gray-500">
            <div className="flex flex-col">
              <span className="font-medium capitalize">Name: {data?.staff_name}</span>
              <span className="text-gray-300">Email: {data?.staff_email}</span>
            </div>
            <div className="flex flex-col">
              <span className="capitalize">Job: {data?.staff_job}</span>
            </div>
          </div>
          <div className="grid w-full h-12 grid-cols-5 text-xs print:text-[8px] font-medium text-gray-700 bg-gray-100 rounded">
            <div className="flex items-center justify-center h-full col-span-1 text-center">
              Date
            </div>
            <div className="flex items-center justify-center h-full col-span-1 text-center">
              Department
            </div>
            <div className="flex items-center justify-center h-full col-span-1 text-center">
              Clock-in
            </div>
            <div className="flex items-center justify-center h-full col-span-1 text-center">
              Clock-out
            </div>
            <div className="flex items-center justify-center h-full col-span-1 text-center">
              Location
            </div>
          </div>
          <div className="flex flex-col w-full divide-y-[1px] divide-gray-100 print:border-b print:border-b-gray-50">
            {(daysReport ?? []).map((report, i) => {
              return (
                <Fragment key={i}>
                  <div className="grid w-full grid-cols-5 text-xs print:text-[8px] font-medium text-gray-700 rounded h-14 print:h-7">
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {formatDate(report.date_ms, 'dd-MM-y')}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report?.clock?.department_title}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report?.clock?.start}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report?.clock?.end}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report?.clock?.location_title}
                    </div>
                  </div>
                  {(report?.leaveRequests ?? []).map((lr) => {
                    return (
                      <div
                        key={lr.id}
                        className="grid w-full grid-cols-7 text-xs print:text-[8px] font-medium text-gray-700 rounded h-14 print:h-7"
                      >
                        <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2 text-[10px] print:text-[8px]">
                          Time off
                        </div>
                        <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                          {lr.start ? `Start: ${lr.start}` : 'Daily'}
                        </div>
                        <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                          {lr.end ? `End: ${lr.end}` : 'Daily'}
                        </div>
                        <div className="flex items-center justify-center h-full col-span-2 text-center capitalize line-clamp-2">
                          Type: {lr?.leave_title}
                        </div>
                        {lr?.leave_title?.toLowerCase() === 'business leave' && (
                          <div className="flex items-center justify-center h-full col-span-2 text-center capitalize line-clamp-2">
                            Reason: {lr.reason}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintWorkTimeSimple;
