import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { useCalendarSlice, Tab } from '../../../store/calendar-slice';
import { PlusIcon, ListBulletIcon, ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import CalendarIcon from '~icons/mdi/calendar-check-outline';
import { classNames } from '../../../utils/tailwind-class-names';
import AddWorktime from './add-worktime';
import AddBreak from './add-break';
import BreakList from './break-list';
import WorktimeList from './worktime-list';
// import { allowToModify } from '../../../utils/allow-to-modify';
import LeaveRequestsList from './leave-requests-list';
import ShiftsList from './shifts-list';
import AddTimeOff from './add-time-off';
import AlarmCheckIcon from '~icons/mdi/alarm-check';

interface ITab {
  name: Tab;
  icon: any;
}

const allTabs: ITab[] = [
  {
    name: 'Worktime List',
    icon: ListBulletIcon,
  },
  {
    name: 'Add Worktime',
    icon: PlusIcon,
  },
  {
    name: 'Break List',
    icon: ListBulletIcon,
  },
  {
    name: 'Add Break',
    icon: PlusIcon,
  },
  {
    name: 'Time off Requests',
    icon: AlarmCheckIcon,
  },
  {
    name: 'Add Time Off',
    icon: CalendarIcon,
  },
  {
    name: 'Shift Swap Requests',
    icon: ArrowsRightLeftIcon,
  },
];

const ScheduleModal = () => {
  const activeTab = useCalendarSlice((state) => state.activeScheduleTab);
  const activeResource = useCalendarSlice((state) => state.activeResource);
  const show = useCalendarSlice((state) => state.showScheduleModal);
  // const selectedDate = useCalendarSlice((state) => state.selectedDate);
  const toggleScheduleModal = useCalendarSlice((state) => state.updateShowScheduleModal);
  const updateScheduleModalTab = useCalendarSlice((state) => state.updateScheduleModalTab);
  const updateActiveResource = useCalendarSlice((state) => state.updateActiveResource);
  const updateWorktimeForUpdate = useCalendarSlice((state) => state.updateWorktimeForUpdate);
  const updateBreakForUpdate = useCalendarSlice((state) => state.updateBreakForUpdate);
  // const tabs = allTabs.filter((tab) => {
  //   if ((tab.name === 'Add Break' || tab.name === 'Add Worktime') && selectedDate) {
  //     return allowToModify(selectedDate);
  //   }
  //   return true;
  // });

  const handleActiveTab = (tab: Tab) => {
    if (activeTab !== tab) {
      updateWorktimeForUpdate(null);
      updateBreakForUpdate(null);
      updateScheduleModalTab(tab);
    }
  };

  useEffect(() => {
    if (show === false) {
      updateActiveResource(null);
      updateWorktimeForUpdate(null);
      updateBreakForUpdate(null);
    }
    return () => {
      if (show === true) {
        toggleScheduleModal(false);
      }
    };
  }, [show]);

  return (
    <Dialog onClose={() => toggleScheduleModal(false)} open={show} fullWidth={true} maxWidth={'md'}>
      <div className="flex max-w-4xl h-[500px] w-[900px] lg:w-full text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
        <div className="flex py-6 text-lg font-medium leading-6 text-gray-900 border-r bg-gray-50">
          <nav className="flex flex-col w-56 px-6 shrink-0" aria-label="Tabs">
            {allTabs.map((tab) => {
              if (
                (tab.name === 'Add Break' || tab.name === 'Break List') &&
                !activeResource?.schedules.length
              ) {
                return null;
              }
              return (
                <div
                  key={tab.name}
                  onClick={() => handleActiveTab(tab.name as Tab)}
                  className={`${
                    activeTab === tab.name
                      ? 'bg-indigo-100 text-indigo-600 rounded'
                      : 'bg-transparent text-gray-500 hover:text-indigo-600 hover:rounded'
                  } cursor-pointer group border-r border-r-transparent inline-flex items-center py-4 px-2 font-medium text-sm transition`}
                >
                  {
                    <>
                      <tab.icon
                        className={classNames(
                          activeTab === tab.name
                            ? 'text-indigo-600'
                            : 'text-gray-400 group-hover:text-indigo-600',
                          'mr-2 h-5 w-5 shrink-0',
                        )}
                        aria-hidden="true"
                      />
                      <span className="flex-1 text-xs shrink-0">{tab.name}</span>
                    </>
                  }
                </div>
              );
            })}
          </nav>
        </div>
        <div className="flex-1 p-6">
          {(() => {
            switch (activeTab) {
              case 'Add Worktime':
                return <AddWorktime />;
              case 'Add Break':
                return <AddBreak />;
              case 'Break List':
                return <BreakList />;
              case 'Worktime List':
                return <WorktimeList />;
              case 'Time off Requests':
                return <LeaveRequestsList />;
              case 'Add Time Off':
                return <AddTimeOff />;
              case 'Shift Swap Requests':
                return <ShiftsList />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </Dialog>
  );
};

export default ScheduleModal;
