import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import reportsService from '../services/reports-service';
import { LastLockResponse } from '../interfaces/last-lock-response';

export function useGetLastLock(
  workSpaceSubDomain: string | undefined,
  configOptions: UseQueryOptions<LastLockResponse['data']> = {},
) {
  return useQuery({
    enabled: Boolean(workSpaceSubDomain),
    queryKey: [reportsService.getLastLockQueryKey, workSpaceSubDomain],
    queryFn: () => reportsService.getLastLock(workSpaceSubDomain!),
    ...configOptions,
  });
}
