import { useState } from 'react';
import PencilIcon from '~icons/carbon/edit';
import TrashIcon from '~icons/carbon/trash-can';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { millisecondsToHours } from 'date-fns';
import toast from 'react-hot-toast';
import { LeaveType } from '../../../interfaces/leave-type-interface';
import leaveService from '../../../services/leave-service';
import Confirmation from '../../../components/libs/confirmation';
import { customToastError } from '../../../utils/custom-toast-error';

interface TimeOffTableProps {
  leaveTypes: LeaveType[];
  handleEditLeaveType: (id: LeaveType['id']) => void;
}

const TimeOffTable = ({ leaveTypes, handleEditLeaveType }: TimeOffTableProps) => {
  const queryClient = useQueryClient();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [id, setId] = useState<number | null>(null);

  const { mutate: deleteLeaveType, isLoading: deleteIsLoading } = useMutation(
    leaveService.deleteLeave,
    {
      onSuccess: (data) => {
        toast.success(`Leave Group Deleted.`);
        queryClient.invalidateQueries([leaveService.leavesQueryKey]);
      },
      onError: (err: AxiosError<Error>) => {
        customToastError('Error', `${err.response?.data.message}`);
        queryClient.invalidateQueries([leaveService.leavesQueryKey]);
      },
    },
  );

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && id) {
      deleteLeaveType(id);
    }
  };

  const handleDeleteLeaveType = (leaveId: LeaveType['id']) => {
    setId(leaveId);
    handleOpenConfirmation();
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9">
        <div className="flex w-full pb-6 mb-6 font-medium border-b">Time Off List</div>
        <div className="flex flex-col w-full">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Total Hours
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Paid
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-sm font-semibold text-gray-900"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {leaveTypes.map((leaveType) => (
                      <tr key={leaveType.id}>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 capitalize whitespace-nowrap sm:pl-6">
                          {leaveType.title}
                        </td>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                          {leaveType.duration === 2629800000 ? 'Monthly' : 'Yearly'}
                        </td>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                          {millisecondsToHours(leaveType.total)}
                        </td>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                          {leaveType.paid ? 'Yes' : 'No'}
                        </td>
                        <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-center whitespace-nowrap sm:pr-6">
                          <button
                            type="button"
                            className="mx-4"
                            disabled={deleteIsLoading}
                            onClick={() => handleDeleteLeaveType(leaveType.id)}
                          >
                            <TrashIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                          </button>
                          <button
                            type="button"
                            className="mx-4"
                            disabled={deleteIsLoading}
                            onClick={() => handleEditLeaveType(leaveType.id)}
                          >
                            <PencilIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
      />
    </>
  );
};

export default TimeOffTable;
