export function minutesToCustomHours(minutes: number | string) {
  // Ensure minutes is a number
  minutes = Number(minutes);

  // Handle invalid input
  if (isNaN(minutes) || minutes < 0) {
    return 'Invalid input';
  }

  // Calculate hours and minutes
  const hours = minutes / 60;
  const decimalHours = hours.toFixed(2); // Format to two decimal places

  return decimalHours;
}

export function millisecondsToCustomHours(milliseconds: number | string) {
  // Ensure input is a number
  milliseconds = Number(milliseconds);

  // Handle invalid input
  if (isNaN(milliseconds) || milliseconds < 0) {
    return 'Invalid input';
  }

  // Convert milliseconds to hours
  const hours = milliseconds / (1000 * 60 * 60); // Convert to hours

  return hours.toFixed(2); // Format to two decimal places
}
