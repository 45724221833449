import { LeaveReq, LeaveReqRequest } from '../interfaces/calendar-leave-request-interface';
import { CreateLeaveReqRequest } from '../interfaces/create-leave-req-request';
import { CreateLeaveReqResponse } from '../interfaces/create-leave-req-response';
import {
  LeaveGroup,
  LeaveGroupRequest,
  UpdateLeaveGroupRequest,
} from '../interfaces/leave-group-interface';
import { LeaveReqData } from '../interfaces/leave-request-interface';
import {
  LeaveType,
  LeaveTypeRequest,
  UpdateLeaveTypeRequest,
} from '../interfaces/leave-type-interface';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import http from './axios-instance';

const leaveGroupsQueryKey = 'LeaveGroups';
const getLeaveGroups = async (): Promise<LeaveGroup[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/leave_group/get`;
  const res = await http.get(url, { headers });
  return res.data;
};

const createLeaveGroup = async (data: LeaveGroupRequest): Promise<LeaveGroup> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/leave_group/create`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const updateLeaveGroup = async (params: UpdateLeaveGroupRequest): Promise<LeaveGroup> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/leave_group/edit`;
  const res = await http.patch(url, null, { headers, params });
  return res.data;
};

const deleteLeaveGroup = async (id: LeaveGroup['id']): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { leave_group_id: id };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/leave_group/delete`;
  const res = await http.delete(url, { headers, params });
  return res.data;
};

const leavesQueryKey = 'Leaves';
const getLeaves = async (): Promise<LeaveType[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/leave/get`;
  const res = await http.get(url, { headers });
  return res.data;
};

const createLeave = async (data: LeaveTypeRequest): Promise<LeaveType> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/leave/create`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const updateLeave = async (params: UpdateLeaveTypeRequest): Promise<LeaveType> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/leave/edit`;
  const res = await http.patch(url, null, { headers, params });
  return res.data;
};

const deleteLeave = async (id: LeaveType['id']): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { leave_id: id };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/leave/delete`;
  const res = await http.delete(url, { headers, params });
  return res.data;
};

const leavesRequestsQueryKey = 'LeaveRequests';
const getLeaveRequests = async (params: {
  page?: number;
  offset?: number;
}): Promise<LeaveReqData> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/leave_request/get`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const createLeaveRequest = async (data: CreateLeaveReqRequest): Promise<CreateLeaveReqResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/leave_request/create`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const updateLeaveRequest = async (data: LeaveReqRequest): Promise<LeaveReq> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/leave_request/change`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const deleteLeaveRequest = async (id: number): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { leave_request_id: id };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/leave_request/delete`;
  const res = await http.delete(url, { headers, params });
  return res.data;
};

const leaveService = {
  leaveGroupsQueryKey,
  getLeaveGroups,
  createLeaveGroup,
  updateLeaveGroup,
  deleteLeaveGroup,
  leavesQueryKey,
  getLeaves,
  createLeave,
  updateLeave,
  deleteLeave,
  leavesRequestsQueryKey,
  getLeaveRequests,
  createLeaveRequest,
  updateLeaveRequest,
  deleteLeaveRequest,
};

export default leaveService;
