import { AxiosError } from 'axios';

interface FormErrorProps {
  error: AxiosError | null | undefined | unknown | Array<unknown>;
  takeSpace?: boolean;
  textAlign?: 'left' | 'center';
}

const FormError = ({ error, takeSpace = false, textAlign = 'center' }: FormErrorProps) => {
  if (Array.isArray(error)) {
    const err = error.find((e) => !!e);
    return (
      <>
        <p
          className={`flex items-center text-sm text-red-500 capitalize first-letter:capitalize font-medium text-center line-clamp-2 ${
            takeSpace ? 'min-h-[30px]' : 'min-h-0 m-0'
          } ${textAlign === 'center' ? 'justify-center' : 'justify-start'}`}
        >
          {err
            ? err instanceof AxiosError
              ? err?.response?.data?.message
              : typeof err === 'string'
              ? err
              : 'Sorry There is a problem.'
            : null}
        </p>
      </>
    );
  }
  if (typeof error === 'string') {
    return (
      <p
        className={`flex items-center text-sm text-red-500 capitalize first-letter:capitalize font-medium text-center line-clamp-2 ${
          takeSpace ? 'min-h-[30px]' : 'min-h-0 m-0'
        } ${textAlign === 'center' ? 'justify-center' : 'justify-start'}`}
      >
        {error && (error ?? 'Sorry There is a problem.')}
      </p>
    );
  }
  return (
    <>
      <p
        className={`flex items-center justify-center text-sm text-red-500 capitalize first-letter:capitalize font-medium text-center line-clamp-2 ${
          takeSpace ? 'min-h-[30px]' : 'min-h-0 m-0'
        }`}
      >
        {error &&
          error instanceof AxiosError &&
          error.response?.data?.message !== 'Email not verified. Please check your email.' &&
          (error.response?.data?.message ?? 'Sorry There is a problem.')}
      </p>
    </>
  );
};

export default FormError;
